import React, { Component } from 'react';
import logging from '../../../lib/logging';
import ExtendedLink from '../../ExtendedLink/ExtendedLink';

class WidgetPageLink extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetPageLink');
    this.logDebug('init:', props);
    this.state = {
      selected: 0,
    };
  }

  render() {
    const { items } = this.props;

    return (
      <section className="widget-section b-page-link gr-12">

        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <ExtendedLink className="link-style" to={item.url}>{item.title}</ExtendedLink>
            </li>
          ))}
        </ul>

      </section>

    );
  }
}

export default WidgetPageLink;
