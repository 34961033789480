const extensionMap = {
  'audio/wav': '.wav',
  'audio/mpeg': '.mp3',
  'video/mp4': '.mp4',
};

/*
 * known formats
 *
 * wav        encoding:pcm
 * mp3 (high) encoding:mp3,bitrate:128
 * mp3 (low)  encoding:mp3,bitrate:64
 *
 * web       encoding:h264,height:540|396
 * HD        encoding:h264,height:1080
 * SD        encoding:h264,height:576
 */
function chooseMediaFormat(formats, targetFormat) {
  let matchedFormat;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < formats.length; i++) {
    const format = formats[i];
    if (format.encoding === targetFormat.encoding) {
      if (!targetFormat.height && !targetFormat.bitrate) {
        matchedFormat = format;
        break;
      }

      const heights = targetFormat.height && targetFormat.height.split('|');
      const bitrates = targetFormat.bitrate && targetFormat.bitrate.split('|');

      const heightMatch = heights && heights.includes(format.height);
      const bitrateMatch = bitrates && bitrates.includes(format.bitrate);

      if (heightMatch || bitrateMatch) {
        matchedFormat = format;
        break;
      }
    }
  }

  if (!matchedFormat) {
    console.error('getDownloadUrl: no valid format available:', formats, targetFormat);
    return {};
  }

  const mimeType = matchedFormat.type.replace('audio_', '');

  const connection = matchedFormat.connection.reduce((acc, item) => {
    if (item.protocol === 'https' && (!acc || acc.priority > item.priority)) {
      acc = item;
    }
    return acc;
  }, null);

  return {
    url: connection.href,
    mimeType,
    extension: extensionMap[mimeType] || '.mp4',
  };
}

export async function getDownloadData(versionPid, format, token) {
  if (!versionPid || !format || !token) {
    console.error('getDownloadUrl: invalid input', { versionPid, format, token });
    return '';
  }

  const mediaSets = ['ws-partner-download'];

  if (format.type !== 'audio') {
    mediaSets.push('ws-clip-syndication-high');
  } else if (format.encoding === 'pcm') {
    mediaSets.push('audio-nondrm-download', 'ws-clip-syndication-audio');
  }

  let mediaSelectorResponse;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < mediaSets.length; i++) {
    const url = `https://open.live.bbc.co.uk/mediaselector/6/select/version/2.0/vpid/${versionPid}/mediaset/${mediaSets[i]}/cors/1?saml_auth=${token}`;

    // eslint-disable-next-line no-await-in-loop
    mediaSelectorResponse = await fetch(url).then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not OK');
      }
      return response.json();
    }).then((json) => {
      if (!json.media || json.media.length === 0) {
        throw new Error('Response invalid');
      }
      return json;
    }).catch((error) => {
      console.warn(`fetching url with media set ${mediaSets[i]} failed for version: ${versionPid}`, error);
      return null;
    });

    if (mediaSelectorResponse) {
      break;
    }
  }

  return mediaSelectorResponse ? chooseMediaFormat(mediaSelectorResponse.media, format) : {};
}
