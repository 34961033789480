import React, { Component } from 'react';
import logging from '../../../lib/logging';

class WidgetHeading extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetHeading');
    this.logDebug('init:', props);
  }

  render() {
    const { heading } = this.props;

    return (

      <h2 className="gr-12">{heading}</h2>

    );
  }
}

export default WidgetHeading;
