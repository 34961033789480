const SOCIAL_LINKS = Object.freeze({
  twitter: 'https://twitter.com/bbcnews',
  facebook: 'https://www.facebook.com/bbcnews',
  instagram: 'https://www.instagram.com/bbcnews',
});

export const visitSocialNetwork = (socialNetwork) => {
  window.open(SOCIAL_LINKS[socialNetwork], '_blank').focus();
};

export const setDocumentMeta = (data, client) => {
  client.writeData({
    data: {
      getDocumentMeta: {
        __typename: 'DocumentMeta',
        pageTitle: null,
        shortSummary: null,
        description: null,
        ...data,
      },
    },
  });
};

export const resetDocumentMeta = (client) => {
  setDocumentMeta({}, client);
};

export const setPageTitle = (pageTitle, client) => {
  setDocumentMeta({ pageTitle }, client);
};

export const getRegionName = (name) => ((name === 'WS English') ? 'World Service English' : name);
