import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../lib/logging';

class TeaserSquareList extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'TeaserSquareList');
    this.logDebug('init:', props);
  }

  render() {
    const {
      items,
      title,
    } = this.props;

    return (
      <div className="b-teaser-list">
        <div className="b-teaser-list__head">
          <h2 className="h2-style">{title}</h2>
        </div>
        <ul className="row">
          {(items).map((item, idx) => (
            <li key={idx} className="gr-12 gr-6@m gr-3@l">
              <Link className="b-teaser-square" to={item.path}>
                <figure>
                  {item.image
                                    && (
                                    <picture>
                                      <img
                                        srcSet={`${item.image.sizes.small}, ${item.image.sizes.medium} 2x`}
                                        src={item.image.sizes.medium}
                                        alt={item.image.title}
                                      />
                                    </picture>
                                    )}

                  <figcaption>
                    <span className="b-teaser-square__title">{item.name}</span>
                  </figcaption>
                </figure>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default TeaserSquareList;
