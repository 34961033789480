import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../lib/logging';
import FAQIcon from '../icons/FAQIcon';
import MailIcon from '../icons/MailIcon';
import GlossaryIcon from '../icons/GlossaryIcon';

class SupportBanner extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'SupportBanner');
    this.logDebug('init:', props);
  }

  render() {
    return (
      <div className="b-support-banner">
        <div className="b-support-banner__image" />
        <div className="b-support-banner__wrapper">
          <p className="h1-style">Need help with something?</p>
          <p className="h2-style">We&apos;ve got you covered.</p>
          <ul>
            <li>
              <Link to="/contact">
                <i><MailIcon /></i>
                <span>Contact us</span>
              </Link>
            </li>
            <li>
              <Link to="/faq">
                <i><FAQIcon /></i>
                <span>FAQs</span>
              </Link>
            </li>
            <li className="b-support-banner__glossary">
              <Link to="/glossary">
                <i><GlossaryIcon /></i>
                <span>Glossary</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SupportBanner;
