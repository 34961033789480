import React, { Component } from 'react';
import moment from 'moment';
import cx from 'classnames';
import FilterTag from '../../../components/filterTag/FilterTag';
import BasketMutations from '../../../graphql/BasketMutations';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import { formatDurationString } from '../../../lib/time';
/* eslint-disable no-nested-ternary */

class CartItem extends Component {
  render() {
    const {
      item, downloadItem, isDownloading, startDownload,
    } = this.props;
    const {
      error, errorStatus, completed, progress,
    } = downloadItem || {};
    const {
      teaser, version, format, availableFormats,
    } = item;

    let preSelectedFormat = null;
    if (availableFormats.length === 1 && format.slug !== availableFormats[0].slug) {
      preSelectedFormat = item.availableFormats[0];
    }
    const releaseDate = teaser.episode.releaseDate ? moment(teaser.episode.releaseDate).format('D MMMM YYYY') : null;

    if (!version.isDownloadable) {
      return (
        <BasketMutations pid={item.pid} versionPid={version.pid} hasAccess>
          {({ removeDetailed }) => (
            <li className="b-cart-item">
              <button className="close" onClick={removeDetailed} title="Remove" />
              <div className="row">
                <div className="gr-12 gr-4@l">
                  <a href={teaser.path}>
                    <img className="b-cart-item__image" src={teaser.image.sizes.medium} alt="" />
                  </a>
                </div>
                <div className="gr-12 gr-8@l">
                  <div className="gr-12 no-gutter">
                    <span className="gr-12 h2-style b-cart-item__title no-gutter">{teaser.titleTruncated}</span>
                    <span className="gr-12 b-cart-item__title no-gutter">
                      Version:
                      {version.type}
                    </span>
                    <span className="gr-12 b-cart-item__title no-gutter"><strong>Error: This Item is no longer available.</strong></span>
                  </div>
                </div>
              </div>
            </li>
          )}
        </BasketMutations>
      );
    }

    return (
      <BasketMutations
        pid={item.pid}
        versionPid={version.pid}
        preSelectedFormat={preSelectedFormat}
        hasAccess
      >
        {({ removeDetailed, changeFormat, isDisabled }) => (
          <li className="b-cart-item">
            {!isDownloading && <button className="close" onClick={removeDetailed} title="Remove" />}
            {!isDownloading && startDownload && (
            <button className="download" onClick={startDownload} title="Direct Download"><DownloadIcon /></button>
            )}
            <div className="row">
              <div className="gr-12 gr-4@l">
                <a href={teaser.path}>
                  <img className="b-cart-item__image" src={teaser.image.sizes.medium} alt="" />
                </a>
              </div>
              <div className="gr-12 gr-8@l">

                <div className="gr-12 no-gutter">
                  <span className="gr-12 h2-style b-cart-item__title no-gutter">{teaser.titleTruncated}</span>
                  <ul className="gr-12 gr-6@m gr-8@l b-cart-item__info-table no-gutter">
                    <li className="b-cart-item__info-table__item">
                      <span className="b-cart-item__info-table__item__label">Version:</span>
                      <span className="b-cart-item__info-table__item__value">{version.label}</span>
                    </li>
                    <li className="b-cart-item__info-table__item">
                      <span className="b-cart-item__info-table__item__label">First aired:</span>
                      <span className="b-cart-item__info-table__item__value">{releaseDate}</span>
                    </li>
                    {version.duration && (
                    <li className="b-cart-item__info-table__item">
                      <span className="b-cart-item__info-table__item__label">Duration:</span>
                      <span className="b-cart-item__info-table__item__value">{formatDurationString(version.duration)}</span>
                    </li>
                    )}
                    <li className="b-cart-item__info-table__item">
                      <span className="b-cart-item__info-table__item__label">Type:</span>
                      <span className="b-cart-item__info-table__item__value">{teaser.episode.mediaType === 'audio' ? 'Audio' : 'Video'}</span>
                    </li>
                    {teaser.series
                                        && (
                                        <li className="b-cart-item__info-table__item">
                                          <span className="b-cart-item__info-table__item__label">Episode:</span>
                                          <span className="b-cart-item__info-table__item__value">
                                            {teaser.series.index || 'Part'}
                                            {' '}
                                            of
                                            {' '}
                                            {teaser.series.length}
                                          </span>
                                        </li>
                                        )}

                    {teaser.language
                                        && (
                                        <li className="b-cart-item__info-table__item">
                                          <span className="b-cart-item__info-table__item__label">Language:</span>
                                          <span className="b-cart-item__info-table__item__value">{teaser.language.name}</span>
                                        </li>
                                        )}

                    {teaser.genre
                                        && (
                                        <li className="b-cart-item__info-table__item">
                                          <span className="b-cart-item__info-table__item__label">Genre:</span>
                                          <span className="b-cart-item__info-table__item__value">{teaser.genre.name}</span>
                                        </li>
                                        )}
                  </ul>

                  {completed
                                        && (
                                        <ul className="gr-12 gr-6@m gr-4@l b-cart-item__cta-list no-gutter">
                                          <li key={format.slug} className="b-cart-item__cta-list__item gr-6 gutter@l">
                                            <FilterTag id={format} title={`${format.label} (✔)`} />
                                          </li>
                                        </ul>
                                        )}

                  {downloadItem && !errorStatus && !completed
                                        && (
                                        <ul className="gr-12 gr-6@m gr-4@l b-cart-item__cta-list">
                                          <li className="b-cart-item__cta-list__item gr-4">
                                            {Math.round(progress * 100)}
                                            %
                                          </li>
                                        </ul>
                                        )}

                  {!isDownloading && !completed
                                        && (
                                        <ul className="gr-12 gr-6@m gr-4@l b-cart-item__cta-list no-gutter">
                                          {item.availableFormats.map((f) => (
                                            <li key={f.slug} className="b-cart-item__cta-list__item gr-4 gutter@l">
                                              <FilterTag
                                                id={f}
                                                onChange={
                                                  (item.format
                                                    && item.format.slug === f.slug) || changeFormat
                                                }
                                                value={item.format && item.format.slug === f.slug}
                                                title={f.label}
                                                className={cx({ 'is-waiting': isDisabled })}
                                              />
                                            </li>
                                          ))}
                                        </ul>
                                        )}

                  { !format && (
                  <span className="gr-12 b-cart-item__title no-gutter">Error: Please select an available download format.</span>
                  )}

                  { error && format && (
                  <span className="gr-12 b-cart-item__title no-gutter">
                    Error:
                    {errorStatus}
                  </span>
                  )}
                </div>
              </div>
            </div>
          </li>
        )}
      </BasketMutations>
    );
  }
}

export default CartItem;
