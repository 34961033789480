import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import cx from 'classnames';
import logging from '../../../lib/logging';

class DetailHeader extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'DetailHeader', false);
  }

  render() {
    if (!this.props.channelData) {
      return null;
    }

    const dayActive = this.props.route.match.path.indexOf('/day') > -1;
    const weekActive = this.props.route.match.path.indexOf('/week') > -1;
    const seasonActive = this.props.route.match.path.indexOf('/season') > -1;
    const { channel } = this.props.route.match.params;

    const today = moment();
    const maxDate = moment(this.props.channelData.max);
    const dateDay = moment.min(today, maxDate).format('YYYY-MM-DD');
    const dateWeek = moment.min(today, maxDate).startOf('isoWeek').format('YYYY-MM-DD');

    return (
      <div className="row">
        <h1 className="h2-style gr-12 gr-9@m">{this.props.channelData.title}</h1>
        <Link className="b-schedule-page__back-link hide show@m h4-style gr-12 gr-3@m" to="/schedules">Change Schedule</Link>

        <ul className="b-schedule-page__view-changer gr-12">
          <li className={cx({ active: dayActive })}><Link to={`/schedules/${channel}/day/${dateDay}`}>Daily</Link></li>
          <li className={cx({ active: weekActive })}><Link to={`/schedules/${channel}/week/${dateWeek}`}>Weekly</Link></li>
          <li className={cx({ active: seasonActive })}><Link to={`/schedules/${channel}/season/${this.props.channelData.season}`}>Season</Link></li>
        </ul>

      </div>

    );
  }
}

export default DetailHeader;
