import React, { Component } from 'react';

class VideoIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M41.4 14.7H8.6v20.7h32.8V14.7zm5.2 0v20.7H50v6.3c0 3.2-2.6 5.8-5.8 5.8H5.8A5.8 5.8 0 0 1 0 41.7v-6.3h3.4V14.7H0V8.4c0-3.2 2.6-5.8 5.8-5.8h38.5c3.2 0 5.8 2.6 5.8 5.8v6.3h-3.5zM6.9 6v5.2h5.2V6H6.9zm10.3 0v5.2h5.2V6h-5.2zm10.4 0v5.2h5.2V6h-5.2zm10.3 0v5.2h5.2V6h-5.2zm5.2 38v-5.2h-5.2V44h5.2zm-10.3 0v-5.2h-5.2V44h5.2zm-10.4 0v-5.2h-5.2V44h5.2zm-10.3 0v-5.2H6.9V44h5.2z" fill="#fff" /></svg>
    );
  }
}

export default VideoIcon;
