import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TeaserSquareList from '../../../components/teaserSquareList/TeaserSquareList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './catalogueRegion.gql';
import { getRegionName } from '../../../js/helper';

class CatalogueRegion extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">

            <div className="gr-12 gr-11@m page__intro">
              <h1>{getRegionName(data.region.name)}</h1>
              <div dangerouslySetInnerHTML={{ __html: data.region.description }} />
            </div>
            <div className="gr-12">
              <div className="b-teaser-list b-teaser-list--region">
                <div className="b-teaser-list__head">
                  <h2 className="h2-style">Select a language</h2>
                </div>
                <ul className="row">
                  {data.region.languages.map((language) => (
                    <li key={language.slug} className="gr-12 gr-6@m gr-3@l">
                      <Link className="b-teaser-square b-teaser-square--red-svg" to={`/catalogue/region/${data.region.slug}/${language.slug}/1`}>
                        <div>
                          <span className="b-teaser-square__title">{language.name}</span>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <TeaserSquareList
                title="Browse by genre"
                items={data.region.genres}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(CatalogueRegion, query);
