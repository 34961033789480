import React, { Component } from 'react';
import logging from '../../lib/logging';

class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'LoadingSpinner');
    this.logDebug('init:', props);
  }

  render() {
    return (
      <div className="b-loading-spinner">
        <div className="spinner">
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
          <i />
        </div>
      </div>
    );
  }
}

export default LoadingSpinner;
