import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import VideoIcon from '../icons/VideoIcon';
import { availability } from '../../lib/time';
import AudioIcon from '../icons/AudioIcon';

function TopTeaser({
  title, pid, image, path, genre, synopsisTruncated, episode, brand,
}) {
  const availableInfo = availability(episode && episode.availability);

  return (
    <li key={pid || title} className="b-carousel__preview__item">
      <div className="b-carousel__preview__item__image">
        { episode && episode.availability && episode.availability.isNew
                    && <div className="b-carousel__preview__item__label">New</div>}
        {image && <img src={image.sizes.medium} />}

        {episode && episode.mediaType && (
        <div className="b-teaser-image__format-icon">
          {episode.mediaType === 'audio' && <AudioIcon />}
          {episode.mediaType === 'audio_video' && <VideoIcon />}
        </div>
        )}
      </div>
      <div className="b-carousel__preview__item__info">
        {availableInfo && <span className="b-teaser-info__available-hint">{availableInfo}</span>}
        { brand && <span className="b-teaser-info__brand-title">{brand.title}</span>}
        <span className={cx('b-teaser-info__title', { 'has-brand-title': !!brand })}>{title}</span>
        {synopsisTruncated && <p className="b-teaser-info__description">{synopsisTruncated}</p>}
        {path && <Link to={path} className="b-button">Read more</Link>}
        {genre && <span className="genres">{genre.name}</span>}
      </div>
    </li>
  );
}

export default TopTeaser;
