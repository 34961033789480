import React, { Component, Fragment } from 'react';
import { Pie } from 'react-chartjs-2';

class Clock extends Component {
  constructor(props) {
    super(props);

    this.colorMap = {
      'hard-posted-break': '#a5160a',
      'soft-posted-break': '#ee8f21',
      'this-programme': '#757171',
      'other-programme': '#a0a0a0',
      bulletin: '#b02418',
      billboard: '#bfbfbf',
      trail: '#4fad5b',
      quiz: '#68349a',
      promo: '#db7332',
      prelude: '#7490cc',
    };
  }

  pluralize = (amount, singular, plural) => ((amount === 1) ? `${amount} ${singular}` : `${amount} ${plural}`);

  getChartOptions = () => ({
    elements: {
      arc: {
        borderColor: '#fff',
        borderWidth: 0.5,
      },
    },
    tooltips: {
      displayColors: false,
      cornerRadius: 0,
      xPadding: 10,
      yPadding: 10,
      callbacks: {
        title: (items, data) => data.labels[items[0].index],
        label: (item, data) => this.pluralize(data.datasets[0].data[item.index], 'minute', 'minutes'),
      },
    },
    legend: {
      position: 'left',
      fullWidth: false,
      onClick: () => {},
      labels: {
        boxWidth: 20,
        padding: 20,
        generateLabels: (chart) => {
          const { data } = chart;
          const arcOptions = chart.options.elements.arc;

          if (data.labels.length && data.datasets.length) {
            const dataset = data.datasets[0];
            const legend = [];

            data.labels.forEach((label, i) => {
              const fill = dataset.backgroundColor[i];
              legend.push({
                text: `${label} - ${this.pluralize(dataset.data[i], 'minute', 'minutes')}`,
                fillStyle: fill,
                strokeStyle: arcOptions.borderColor,
                lineWidth: arcOptions.borderWidth,
              });
            });
            return legend;
          }
          return [];
        },
      },
    },
  });

  prepareChartData = (clockData) => {
    const data = [];
    const labels = [];
    const backgroundColor = [];

    if (!clockData.clockSegments || clockData.clockSegments.length === 0) {
      return null;
    }

    clockData.clockSegments.forEach((item) => {
      const minutes = parseInt(item.segmentDurationMinutes, 10);
      const seconds = parseInt(item.segmentDurationSeconds, 10) || 0;
      const duration = minutes + (seconds / 60);

      data.push(duration);
      labels.push(item.segmentLabel);
      backgroundColor.push(this.colorMap[item.segmentType]);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
          hoverBorderColor: '#fff',
          hoverBorderWidth: 1,
        },
      ],
    };
  };

  render() {
    const { programClock } = this.props;
    const chartData = this.prepareChartData(programClock);

    return (
      <>
        <div className="gr-12 gr-11@m">
          {programClock.description
                        && <div className="editor-text" dangerouslySetInnerHTML={{ __html: programClock.description }} />}
        </div>
        { chartData && (
        <Pie data={chartData} options={this.getChartOptions()} />
        )}
      </>
    );
  }
}

export default Clock;
