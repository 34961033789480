import React, { Component } from 'react';

class SettingsIcon extends Component {
  render() {
    return (
      <svg enableBackground="new 0 0 33 33" version="1.1" viewBox="0 0 33 33" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        <path d="m28.3 12.3l-0.5-1.3c-0.1-0.2-0.1-0.5 0-0.7l1.4-3.7c0.1-0.4 0.1-0.8-0.2-1.1l-1.7-1.5c-0.3-0.3-0.7-0.4-1.1-0.2l-3.6 1.5c-0.2 0.1-0.5 0.1-0.8 0l-1.3-0.5c-0.2-0.1-0.4-0.3-0.5-0.5l-1.6-3.6c-0.1-0.5-0.4-0.7-0.8-0.7h-2.3c-0.4 0-0.8 0.2-0.9 0.6l-1.5 3.5c-0.1 0.2-0.3 0.4-0.5 0.5l-1.3 0.5c-0.2 0.1-0.5 0.1-0.7 0l-3.7-1.3c-0.4-0.1-0.8 0-1.1 0.3l-1.6 1.6c-0.3 0.3-0.4 0.7-0.2 1.1l1.5 3.6c0.1 0.2 0.1 0.5 0 0.8l-0.5 1.3c-0.1 0.2-0.3 0.4-0.5 0.5l-3.6 1.6c-0.5 0.1-0.7 0.4-0.7 0.8v2.3c0 0.4 0.2 0.8 0.6 0.9l3.5 1.5c0.2 0.1 0.4 0.3 0.5 0.5l0.5 1.3c0.1 0.2 0.1 0.5 0 0.7l-1.4 3.7c-0.1 0.4-0.1 0.8 0.2 1.1l1.8 1.6c0.3 0.3 0.7 0.4 1.1 0.2l3.6-1.5c0.2-0.1 0.5-0.1 0.8 0l1.3 0.5c0.2 0.1 0.4 0.3 0.5 0.5l1.6 3.6c0.2 0.4 0.5 0.6 0.9 0.6h2.3c0.4 0 0.8-0.2 0.9-0.6l1.5-3.5c0.1-0.2 0.3-0.4 0.5-0.5l1.3-0.5c0.2-0.1 0.5-0.1 0.7 0l3.7 1.4c0.4 0.1 0.8 0.1 1.1-0.2l1.6-1.6c0.3-0.3 0.4-0.7 0.2-1.1l-1.5-3.6c-0.1-0.2-0.1-0.5 0-0.8l0.5-1.3c0.1-0.2 0.3-0.4 0.5-0.5l3.6-1.6c0.4-0.2 0.6-0.5 0.6-0.9v-2.3c0-0.4-0.2-0.8-0.6-0.9l-3.5-1.5c-0.3-0.1-0.5-0.3-0.6-0.6zm-4.3 4.2c0 4.1-3.4 7.5-7.5 7.5s-7.5-3.4-7.5-7.5 3.4-7.5 7.5-7.5 7.5 3.4 7.5 7.5z" />
      </svg>
    );
  }
}

export default SettingsIcon;
