import React, { Component } from 'react';
import logging from '../../lib/logging';
import Alert from '../alert/Alert';

class AlertsArea extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'AlertsArea');
    this.logDebug('init:', props);

    let done = [];

    try {
      done = JSON.parse(localStorage.getItem('_bbc_exozet_alerts')) || [];
    } catch (error) {
      this.logError(error);
    }

    this.state = {
      done,
    };
  }

  onClose = (id) => {
    this.setState({
      done: [...this.state.done, id],
    }, () => {
      try {
        localStorage.setItem('_bbc_exozet_alerts', JSON.stringify(this.state.done));
      } catch (error) {
        this.logError(error);
      }
    });
  };

  render() {
    const { items } = this.props;

    return (
      <div className="b-alerts-area">
        {items
          .filter((item) => !item.invalid && !this.state.done.includes(item.id))
          .filter((item, idx) => idx === 0)
          .map((item) => (
            <Alert
              key={item.id}
              onClose={this.onClose}
              id={item.id}
              icon
              label={!!item.label}
              link={item.link}
              type={item.type}
              text={item.text}
              labelTitle={item.label}
              headline={!!item.headline}
              cta={!!item.link}
              headlineTitle={item.headline}
              ctaTitle="More details"
            />
          ))}
      </div>
    );
  }
}

export default AlertsArea;
