import React, { Component } from 'react';

import { Mutation } from 'react-apollo';
import MUTATION_TOGGLE_FAVORITE from './mutation.gql';

class ToggleFavoriteMutation extends Component {
  render() {
    return (
      <Mutation mutation={MUTATION_TOGGLE_FAVORITE}>
        {(mutate) => (
          this.props.children((brand) => this.onMutate(mutate, brand))
        )}
      </Mutation>
    );
  }

  onMutate = (mutate, brand) => {
    const toggle = !brand.isFavorite;

    mutate({
      variables: { toggle, pid: brand.pid },

      optimisticResponse: {
        __typename: 'Mutation',
        toggleFavorite: {
          __typename: 'BrandModel',
          _id: brand._id,
          pid: brand.pid,
          isFavorite: toggle,
        },
      },
    });
  };
}

export default ToggleFavoriteMutation;
