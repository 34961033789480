import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import Pagination from '../../../components/pagination/Pagination';
import Error from '../../../components/error/Error';
import query from './genrePage.gql';
import { setPageTitle } from '../../../js/helper';

class Evergreen extends Component {
  render() {
    if (!this.props.data.evergreenAll) {
      return (<Error>Genre not found.</Error>);
    }

    const { items, totalPages, title } = this.props.data.evergreenAll;
    const { page, genre } = this.props.route.match.params;

    setPageTitle(`Continuous Content - ${title}`, this.props.client);

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">
            <h1>
              Continuous Content -
              {title}
            </h1>
            <TeaserList
              isThirdGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle
              hasDescription
              hasGenre
              items={items}
            />
            <Pagination route={`/continuous-content/${genre}`} totalPages={totalPages} currentPage={parseInt(page, 10)} />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(Evergreen), query, null, true);
