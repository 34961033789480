import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import graphQlHoc from '../../../components/graphQlHoc';
import logging from '../../../lib/logging';

import Tabs from '../../../components/tabs/Tabs';
import RegionIcon from '../../../components/icons/RegionIcon';
import DownloadIcon from '../../../components/icons/DownloadIcon';
import NotificationIcon from '../../../components/icons/NotificationIcon';
import Checkbox from '../../../components/form-elements/checkbox/Checkbox';
import Selectbox from '../../../components/form-elements/selectbox/Selectbox';
import NotLoggedIn from '../../../components/notLoggedIn/notLoggedIn';
import QUERY from './query.gql';
import MUTATION_UPDATE_SETTINGS from './mutation.gql';
import { setPageTitle } from '../../../js/helper';

class User extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'User', false);
    this.logDebug('init:', props);

    const params = new URLSearchParams(window.document.location.search.slice(1));
    this.initialTabIndex = params.has('tab') ? Number(params.get('tab')) : 0;

    this.audioFormats = this.props.data.allDownloadFormats.filter((format) => (format.type === 'audio'));
    this.videoFormats = this.props.data.allDownloadFormats.filter((format) => (format.type === 'audio_video'));
  }

  componentDidMount() {
    setPageTitle('User Settings', this.props.client);
  }

  onChange = (id, value) => {
    const settings = { ...this.props.data.settings };
    const refetchQueries = [];

    /* reset language if region has changed */
    if (id === 'region' && (!settings.region || settings.region.slug !== value.slug)) {
      settings.language = value.languages[0];
      refetchQueries.push({ query: QUERY });
    }

    settings[id] = value;
    const variables = {
      ...settings,
      region: `api/regions/${settings.region.slug}`,
      language: `api/languages/${settings.language.slug}`,
      defaultAudioFormat: `api/download_formats/${settings.defaultAudioFormat.slug}`,
      defaultVideoFormat: `api/download_formats/${settings.defaultVideoFormat.slug}`,
    };

    this.props.mutate({
      variables,
      optimisticResponse: {
        __typename: 'Mutation',
        updateSettings: settings,
      },
      refetchQueries,
    });
  };

  render() {
    const { loginStatus, settings, regions } = this.props.data;

    if (!loginStatus) {
      return (<NotLoggedIn />);
    }

    return (
      <div className="b-user">
        <div className="container container--indent">
          <div className="gr-12">

            <Tabs defaultTab={this.initialTabIndex}>
              <Tabs.Tab>
                <Tabs.Title>
                  <span>
                    <i><RegionIcon /></i>
                    Localization
                  </span>
                </Tabs.Title>
                <Tabs.Content>
                  <Selectbox
                    id="region"
                    value={settings.region}
                    onChange={this.onChange}
                    options={regions}
                    valueKey="slug"
                    label="Default region"
                    placeholder="Select a default…"
                    getOptionValue={(option) => option.slug}
                    getOptionLabel={(option) => option.name}
                    clearable={false}
                  />
                  <Selectbox
                    id="language"
                    value={settings.language}
                    onChange={this.onChange}
                    options={(settings.region) ? settings.region.languages : null}
                    valueKey="slug"
                    label="Default region language"
                    placeholder="Select a default…"
                    getOptionValue={(option) => option.slug}
                    getOptionLabel={(option) => option.name}
                    clearable={false}
                    noResultsText="Please select a region first"
                  />
                </Tabs.Content>
              </Tabs.Tab>
              <Tabs.Tab>
                <Tabs.Title>
                  <span>
                    <i><DownloadIcon /></i>
                    Download format
                  </span>
                </Tabs.Title>
                <Tabs.Content>
                  <Selectbox
                    id="defaultAudioFormat"
                    value={settings.defaultAudioFormat}
                    onChange={this.onChange}
                    options={this.audioFormats}
                    valueKey="slug"
                    label="Default audio file download format"
                    placeholder="Select a default…"
                    getOptionValue={(option) => option.slug}
                    getOptionLabel={(option) => option.name}
                    clearable={false}
                  />
                  <Selectbox
                    id="defaultVideoFormat"
                    value={settings.defaultVideoFormat}
                    onChange={this.onChange}
                    options={this.videoFormats}
                    valueKey="slug"
                    label="Default video file download format"
                    placeholder="Select a default…"
                    getOptionValue={(option) => option.slug}
                    getOptionLabel={(option) => option.name}
                    clearable={false}
                  />
                </Tabs.Content>
              </Tabs.Tab>
              <Tabs.Tab>
                <Tabs.Title>
                  <span>
                    <i><NotificationIcon /></i>
                    Notifications
                  </span>
                </Tabs.Title>
                <Tabs.Content>
                  <Checkbox
                    id="sendNotifications"
                    value={settings.sendNotifications}
                    label="Send me notifications via email"
                    onChange={this.onChange}
                  />
                  <Checkbox
                    id="showNotifications"
                    value={settings.showNotifications}
                    label="Show notifications on favourites page"
                    onChange={this.onChange}
                  />
                </Tabs.Content>
              </Tabs.Tab>
            </Tabs>

          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(graphql(MUTATION_UPDATE_SETTINGS)(withApollo(User)), QUERY);
