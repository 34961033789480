import React, { Component } from 'react';
import logging from '../../lib/logging';
import SearchIcon from '../icons/SearchIcon';

class SearchInput extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'SearchInput', false);
    this.logDebug('init:', props);
  }

  render() {
    return (
      <form role="search" className="b-search-input" onSubmit={this.props.onSubmit}>
        <input
          value={this.props.value}
          onChange={this.props.onChange}
          className="input"
          type="search"
          id="site-search"
          name="search"
          placeholder="Search for programme brands and episodes…"
          aria-label="Search for programme brands and episodes"
        />
        <i className="hide show@m"><SearchIcon /></i>
        <button className="submit">
          <i className="hide@m"><SearchIcon /></i>
          <span className="hide show@m">Search</span>
        </button>
      </form>
    );
  }
}

export default SearchInput;
