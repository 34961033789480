import React, { Component } from 'react';
import moment from 'moment';
import logging from '../../../lib/logging';

import DetailHeader from './DetailHeader';
import graphQlHoc from '../../../components/graphQlHoc';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';
import queryDetailSeason from './queryDetailSeason.gql';
import Error from '../../../components/error/Error';
import TimezoneSelectbox from '../../../components/form-elements/timezoneSelectbox/TimezoneSelectbox';
import 'moment-timezone';
import { IconList, IconListItem } from '../../../components/iconList/IconList';

class SchedulesDetailSeason extends Component {
  state = {
    timezone: null,
  };

  constructor(props) {
    super(props);
    logging.applyLogging(this, 'SchedulesDetailSeason', false);
    this.logDebug('init:', props);
  }

  render() {
    if (!this.props.data.channelData) {
      return (<Error>This channel does not exist.</Error>);
    }

    return (
      <div className="b-schedule-page">
        <div className="container container--indent">
          <div className="gr-12">
            <DetailHeader channelData={this.props.data.channelData} route={this.props.route} />

            <div className="gr-12 no-gutter">
              <div className="gr-12 prefix-3@l gr-6@l">
                <TimezoneSelectbox
                  className="m-large"
                  value={this.state.timezone}
                  onChange={(option) => { this.setState({ timezone: option }); }}
                />
              </div>
            </div>

            { this.renderTable(this.props.data.seasonSchedule) }
          </div>
        </div>
      </div>
    );
  }

  renderTable(season) {
    if (!season) {
      return (
        <div className="b-season-schedule gr-12">
          There is currently no schedule data available for this channel.
        </div>
      );
    }

    const { timezone } = this.state;
    const mappedBroadcasts = {};
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const times = [];
    for (let i = 0; i < 24; i++) { // eslint-disable-line no-plusplus
      times.push(`0${i}:00`.slice(-5));
    }

    times.forEach((time) => {
      mappedBroadcasts[time] = {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      };
    });

    season.broadcasts.forEach((broadcast) => {
      const hour = `${broadcast.start.substr(0, 2)}:00`;
      mappedBroadcasts[hour][broadcast.day].push(broadcast);
    });

    const prevLink = (season.prev) ? `/schedules/${this.props.route.match.params.channel}/season/${season.prev}` : null;
    const nextLink = (season.next) ? `/schedules/${this.props.route.match.params.channel}/season/${season.next}` : null;

    const startDate = season.startDate && moment.utc(season.startDate).format('MMM Do YYYY');
    const endDate = season.endDate && moment.utc(season.endDate).format('MMM Do YYYY');

    return (
      <div className="b-season-schedule gr-12 no-gutter">
        <div className="availability-wrapper">
          <IconList isHorizontal>
            <IconListItem icon="calendar">
              {startDate}
              {' '}
              -
              {' '}
              {endDate}
            </IconListItem>
            {' '}
          </IconList>
        </div>
        <div className="wrapper">
          <ul className="day-list gr-12 no-gutter">
            <li>
              <ExtendedLink className="link" to={prevLink}>
                <i />
                Last
              </ExtendedLink>
            </li>
            <li>
              <span className="day-label">Mon</span>
            </li>
            <li>
              <span className="day-label">Tue</span>
            </li>
            <li>
              <span className="day-label">Wed</span>
            </li>
            <li>
              <span className="day-label">Thu</span>
            </li>
            <li>
              <span className="day-label">Fri</span>
            </li>
            <li>
              <span className="day-label">Sat</span>
            </li>
            <li>
              <span className="day-label">Sun</span>
            </li>
            <li>
              <ExtendedLink className="link" to={nextLink}>
                Next
                <i />
              </ExtendedLink>
            </li>
          </ul>

          { times.map((time) => {
            const timezoneLabel = timezone && moment.utc(time, ['HH:mm']).tz(timezone.zone).format('HH:mm z');
            return (
              <ul key={time} className="hours-list gr-12 no-gutter">
                <li className="hours-item">
                  <div className="times">
                    <span>
                      {time}
                      {' '}
                      GMT
                    </span>
                    {timezone && <span>{timezoneLabel}</span>}
                  </div>
                </li>

                { days.map((day) => (
                  <li key={day} className="hours-item">
                    <ul>
                      { mappedBroadcasts[time][day].map((broadcast, i) => (
                        <li key={i} className="broadcast">
                          <span className="broadcast-time">{broadcast.start}</span>
                          <span className="broadcast-title">{broadcast.slot}</span>
                        </li>
                      )) }
                    </ul>
                  </li>
                )) }

                <li className="hours-item">
                  <div className="times">
                    <span>
                      {time}
                      {' '}
                      GMT
                    </span>
                    {timezone && <span>{timezoneLabel}</span>}
                  </div>
                </li>
              </ul>
            );
          })}

        </div>
      </div>
    );
  }
}

export default graphQlHoc(SchedulesDetailSeason, queryDetailSeason);
