import React, { Component } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import LoginLink from '../../components/login/LoginLink';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
};

function OpenEnd({ pid }) {
  return (
    <div className="contentbox">
      <p>Please login. If you are not a partner, register your interest to become a partner here.</p>

      <div className="b-overlay__cta-area row">
        <div className="gr-12 gr-6@m">
          <LoginLink />
        </div>
        <div className="gr-12 gr-6@m">
          <Link className="b-button" to={`/contact/become-bbc-media-partner/${pid}`}>Become a Partner</Link>
        </div>
      </div>
    </div>
  );
}

function NoAccess({ pid }) {
  return (
    <div className="contentbox">
      <p>Currently, you do not have access to this asset, please request access here.</p>
      <div className="b-overlay__cta-area row">
        <div className="gr-12 gr-6@m">
          <Link className="b-button" to={`/contact/programme-query/${pid}`}>Request access</Link>
        </div>
      </div>
    </div>
  );
}

export default class AuthorizationModal extends Component {
  onClose = () => {
    const data = {
      modalAuthorization: {
        __typename: 'ModalAuthorization',
        open: false,
      },
    };

    this.props.client.writeData({ data });
  };

  componentWillUnmount() {
    if (this.props.open) {
      this.onClose();
    }
  }

  render() {
    const { open, variant, episodePid } = this.props;

    return (
      <Modal
        style={customStyles}
        isOpen={open}
        onRequestClose={this.onClose}
        contentLabel="Authorization Modal"
      >
        <i onClick={this.onClose} className="close" />
        {variant === 'open-end' && <OpenEnd pid={episodePid} />}
        {variant === 'no-access' && <NoAccess pid={episodePid} />}
      </Modal>
    );
  }
}
