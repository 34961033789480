/* eslint-disable */

const logWithPrefix = function (prefix, method, parameters) {
    parameters = parameters || [];
    parameters = Array.prototype.slice.apply(parameters);

    parameters.unshift(`[${prefix}]`);

    if (typeof console[method] === 'function') {
        console[method](...parameters);
    } else {
        /* If we are on an old IE, we cannot output the values with console.METHOD.apply */
        console[method](parameters);
    }
};

const logging = {};

logging.logTrace = function () {
    if (logging.level >= logging.LEVEL_TRACE) {
        logWithPrefix(this.loggingPrefix, 'trace', arguments);
    }
};

logging.logDebug = function () {
    if (logging.level >= logging.LEVEL_DEBUG) {
        logWithPrefix(this.loggingPrefix, 'debug', arguments);
    }
};

logging.log = function () {
    if (logging.level >= logging.LEVEL_LOG) {
        logWithPrefix(this.loggingPrefix, 'log', arguments);
    }
};

logging.logInfo = function () {
    if (logging.level >= logging.LEVEL_INFO) {
        logWithPrefix(this.loggingPrefix, 'info', arguments);
    }
};

logging.logWarn = function () {
    if (logging.level >= logging.LEVEL_WARN) {
        logWithPrefix(this.loggingPrefix, 'warn', arguments);
    }
};

logging.logError = function () {
    if (logging.level >= logging.LEVEL_ERROR) {
        logWithPrefix(this.loggingPrefix, 'error', arguments);
    }
};

logging.applyLogging = function (target, loggingPrefix, fromTracingExcludedMethods) {
    const methods = ['logDebug', 'logTrace', 'logError', 'logInfo', 'logWarn'];
    const methods_length = methods.length;
    let disableTracing = false;

    if (typeof fromTracingExcludedMethods !== 'undefined' && fromTracingExcludedMethods === false) {
        disableTracing = true;
    }

    fromTracingExcludedMethods = fromTracingExcludedMethods || ['logDebug', 'logTrace', 'logError', 'logInfo', 'logWarn'];

    target.loggingPrefix = loggingPrefix;

    if (!disableTracing) {
        for (const key in target) {
            if (typeof target[key] === 'function') {
                if (fromTracingExcludedMethods.indexOf(key) < 0) {
                    (function (functionName) {
                        const originalFunction = target[functionName];
                        target[functionName] = function () {
                            if (logging.level >= logging.LEVEL_TRACE) {
                                logWithPrefix(`${target.loggingPrefix}.${functionName}`, 'trace', arguments);
                            }
                            return originalFunction.apply(target, arguments);
                        };
                    }(key));
                }
            }
        }
    }

    for (let i = 0; i < methods_length; i++) {
        target[methods[i]] = target[methods[i]] || logging[methods[i]];
    }
};

logging.setLevel = function (level) {
    logging.level = level;
};

logging.LEVEL_ALL = 127;
logging.LEVEL_TRACE = 6;
logging.LEVEL_LOG = 5;
logging.LEVEL_DEBUG = 5;
logging.LEVEL_INFO = 4;
logging.LEVEL_WARN = 3;
logging.LEVEL_ERROR = 2;
logging.LEVEL_FATAL = 1;
logging.LEVEL_OFF = 0;

logging.level = logging.LEVEL_LOG;

export default logging;
