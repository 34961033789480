import React, { Component } from 'react';

class FacebookIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M35.7 50V32.9H42c.5 0 .8-.4.8-.8v-6.7l-.2-.6c-.2-.2-.4-.2-.6-.2h-6.3v-3.9c0-1.9.5-2.9 2.9-2.9h3.6c.5 0 .8-.4.8-.8v-6.3c0-.5-.4-.8-.8-.8h-5.3c-6 0-9.8 4-9.8 10.1v4.7h-5.3c-.5 0-.8.4-.8.8v6.7c0 .5.4.8.8.8h5.3v17.1h-24A3.3 3.3 0 0 1 0 46.9V3.1C0 1.4 1.4 0 3.1 0h43.8C48.6 0 50 1.4 50 3.1v43.8c0 1.7-1.4 3.1-3.1 3.1H35.7z" fill="#3b5998" /></svg>
    );
  }
}

export default FacebookIcon;
