import React, { Component } from 'react';

class DownloadIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M30.2 20.1h6.6c1.1 0 1.9.9 1.9 1.9 0 .5-.2.9-.5 1.3L26.8 36.1a2 2 0 0 1-2.7.2l-.2-.2-11.4-12.8a2 2 0 0 1 .2-2.7c.4-.3.8-.5 1.3-.5h6.7V4.3c0-1.1.9-1.9 1.9-1.9h5.8c1.1 0 1.9.9 1.9 1.9v15.8zm14 20.7V36a2.9 2.9 0 0 1 5.8 0v7.7c0 1.6-1.3 2.9-2.9 2.9H2.9A2.9 2.9 0 0 1 0 43.7V36a2.9 2.9 0 0 1 5.8 0v4.8h38.4z" /></svg>
    );
  }
}

export default DownloadIcon;
