import React from 'react';
import Modal from 'react-modal';
import Player from '../../../components/player/Player';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
};

export default function ({
  isOpen, headline, description, cover, versionPid, samlToken, preview, onClose,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >
      <i onClick={onClose} className="close" />
      <span className="gr-10 b-overlay__title b-overlay__title--video h1-style">{headline}</span>
      <p className="gr-10 b-overlay__text b-overlay__text--video">{description}</p>

      {isOpen && versionPid
            && (
            <div className="gr-12 no-gutter">
              <div>
                <Player
                  versionPid={versionPid}
                  cover={cover}
                  autoplay
                  samlToken={samlToken}
                  isPreview={preview || false}
                />
              </div>
            </div>
            )}
    </Modal>
  );
}
