import React, { Component } from 'react';

class PlaylistIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M1.8 5.4h41.6c1 0 1.8.8 1.8 1.8v3.6c0 1-.8 1.8-1.8 1.8H1.8c-1 0-1.8-.8-1.8-1.8V7.3c0-1 .8-1.9 1.8-1.9zm0 14.5h25.3c1 0 1.8.8 1.8 1.8v3.6c0 1-.8 1.8-1.8 1.8H1.8c-1 0-1.8-.8-1.8-1.8v-3.6c0-1 .8-1.8 1.8-1.8zm0 14.5h25.3c1 0 1.8.8 1.8 1.8v3.6c0 1-.8 1.8-1.8 1.8H1.8c-1 0-1.8-.8-1.8-1.8v-3.6c0-1 .8-1.8 1.8-1.8zM49.6 32L36.8 44.2c-.6.6-1.5.5-2 0-.3-.3-.4-.6-.4-1V18.7c0-.8.6-1.4 1.4-1.4.4 0 .7.1 1 .4l12.7 12.2c.6.5.7 1.4.1 2.1 0-.1 0-.1 0 0z" /></svg>
    );
  }
}

export default PlaylistIcon;
