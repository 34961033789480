import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import TopTeaser from './TopTeaser';
import BottomTeaser from './BottomTeaser';

import SiteContext from '../../modules/contexts/SiteContext';

class Carousel extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      render: false,
    };

    this.sliderTopRef = (slider) => (this.sliderTop = slider);
    this.sliderBottomRef = (slider) => (this.sliderBottom = slider);
  }

  componentDidMount() {
    this.setState({
      render: true,
    });
  }

  render() {
    return (
      <div className="b-carousel">

        <Slider
          asNavFor={this.sliderBottom}
          ref={this.sliderTopRef}
          fade
          arrows={false}
          swipe={false}
          touchMove={false}
          className="b-carousel__preview"
        >
          {this.props.items.map(TopTeaser)}
        </Slider>

        {this.context !== 'SPECIALEVENT' && (
        <Slider
          asNavFor={this.sliderTop}
          ref={this.sliderBottomRef}
          slidesToShow={4}
          focusOnSelect
          infinite
          swipe={false}
          touchMove={false}
          afterChange={this.onSliderBottomAfterChange}
          className="b-carousel__thumbnails"
        >
          {this.props.items.map(BottomTeaser)}
        </Slider>
        )}
      </div>
    );
  }
}

Carousel.contextType = SiteContext;

export default Carousel;
