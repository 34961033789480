/*
 * custom timezone dataset
 * @see https://jira.dev.bbc.co.uk/browse/WSPARTNERS-881
 */
const timezones = [
  { label: 'Afghanistan', zone: 'Asia/Kabul' },
  { label: 'Åland', zone: 'Europe/Mariehamn' },
  { label: 'Albania', zone: 'Europe/Tirane' },
  { label: 'Algeria', zone: 'Africa/Algiers' },
  { label: 'Andorra', zone: 'Europe/Andorra' },
  { label: 'Angola', zone: 'Africa/Luanda' },
  { label: 'Anguilla', zone: 'America/Anguilla' },
  { label: 'Antarctica', zone: 'Antarctica/Vostok' },
  { label: 'Antigua and Barbuda', zone: 'America/Antigua' },
  { label: 'Argentina', zone: 'America/Argentina/Ushuaia' },
  { label: 'Armenia', zone: 'Asia/Yerevan' },
  { label: 'Aruba', zone: 'America/Aruba' },
  { label: 'Australia - Central', zone: 'Australia/Adelaide' },
  { label: 'Australia - Central Western', zone: 'Australia/Eucla' },
  { label: 'Australia - Eastern', zone: 'Australia/Sydney' },
  { label: 'Australia - Lord Howe', zone: 'Australia/Lord_Howe' },
  { label: 'Australia - Western', zone: 'Australia/Perth' },
  { label: 'Austria', zone: 'Europe/Vienna' },
  { label: 'Azerbaijan', zone: 'Asia/Baku' },
  { label: 'Bahamas', zone: 'America/Nassau' },
  { label: 'Bahrain', zone: 'Asia/Bahrain' },
  { label: 'Bangladesh', zone: 'Asia/Dhaka' },
  { label: 'Barbados', zone: 'America/Barbados' },
  { label: 'Belarus', zone: 'Europe/Minsk' },
  { label: 'Belgium', zone: 'Europe/Brussels' },
  { label: 'Belize', zone: 'America/Belize' },
  { label: 'Benin', zone: 'Africa/Porto-Novo' },
  { label: 'Bermuda', zone: 'Atlantic/Bermuda' },
  { label: 'Bhutan', zone: 'Asia/Thimphu' },
  { label: 'Bolivia', zone: 'America/La_Paz' },
  { label: 'Bonaire', zone: 'America/Kralendijk' },
  { label: 'Bosnia and Herzegovina', zone: 'Europe/Sarajevo' },
  { label: 'Botswana', zone: 'Africa/Gaborone' },
  { label: 'Brazil - Acre', zone: 'America/Rio_Branco' },
  { label: 'Brazil - Brasília', zone: 'America/Sao_Paulo' },
  { label: 'Brazil - Fernando de Noronha', zone: 'America/Noronha' },
  { label: 'Brazil -Amazon', zone: 'America/Manaus' },
  { label: 'British Indian Ocean Territory', zone: 'Indian/Chagos' },
  { label: 'British Virgin Islands', zone: 'America/Tortola' },
  { label: 'Brunei', zone: 'Asia/Brunei' },
  { label: 'Bulgaria', zone: 'Europe/Sofia' },
  { label: 'Burkina Faso', zone: 'Africa/Ouagadougou' },
  { label: 'Burundi', zone: 'Africa/Bujumbura' },
  { label: 'Cabo Verde', zone: 'Atlantic/Cape_Verde' },
  { label: 'Cambodia', zone: 'Asia/Phnom_Penh' },
  { label: 'Cameroon', zone: 'Africa/Douala' },
  { label: 'Canada', zone: 'America/Yellowknife' },
  { label: 'Canada - Atlantic', zone: 'America/Halifax' },
  { label: 'Canada - Central', zone: 'America/Atikokan' },
  { label: 'Canada - Eastern', zone: 'America/Blanc-Sablon' },
  { label: 'Canada - Mountain', zone: 'America/Edmonton' },
  { label: 'Canada - Pacific', zone: 'America/Vancouver' },
  { label: 'Cayman Islands', zone: 'America/Cayman' },
  { label: 'Central African Republic', zone: 'Africa/Bangui' },
  { label: 'Chad', zone: 'Africa/Ndjamena' },
  { label: 'Chile', zone: 'Pacific/Easter' },
  { label: 'China', zone: 'Asia/Urumqi' },
  { label: 'Christmas Island', zone: 'Indian/Christmas' },
  { label: 'Cocos [Keeling] Islands', zone: 'Indian/Cocos' },
  { label: 'Colombia', zone: 'America/Bogota' },
  { label: 'Comoros', zone: 'Indian/Comoro' },
  { label: 'Cook Islands', zone: 'Pacific/Rarotonga' },
  { label: 'Costa Rica', zone: 'America/Costa_Rica' },
  { label: 'Croatia', zone: 'Europe/Zagreb' },
  { label: 'Cuba', zone: 'America/Havana' },
  { label: 'Curacao', zone: 'America/Curacao' },
  { label: 'Cyprus', zone: 'Asia/Nicosia' },
  { label: 'Czechia', zone: 'Europe/Prague' },
  { label: 'Democratic Republic of the Congo', zone: 'Africa/Lubumbashi' },
  { label: 'Denmark', zone: 'Europe/Copenhagen' },
  { label: 'Djibouti', zone: 'Africa/Djibouti' },
  { label: 'Dominica', zone: 'America/Dominica' },
  { label: 'Dominican Republic', zone: 'America/Santo_Domingo' },
  { label: 'Ecuador', zone: 'Pacific/Galapagos' },
  { label: 'Egypt', zone: 'Africa/Cairo' },
  { label: 'El Salvador', zone: 'America/El_Salvador' },
  { label: 'Equatorial Guinea', zone: 'Africa/Malabo' },
  { label: 'Eritrea', zone: 'Africa/Asmara' },
  { label: 'Estonia', zone: 'Europe/Tallinn' },
  { label: 'Eswatini', zone: 'Africa/Mbabane' },
  { label: 'Ethiopia', zone: 'Africa/Addis_Ababa' },
  { label: 'Falkland Islands', zone: 'Atlantic/Stanley' },
  { label: 'Faroe Islands', zone: 'Atlantic/Faroe' },
  { label: 'Fiji', zone: 'Pacific/Fiji' },
  { label: 'Finland', zone: 'Europe/Helsinki' },
  { label: 'France', zone: 'Europe/Paris' },
  { label: 'French Guiana', zone: 'America/Cayenne' },
  { label: 'French Polynesia', zone: 'Pacific/Tahiti' },
  { label: 'French Southern Territories', zone: 'Indian/Kerguelen' },
  { label: 'Gabon', zone: 'Africa/Libreville' },
  { label: 'Gambia', zone: 'Africa/Banjul' },
  { label: 'Georgia', zone: 'Asia/Tbilisi' },
  { label: 'Germany', zone: 'Europe/Berlin' },
  { label: 'Ghana', zone: 'Africa/Accra' },
  { label: 'Gibraltar', zone: 'Europe/Gibraltar' },
  { label: 'Greece', zone: 'Europe/Athens' },
  { label: 'Greenland', zone: 'America/Thule' },
  { label: 'Grenada', zone: 'America/Grenada' },
  { label: 'Guadeloupe', zone: 'America/Guadeloupe' },
  { label: 'Guatemala', zone: 'America/Guatemala' },
  { label: 'Guernsey', zone: 'Europe/Guernsey' },
  { label: 'Guinea', zone: 'Africa/Conakry' },
  { label: 'Guinea-Bissau', zone: 'Africa/Bissau' },
  { label: 'Guyana', zone: 'America/Guyana' },
  { label: 'Haiti', zone: 'America/Port-au-Prince' },
  { label: 'Honduras', zone: 'America/Tegucigalpa' },
  { label: 'Hong Kong', zone: 'Asia/Hong_Kong' },
  { label: 'Hungary', zone: 'Europe/Budapest' },
  { label: 'Iceland', zone: 'Atlantic/Reykjavik' },
  { label: 'India', zone: 'Asia/Kolkata' },
  { label: 'Indonesia', zone: 'Asia/Pontianak' },
  { label: 'Indonesia - Western', zone: 'Asia/Jakarta' },
  { label: 'Indonesia -Central', zone: 'Asia/Makassar' },
  { label: 'Indonesia -Eastern', zone: 'Asia/Jayapura' },
  { label: 'Iran', zone: 'Asia/Tehran' },
  { label: 'Iraq', zone: 'Asia/Baghdad' },
  { label: 'Ireland', zone: 'Europe/Dublin' },
  { label: 'Isle of Man', zone: 'Europe/Isle_of_Man' },
  { label: 'Israel', zone: 'Asia/Jerusalem' },
  { label: 'Italy', zone: 'Europe/Rome' },
  { label: 'Ivory Coast', zone: 'Africa/Abidjan' },
  { label: 'Jamaica', zone: 'America/Jamaica' },
  { label: 'Japan', zone: 'Asia/Tokyo' },
  { label: 'Jersey', zone: 'Europe/Jersey' },
  { label: 'Jordan', zone: 'Asia/Amman' },
  { label: 'Kamchatka', zone: 'Asia/Kamchatka' },
  { label: 'Kazakhstan', zone: 'Asia/Qyzylorda' },
  { label: 'Kenya', zone: 'Africa/Nairobi' },
  { label: 'Kiribati', zone: 'Pacific/Tarawa' },
  { label: 'Kuwait', zone: 'Asia/Kuwait' },
  { label: 'Kyrgyzstan', zone: 'Asia/Bishkek' },
  { label: 'Laos', zone: 'Asia/Vientiane' },
  { label: 'Latvia', zone: 'Europe/Riga' },
  { label: 'Lebanon', zone: 'Asia/Beirut' },
  { label: 'Lesotho', zone: 'Africa/Maseru' },
  { label: 'Liberia', zone: 'Africa/Monrovia' },
  { label: 'Libya', zone: 'Africa/Tripoli' },
  { label: 'Liechtenstein', zone: 'Europe/Vaduz' },
  { label: 'Lithuania', zone: 'Europe/Vilnius' },
  { label: 'Luxembourg', zone: 'Europe/Luxembourg' },
  { label: 'Macao', zone: 'Asia/Macau' },
  { label: 'Madagascar', zone: 'Indian/Antananarivo' },
  { label: 'Malawi', zone: 'Africa/Blantyre' },
  { label: 'Malaysia', zone: 'Asia/Kuching' },
  { label: 'Maldives', zone: 'Indian/Maldives' },
  { label: 'Mali', zone: 'Africa/Bamako' },
  { label: 'Malta', zone: 'Europe/Malta' },
  { label: 'Marshall Islands', zone: 'Pacific/Majuro' },
  { label: 'Martinique', zone: 'America/Martinique' },
  { label: 'Mauritania', zone: 'Africa/Nouakchott' },
  { label: 'Mauritius', zone: 'Indian/Mauritius' },
  { label: 'Mayotte', zone: 'Indian/Mayotte' },
  { label: 'Mexico - Central', zone: 'America/Bahia_Banderas' },
  { label: 'Mexico - Eastern', zone: 'America/Cancun' },
  { label: 'Mexico - Pacific', zone: 'America/Tijuana' },
  { label: 'Mexico - Mountain', zone: 'America/Chihuahua' },
  { label: 'Micronesia', zone: 'Pacific/Pohnpei' },
  { label: 'Moldova', zone: 'Europe/Chisinau' },
  { label: 'Monaco', zone: 'Europe/Monaco' },
  { label: 'Mongolia', zone: 'Asia/Ulaanbaatar' },
  { label: 'Montenegro', zone: 'Europe/Podgorica' },
  { label: 'Montserrat', zone: 'America/Montserrat' },
  { label: 'Morocco', zone: 'Africa/Casablanca' },
  { label: 'Mozambique', zone: 'Africa/Maputo' },
  { label: 'Myanmar [Burma]', zone: 'Asia/Yangon' },
  { label: 'Namibia', zone: 'Africa/Windhoek' },
  { label: 'Nauru', zone: 'Pacific/Nauru' },
  { label: 'Nepal', zone: 'Asia/Kathmandu' },
  { label: 'Netherlands', zone: 'Europe/Amsterdam' },
  { label: 'New Caledonia', zone: 'Pacific/Noumea' },
  { label: 'New Zealand', zone: 'pacific/Auckland' },
  { label: 'New Zealand - Chatham Islands', zone: 'pacific/Chatham' },
  { label: 'Nicaragua', zone: 'America/Managua' },
  { label: 'Niger', zone: 'Africa/Niamey' },
  { label: 'Nigeria', zone: 'Africa/Lagos' },
  { label: 'Niue', zone: 'Pacific/Niue' },
  { label: 'Norfolk Island', zone: 'Pacific/Norfolk' },
  { label: 'North Korea', zone: 'Asia/Pyongyang' },
  { label: 'North Macedonia', zone: 'Europe/Skopje' },
  { label: 'Northern Mariana Islands', zone: 'Pacific/Saipan' },
  { label: 'Norway', zone: 'Europe/Oslo' },
  { label: 'Oman', zone: 'Asia/Muscat' },
  { label: 'Pakistan', zone: 'Asia/Karachi' },
  { label: 'Palau', zone: 'Pacific/Palau' },
  { label: 'Palestine', zone: 'Asia/Hebron' },
  { label: 'Panama', zone: 'America/Panama' },
  { label: 'Papua New Guinea', zone: 'Pacific/Port_Moresby' },
  { label: 'Paraguay', zone: 'America/Asuncion' },
  { label: 'Peru', zone: 'America/Lima' },
  { label: 'Philippines', zone: 'Asia/Manila' },
  { label: 'Pitcairn Islands', zone: 'Pacific/Pitcairn' },
  { label: 'Poland', zone: 'Europe/Warsaw' },
  { label: 'Portugal', zone: 'Europe/Lisbon' },
  { label: 'Puerto Rico', zone: 'America/Puerto_Rico' },
  { label: 'Qatar', zone: 'Asia/Qatar' },
  { label: 'Republic of the Congo', zone: 'Africa/Brazzaville' },
  { label: 'Réunion', zone: 'Indian/Reunion' },
  { label: 'Romania', zone: 'Europe/Bucharest' },
  { label: 'Russia', zone: 'Europe/Volgograd' },
  { label: 'Russia - Irkutsk', zone: 'Asia/Irkutsk' },
  { label: 'Russia - Kaliningrad', zone: 'Europe/Kaliningrad' },
  { label: 'Russia - Magadan', zone: 'Asia/Magadan' },
  { label: 'Russia - Moscow', zone: 'Europe/Moscow' },
  { label: 'Russia - Samara', zone: 'Europe/Samara' },
  { label: 'Russia - Vladivostok', zone: 'Asia/Vladivostok' },
  { label: 'Russia - Krasnoyarsk', zone: 'Asia/Krasnoyarsk' },
  { label: 'Russia - Omsk', zone: 'Asia/Omsk' },
  { label: 'Russia - Yakutsk', zone: 'Asia/Yakutsk' },
  { label: 'Russia - Yekaterinburg', zone: 'Asia/Yekaterinburg' },
  { label: 'Rwanda', zone: 'Africa/Kigali' },
  { label: 'Saint Barthélemy', zone: 'America/St_Barthelemy' },
  { label: 'Saint Helena', zone: 'Atlantic/St_Helena' },
  { label: 'Saint Kitts and Nevis', zone: 'America/St_Kitts' },
  { label: 'Saint Lucia', zone: 'America/St_Lucia' },
  { label: 'Saint Martin', zone: 'America/Marigot' },
  { label: 'Saint Pierre and Miquelon', zone: 'America/Miquelon' },
  { label: 'Saint Vincent and the Grenadines', zone: 'America/St_Vincent' },
  { label: 'Samoa', zone: 'Pacific/Apia' },
  { label: 'San Marino', zone: 'Europe/San_Marino' },
  { label: 'São Tomé and Príncipe', zone: 'Africa/Sao_Tome' },
  { label: 'Saudi Arabia', zone: 'Asia/Riyadh' },
  { label: 'Senegal', zone: 'Africa/Dakar' },
  { label: 'Serbia', zone: 'Europe/Belgrade' },
  { label: 'Seychelles', zone: 'Indian/Mahe' },
  { label: 'Sierra Leone', zone: 'Africa/Freetown' },
  { label: 'Singapore', zone: 'Asia/Singapore' },
  { label: 'Sint Maarten', zone: 'America/Lower_Princes' },
  { label: 'Slovakia', zone: 'Europe/Bratislava' },
  { label: 'Slovenia', zone: 'Europe/Ljubljana' },
  { label: 'Solomon Islands', zone: 'Pacific/Guadalcanal' },
  { label: 'Somalia', zone: 'Africa/Mogadishu' },
  { label: 'South Africa', zone: 'Africa/Johannesburg' },
  { label: 'South Georgia and the South Sandwich Islands', zone: 'Atlantic/South_Georgia' },
  { label: 'South Korea', zone: 'Asia/Seoul' },
  { label: 'South Sudan', zone: 'Africa/Juba' },
  { label: 'Spain', zone: 'Europe/Madrid' },
  { label: 'Sri Lanka', zone: 'Asia/Colombo' },
  { label: 'Sudan', zone: 'Africa/Khartoum' },
  { label: 'Suriname', zone: 'America/Paramaribo' },
  { label: 'Svalbard and Jan Mayen', zone: 'Arctic/Longyearbyen' },
  { label: 'Sweden', zone: 'Europe/Stockholm' },
  { label: 'Switzerland', zone: 'Europe/Zurich' },
  { label: 'Syria', zone: 'Asia/Damascus' },
  { label: 'Taiwan', zone: 'Asia/Taipei' },
  { label: 'Tajikistan', zone: 'Asia/Dushanbe' },
  { label: 'Tanzania', zone: 'Africa/Dar_es_Salaam' },
  { label: 'Thailand', zone: 'Asia/Bangkok' },
  { label: 'Timor-Leste', zone: 'Asia/Dili' },
  { label: 'Togo', zone: 'Africa/Lome' },
  { label: 'Tokelau', zone: 'Pacific/Fakaofo' },
  { label: 'Tonga', zone: 'Pacific/Tongatapu' },
  { label: 'Trinidad and Tobago', zone: 'America/Port_of_Spain' },
  { label: 'Tunisia', zone: 'Africa/Tunis' },
  { label: 'Turkey', zone: 'Europe/Istanbul' },
  { label: 'Turkmenistan', zone: 'Asia/Ashgabat' },
  { label: 'Turks and Caicos Islands', zone: 'America/Grand_Turk' },
  { label: 'Tuvalu', zone: 'Pacific/Funafuti' },
  { label: 'Uganda', zone: 'Africa/Kampala' },
  { label: 'Ukraine', zone: 'Europe/Zaporozhye' },
  { label: 'United Arab Emirates', zone: 'Asia/Dubai' },
  { label: 'United Kingdom', zone: 'Europe/London' },
  { label: 'Uruguay', zone: 'America/Montevideo' },
  { label: 'US Alaska', zone: 'America/Anchorage' },
  { label: 'US Central', zone: 'America/Chicago' },
  { label: 'US Chamorro', zone: 'Pacific/Guam' },
  { label: 'US Eastern', zone: 'America/New_York' },
  { label: 'US Hawaii', zone: 'Pacific/Honolulu' },
  { label: 'US Minor Outlying Islands', zone: 'Pacific/Wake' },
  { label: 'US Mountain', zone: 'America/Denver' },
  { label: 'US Pacific', zone: 'America/Los_Angeles' },
  { label: 'US Samoa', zone: 'Pacific/Pago_Pago' },
  { label: 'US Virgin Islands', zone: 'America/St_Thomas' },
  { label: 'Uzbekistan', zone: 'Asia/Tashkent' },
  { label: 'Vanuatu', zone: 'Pacific/Efate' },
  { label: 'Vatican City', zone: 'Europe/Vatican' },
  { label: 'Venezuela', zone: 'America/Caracas' },
  { label: 'Vietnam', zone: 'Asia/Ho_Chi_Minh' },
  { label: 'Wallis and Futuna', zone: 'Pacific/Wallis' },
  { label: 'Western Sahara', zone: 'Africa/El_Aaiun' },
  { label: 'Yemen', zone: 'Asia/Aden' },
  { label: 'Zambia', zone: 'Africa/Lusaka' },
  { label: 'Zimbabwe', zone: 'Africa/Harare' },
];

export default timezones;
