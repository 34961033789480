import React, { Component, Fragment } from 'react';
import Media from 'react-media';
import cx from 'classnames';
import logging from '../../lib/logging';

class Tabs extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'TabModule');
    this.logDebug('init:', props);
    this.state = {
      selected: props.defaultTab || 0,
    };
  }

  render() {
    const { selected } = this.state;
    const { children } = this.props;

    return (
      <div className="b-tabs">
        <Media query="(max-width: 900px)">
          {(matches) => (matches ? (
            <ul>
              { children.map((tab, index) => (
                <li key={index} onClick={() => this.setState({ selected: index })} className={cx('mobile-item', { 'is-active': selected === index })}>
                  <div className="title">
                    { tab.props.children.filter((child) => child.type.displayName === 'TabModuleTitle')[0] }
                  </div>
                  <div className="content">
                    { tab.props.children.filter((child) => child.type.displayName === 'TabModuleContent')[0] }
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="desktop">
              <ul className="desktop-titles">
                { children.map((tab, index) => (
                  <li onClick={() => this.setState({ selected: index })} key={index} className={cx('title', { 'is-active': selected === index })}>
                    { tab.props.children.filter((child) => child.type.displayName === 'TabModuleTitle')[0] }
                  </li>
                ))}
              </ul>

              <div className="desktop-content">
                <div className="content">
                  <ul>
                    { children.map((tab, item) => (
                      <li key={item} className={cx('inner', { 'is-active': selected === item })}>
                        { tab.props.children.filter((child) => child.type.displayName === 'TabModuleContent')[0] }
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Media>
      </div>
    );
  }
}

Tabs.Tab = function ({ children }) {
  return <>{children}</>;
};
Tabs.Tab.displayName = 'TabModuleTab';

Tabs.Title = function ({ children }) {
  return <>{children}</>;
};
Tabs.Title.displayName = 'TabModuleTitle';

Tabs.Content = function ({ children }) {
  return <>{children}</>;
};
Tabs.Content.displayName = 'TabModuleContent';

export default Tabs;
