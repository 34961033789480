import React from 'react';
import cx from 'classnames';

export default function ({
  field, required, form: {
    touched, errors, setFieldValue, setFieldTouched,
  }, label, ...props
}) {
  const hasError = touched[field.name] && errors[field.name];
  return (
    <div className={cx('form-element form-element--textarea', {
      'form-element--error': hasError,
    })}
    >
      <label className="form-element--textarea__label" htmlFor="check-1">
        <span>
          {label}
          {required ? '*' : ''}
        </span>
      </label>
      <textarea
        className="form-element form-element--textarea__textarea"
        {...field}
        {...props}
        onChange={(ev) => {
          setFieldTouched(field.name, true);
          setFieldValue(field.name, ev.target.value);
        }}
        onBlur={() => setFieldTouched(field.name, true)}
        autoComplete="off"
      />
      {hasError && <span className="form-element__error-message">{errors[field.name]}</span>}
    </div>
  );
}
