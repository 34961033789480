import React, { Component } from 'react';

class BBCLogoIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 44">
        <g id="Group" transform="translate(11 11)">
          <path className="st0" d="M77.5 0v21.9H54.3V0h23.2zm-4.6 14.6l-.4.2a11 11 0 0 1-5.5 1.7c-3.8 0-6.3-2.3-6.3-5.5s2.6-5.6 6.2-5.6c1.8 0 3.5.5 5.5 1.6l.4.2V4.3l-.2-.1c-2.2-.9-4-1.3-5.7-1.3-2.6 0-4.9.9-6.5 2.3a7.7 7.7 0 0 0-.4 11.2 9 9 0 0 0 6.8 2.7c2.6 0 4.4-.6 6-1.4l.1-.1v-3z" id="Shape" />
          <g id="Shape_1_">
            <path className="st0" d="M23.7 0v21.9H.5V0h23.2zm-5.5 14.5c0-2-1.3-3.5-3.4-4.1.6-.3 1-.6 1.4-1.1.4-.6.7-1.3.7-2.1 0-1-.4-2.1-1.3-2.8a5.8 5.8 0 0 0-4-1.3H6.7v15.7h5.8c2.1 0 3.5-.6 4.5-1.4.8-.8 1.2-1.9 1.2-2.9z" transform="translate(27)" />
            <path className="st0" d="M15.3 14.2c0 .5-.2 1-.6 1.5-.5.4-1.3.7-2.6.7H9.4V12H12c1.2 0 2 .2 2.6.6.5.4.7.9.7 1.6zM13.2 9c.5-.4.7-.9.7-1.6 0-.5-.2-1-.5-1.3-.4-.3-1-.6-2.1-.6H9.4v4.1h1.3c1.2 0 2-.2 2.5-.6z" transform="translate(27)" />
          </g>
          <g id="Shape_2_">
            <path className="st0" d="M24 0v21.9H.8V0H24zm-5.5 14.5c0-2-1.3-3.5-3.4-4.1.6-.3 1-.6 1.4-1.1.4-.6.7-1.3.7-2.1 0-1-.4-2.1-1.3-2.8a5.8 5.8 0 0 0-4-1.3h-5v15.7h5.8c2.1 0 3.5-.6 4.5-1.4.9-.8 1.3-1.9 1.3-2.9z" />
            <path className="st0" d="M13.4 9c.5-.4.7-.9.7-1.6 0-.5-.2-1-.5-1.3-.4-.3-1-.6-2.1-.6H9.6v4.1H11c1.1 0 1.9-.2 2.4-.6zM14.8 12.7c-.5-.4-1.4-.6-2.6-.6H9.6v4.3h2.7c1.3 0 2.1-.3 2.6-.7.5-.4.6-.9.6-1.5.1-.7-.2-1.2-.7-1.5z" />
          </g>
        </g>
        <g id="Group_1_" transform="translate(100 13)">
          <path className="st0" d="M12.8 1.1h2.1v15.8H13L2.4 4.7v12.2H.3V1.1h1.8l10.6 12.3V1.1z" id="Shape_3_" />
          <path className="st0" d="M19 1.1h8.9v2h-6.7v4.8h6.5v2h-6.5v4.9h6.9v2H19z" id="Shape_4_" />
          <path className="st0" d="M52.1 1.1h2.3L48 16.9h-.5L42.3 4.1l-5.2 12.8h-.5L30.2 1h2.3l4.4 10.9L41.3 1h2.1l4.4 10.9z" id="Shape_5_" />
          <path className="st0" d="M60.5 10.2l-1.7-1c-1.1-.7-1.8-1.3-2.3-1.9s-.7-1.4-.7-2.2c0-1.2.4-2.3 1.3-3 .9-.8 2-1.2 3.4-1.2 1.3 0 2.5.4 3.6 1.1v2.6A5.2 5.2 0 0 0 60.4 3c-.7 0-1.3.2-1.8.5s-.7.8-.7 1.3.2.9.5 1.3.9.8 1.7 1.3l1.7 1c1.9 1.2 2.9 2.6 2.9 4.4 0 1.3-.4 2.3-1.3 3.1-.8.8-1.9 1.2-3.3 1.2-1.6 0-3-.5-4.3-1.4v-2.9c1.2 1.5 2.6 2.3 4.2 2.3.7 0 1.3-.2 1.8-.6s.7-.9.7-1.5c0-1-.6-1.9-2-2.8z" id="Shape_6_" />
        </g>
        <g id="Group_2_" transform="translate(195 16)">
          <path className="st0" d="M15.5 1H17l-4.3 10.6h-.3L8.9 3l-3.5 8.6h-.3L.9 1h1.5l2.9 7.3L8.2 1h1.4l2.9 7.3z" id="Shape_7_" />
          <path className="st0" d="M23.7.9c1.6 0 3 .5 4.1 1.6 1.1 1 1.6 2.3 1.6 3.9s-.5 2.8-1.6 3.9a6 6 0 0 1-4.1 1.5c-1.6 0-2.9-.5-4-1.5a5 5 0 0 1-1.6-3.8 5 5 0 0 1 1.6-3.9 5 5 0 0 1 4-1.7zm.1 1.4c-1.2 0-2.2.4-3 1.1-.8.8-1.2 1.7-1.2 2.9 0 1.1.4 2.1 1.2 2.8.8.7 1.8 1.1 2.9 1.1a4 4 0 0 0 3-1.2c.8-.8 1.2-1.7 1.2-2.9 0-1.1-.4-2.1-1.2-2.8-.8-.6-1.8-1-2.9-1z" id="Shape_8_" />
          <path className="st0" d="M32.1 11.6V1h2.6c1.1 0 1.9.3 2.5.8.6.5.9 1.2.9 2.1a3 3 0 0 1-.5 1.6c-.3.4-.7.8-1.3 1l1 .9 1.4 2 .9 1.4.6.8h-1.8l-.5-.7-.1-.1-.3-.4-.5-.8-.5-.8-.9-1-.7-.6-1.1-.2h-.4v4.6h-1.3zm2-9.3h-.5v3.3h.6l1.6-.2c.3-.1.5-.3.7-.6.2-.3.2-.6.2-.9l-.3-.9c-.2-.3-.4-.5-.7-.6l-1.6-.1z" id="Shape_9_" />
          <path className="st0" d="M42.3 1h1.5v9.2h4.8v1.4h-6.3z" id="Shape_10_" />
          <path className="st0" d="M50.7 11.6V1h3.6c1.4 0 2.6.2 3.4.6s1.5 1.1 2 1.9.7 1.8.7 2.7c0 .7-.1 1.4-.4 2s-.7 1.2-1.2 1.7-1.1.9-1.8 1.1l-1.1.3-1.9.1h-3.3v.2zm3.4-9.2h-1.9v7.9h1.9l1.8-.2c.4-.1.8-.2 1-.4l.8-.6c.7-.8 1.1-1.7 1.1-2.9 0-1.1-.4-2.1-1.2-2.8l-1-.7-1-.3h-1.5z" id="Shape_11_" />
          <path className="st0" d="M70.8 7.2l-1.2-.7a5 5 0 0 1-1.5-1.3c-.3-.4-.5-.9-.5-1.5 0-.8.3-1.5.9-2s1.3-.8 2.3-.8a4 4 0 0 1 2.4.7v1.7a3.8 3.8 0 0 0-2.5-1.1c-.5 0-.9.1-1.2.3s-.5.5-.5.9c0 .3.1.6.4.9.2.3.6.6 1.1.9l1.2.7c1.3.8 1.9 1.8 1.9 2.9 0 .8-.3 1.5-.9 2.1-.6.5-1.3.8-2.2.8-1 0-2-.3-2.9-1V8.8c.8 1 1.8 1.6 2.8 1.6.5 0 .9-.1 1.2-.4.3-.3.5-.6.5-1 .1-.6-.4-1.3-1.3-1.8z" id="Shape_12_" />
          <path className="st0" d="M76.3 1h6v1.3h-4.5v3.2h4.3v1.4h-4.3v3.3h4.6v1.3h-6.1z" id="Shape_13_" />
          <path className="st0" d="M85 11.6V1h2.6c1.1 0 1.9.3 2.5.8S91 3 91 3.9a3 3 0 0 1-.5 1.6c-.3.4-.7.8-1.3 1l1 .9 1.4 2 .9 1.4.6.8h-1.8l-.5-.7-.1-.1-.3-.4-.5-.8-.5-.8-.9-1-.7-.6-1.1-.2h-.4v4.6H85zm2-9.3h-.5v3.3h.6l1.6-.2c.3-.1.5-.3.7-.6.2-.3.2-.6.2-.9l-.3-.9c-.2-.3-.4-.5-.7-.6L87 2.3z" id="Shape_14_" />
          <path className="st0" d="M101 1h1.5L98 11.6h-.3L93.2 1h1.5l3.2 7.4z" id="Shape_15_" />
          <path className="st0" d="M104.6 1h1.5v10.6h-1.5z" id="Shape_16_" />
          <path className="st0" d="M118.3 9.1v1.7c-1.1.6-2.4.9-3.8.9-1.2 0-2.2-.2-3-.7s-1.5-1.1-2-2a5 5 0 0 1-.7-2.7c0-1.5.5-2.8 1.6-3.9a6 6 0 0 1 4.1-1.6c1.1 0 2.3.3 3.7.9v1.6a7.2 7.2 0 0 0-3.6-1.1c-1.2 0-2.2.4-3 1.1-.8.8-1.2 1.7-1.2 2.9s.4 2.1 1.2 2.9a4 4 0 0 0 3 1.1c1.3.1 2.5-.3 3.7-1.1z" id="Shape_17_" />
          <path className="st0" d="M121.1 1h6v1.3h-4.5v3.2h4.3v1.4h-4.3v3.3h4.6v1.3h-6.1z" id="Shape_18_" />
        </g>
        <path className="st0" d="M179.1 10.7h2v22.6h-2z" id="Shape_19_" />
      </svg>
    );
  }
}

export default BBCLogoIcon;
