import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import clientStateOptions from './clientState';
import InMemoryCacheOptions from './inMemory';

const stateLink = withClientState(clientStateOptions);

/*
const defaultOptions = {
    query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};
*/

export default new ApolloClient({
  // defaultOptions,
  link: ApolloLink.from([stateLink, createHttpLink({ uri: '/graphql', credentials: 'include' })]),
  cache: new InMemoryCache(InMemoryCacheOptions).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 250,
});
