import React, { Component } from 'react';
import Select from 'react-select';
import cx from 'classnames';
import logging from '../../../lib/logging';

class Selectbox extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Selectbox', false);
    this.logDebug('init:', props);
  }

  renderOption = (option) => (
    <div value={option.value || this.props.getOptionValue(option)}>
      { (this.props.getOptionLabel && this.props.getOptionLabel(option)) || option.label }
    </div>
  );

  renderValue = (option) => (
    <span className="Select-value-label" role="option" aria-selected="true">
      { (this.props.getOptionLabel && this.props.getOptionLabel(option)) || option.label }
    </span>
  );

  render() {
    const {
      id,
      label,
      onChange,
      className,
      ...restProperties
    } = this.props;

    return (
      <div className="b-selectbox-wrapper">
        {label
                    && <span className="b-selectbox-wrapper__label">{label}</span>}
        <Select
          onChange={id ? (selectedOptions) => onChange(id, selectedOptions) : onChange}
          className={cx('b-selectbox', className)}
          optionRenderer={this.renderOption}
          valueRenderer={this.renderValue}
          {...restProperties}
        />
      </div>
    );
  }
}

export default Selectbox;
