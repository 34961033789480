import React, { Component } from 'react';
import cx from 'classnames';
import logging from '../../../lib/logging';

class WidgetFaq extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetFaq');
    this.logDebug('init:', props);
    this.state = {
      selected: 0,
      letter: 'a',
    };

    this.glossaryAnchors = null;
  }

  getGlossaryAnchors() {
    if (!this.glossaryAnchors) {
      const anchors = this.props.items.map((item) => item.question.charAt(0).toUpperCase());
      const uniqueAnchors = anchors.filter((item, index, array) => array.indexOf(item) === index);
      this.glossaryAnchors = uniqueAnchors.sort();
    }

    return this.glossaryAnchors;
  }

  render() {
    const { items, panelTitle } = this.props;
    const { selected } = this.state;

    const isGlossary = (panelTitle && panelTitle.indexOf('#glossary') > -1);

    if (isGlossary) {
      return this.renderGlossary(items);
    }

    return (
      <section className="b-widget-faq gr-12">
        <ul className="list">
          {items.map((item, index) => (
            <li onClick={() => this.setState({ selected: index })} key={index} className={cx('item', { 'is-open': selected === index })}>
              <div className="title">
                <span>{item.question}</span>
              </div>
              <div className="content gr-12 gr-10@m gr-8@l" dangerouslySetInnerHTML={{ __html: item.answer }} />
            </li>
          ))}
        </ul>
      </section>
    );
  }

  changeLetter = (item) => {
    const letter = item.toLowerCase();
    this.setState({
      letter,
    });
    if (document.getElementById(`glossary-anchor-${letter}`)) {
      Array.from(document.getElementsByClassName('item')).forEach((el) => {
        el.classList.remove('is-open');
      });

      document.getElementById(`glossary-anchor-${letter}`).classList.add('is-open');
      // window.scrollTo(0, document.getElementById(`glossary-anchor-${letter}`).offsetTop);

      document.getElementById(`glossary-anchor-${letter}`).scrollIntoView();
    }
  };

  renderGlossary(items) {
    const { selected } = this.state;

    let previousFirstLetter = null;

    return (
      <section className="b-widget-faq is-glossary gr-12">

        <div className="anchor-list">
          <ul>
            { this.getGlossaryAnchors().map((item) => <li key={item}><a className={`${this.state.letter === item.toLowerCase() && 'active'}`} onClick={() => this.changeLetter(item)}><span>{item}</span></a></li>) }
          </ul>
        </div>

        <ul className="list">
          {items.map((item, index) => {
            const firstLetter = item.question.charAt(0).toLowerCase();
            const hasAnchor = (firstLetter !== previousFirstLetter);
            previousFirstLetter = firstLetter;

            return (
              <li
                id={hasAnchor ? `glossary-anchor-${firstLetter}` : null}
                onClick={() => this.setState({ selected: index })}
                key={index}
                className={cx('item', { 'is-open': selected === index })}
              >
                <div className="title">
                  <span>{item.question}</span>
                </div>
                <div className="content gr-12 gr-10@m gr-8@l" dangerouslySetInnerHTML={{ __html: item.answer }} />
              </li>
            );
          })}
        </ul>

      </section>
    );
  }
}

export default WidgetFaq;
