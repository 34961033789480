import React, { Component } from 'react';
import Detailpage from './Detailpage';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './DetailpageClip.gql';

class DetailpageClip extends Component {
  render() {
    const { data } = this.props;

    return (
      <Detailpage
        entity="clip"
        {...data}
        {...data.clip}
        subTitle={data.clip.title}
        title={(data.clip.episode || data.clip.brand || { title: 'MISSING PARENT TITLE' }).title}
        path={(data.clip.episode || data.clip.brand || { path: 'MISSING PATH' }).path}
        intro={data.clip.synopsisBrand}
        description={data.clip.clip.synopsis.long}
      />
    );
  }
}

export default graphQlHoc(DetailpageClip, query);
