import React, { Component } from 'react';

class GlossaryIcon extends Component {
  render() {
    return (
      <svg x="0px" y="0px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
        <path d="M20.5,43.9V50l-3.8-1.5L12.9,50V0h28.8c0.9,0,1.5,0.6,1.5,1.5v40.9c0,0.9-0.6,1.5-1.5,1.5H20.5z M18.9,6.1
c-0.9,0-1.5,0.6-1.5,1.5s0.6,1.5,1.5,1.5h18.2c0.9,0,1.5-0.6,1.5-1.5S38,6.1,37.1,6.1H18.9z M18.9,12.1c-0.9,0-1.5,0.6-1.5,1.5
c0,0.9,0.6,1.5,1.5,1.5h18.2c0.9,0,1.5-0.6,1.5-1.5c0-0.9-0.6-1.5-1.5-1.5H18.9z M8.3,0h3v43.9h-3c-0.9,0-1.5-0.6-1.5-1.5V1.5
C6.8,0.6,7.4,0,8.3,0z"
        />
      </svg>
    );
  }
}

export default GlossaryIcon;
