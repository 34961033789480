import React, { Component } from 'react';
import cx from 'classnames';
import logging from '../../lib/logging';

class FilterTag extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'FilterTag', false);
    this.logDebug('init:', props);
  }

  toggle = () => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.id, !this.props.value);
    }
  };

  render() {
    return (
      <div
        className={cx('b-filter-tag', this.props.className, {
          'b-filter-tag--active': this.props.value,
        })}
        onClick={this.toggle}
      >
        {this.props.title}
      </div>
    );
  }
}

export default FilterTag;
