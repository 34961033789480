/* eslint-disable no-console */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import logging from '../../lib/logging';
import query from '../../modules/routes/favorites/query.gql';
import MUTATION_TOGGLE_FAVORITE from './mutation.gql';

import AudioIcon from '../icons/AudioIcon';
import VideoIcon from '../icons/VideoIcon';
import { IconListItem } from '../iconList/IconList';
import { formatDurationString, versionAvailability } from '../../lib/time';

class FavlistTile extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'FavlistTile');
    this.logDebug('init:', props);
  }

  render() {
    const {
      label,
      title,
      image,
      description,
      item,
    } = this.props;

    return (
      <div className="b-favlist-tile">
        <div className="episode-wrapper">
          <div className="b-favlist-tile__image">
            <div className="b-favlist-tile__image__label">{label}</div>
            <img src={image.sizes.medium} alt="" />

            {item.episode.episode.mediaType
                            && (
                            <div className="b-favlist-tile__image__format-icon">
                              {item.episode.episode.mediaType === 'audio' && <i><AudioIcon /></i>}
                              {item.episode.episode.mediaType === 'audio_video' && <i><VideoIcon /></i>}
                            </div>
                            )}
          </div>

          <div className="b-favlist-tile__info">
            <Mutation mutation={MUTATION_TOGGLE_FAVORITE}>
              {(mutate) => (
                <button type="button" onClick={() => this.onMutate(mutate)} className="close" />
              )}
            </Mutation>

            <span className="b-favlist-tile__info__title">{title}</span>
            <p className="b-favlist-tile__info__description">{description}</p>

            <Link className="link-style" to={item.episode.path}>View episode</Link>
          </div>
        </div>
        <div className="versions-wrapper">
          { item.episode.episode.versions.filter((version) => !version.isExpired).map((version) => (
            <div key={version.pid}>
              <ul className="version">
                <li className="title">{version.label}</li>
                <IconListItem icon="duration">{formatDurationString(version.duration)}</IconListItem>
                <IconListItem icon="calendar">{versionAvailability(version)}</IconListItem>
                <li className="link">
                  <Link className="link-style" to={item.episode.path}>View episode</Link>
                </li>
              </ul>
            </div>
          )) }
        </div>
      </div>
    );
  }

  onMutate = (mutate) => {
    mutate({
      variables: { pid: this.props.item.pid },
      optimisticResponse: {
        __typename: 'Mutation',
        invalidateNotification: true,
      },

      update: (proxy) => {
        const data = proxy.readQuery({ query });

        data.notifications
          .filter((item) => item.brand === this.props.item.episode.pidBrand)
          .forEach((brand) => {
            brand.notifications
              .filter((notification) => notification.pid === this.props.item.pid)
              .forEach((episode) => {
                episode.invalidated = true;
              });
          });

        proxy.writeQuery({ query, data });

        return null;
      },
    }).catch((err) => {
      console.error(err);
    });
  };
}

export default FavlistTile;
