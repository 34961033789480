import React, { Component } from 'react';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './DetailpageBrand.gql';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';
import Clock from '../../../components/clock/Clock';

class brandClock extends Component {
  render() {
    const { title, programClock, path } = this.props.data.brand;

    return (
      <div className="position-relative">
        <div className="container container--indent">
          <div className="gr-12 gr-11@m">
            <div className="b-main-title">
              <div className="title">
                <ExtendedLink to={path}>{title}</ExtendedLink>
              </div>
            </div>
            <h1>
              {title}
              {' '}
              programme clock
            </h1>
          </div>
          <Clock programClock={programClock} />
        </div>
      </div>
    );
  }
}

export default graphQlHoc(brandClock, query);
