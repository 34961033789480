import React, { Component } from 'react';

class PreviewIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M38.8 35.8l-6-6a9.6 9.6 0 1 0-2.4 2.5l5.5 5.5a21.6 21.6 0 0 1-11 3.2C17.1 41 9 35.8.4 25.5a2 2 0 0 1 0-2.3c8.6-10 16.8-15 24.6-15 7.8 0 16 5 24.6 15.1.6.7.6 1.6 0 2.3a62.7 62.7 0 0 1-10.8 10.2zm-13.9-5.1c-3.5 0-6.4-2.8-6.4-6.4s2.8-6.4 6.4-6.4c3.5 0 6.4 2.8 6.4 6.4s-2.9 6.4-6.4 6.4z" /></svg>
    );
  }
}

export default PreviewIcon;
