import React, { Component } from 'react';
import Detailpage from './Detailpage';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './DetailpageEpisode.gql';

function suppressUnwantedVersions(versions) {
  const wanted = versions.filter((v) => v.start);
  if (wanted.length > 0) { // don't let the episode have no versions
    return wanted;
  }
  if (versions.length === 1) {
    return versions;
  }
  // corner case - we may prefer some versions over others
  console.log('V', versions);
  return versions;
}

class DetailpageEpisode extends Component {
  render() {
    const { data } = this.props;

    const { episode, loginStatus } = data;

    const { versions, ...eprest } = episode.episode;

    return (
      <Detailpage
        {...this.props}
        entity="episode"
        loginStatus={loginStatus}
        {...episode}
        {...eprest}
        versions={suppressUnwantedVersions(versions)}
        title={(episode.brand) ? episode.brand.title : episode.title}
        subTitle={(episode.brand) ? episode.title : null}
        clips={episode.clips}
        intro={episode.synopsisBrand}
        description={episode.episode.synopsis?.long}
        path={episode.pathBrand}
      />
    );
  }
}

export default graphQlHoc(DetailpageEpisode, query);
