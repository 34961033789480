import React, { Component } from 'react';

class PlayerIcon extends Component {
  render() {
    return (
      <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g fill="#000000">
          <path d="M27,3 C28.1045695,3 29,3.62680135 29,4.4 L29,22.6 C29,23.3731986 28.1045695,24 27,24 L5,24 C3.8954305,24 3,23.3731986 3,22.6 L3,4.4 C3,3.62680135 3.8954305,3 5,3 L27,3 Z M12.7622902,8.10877766 L12.7622902,18.633358 L21.6421356,13.3710678 L12.7622902,8.10877766 Z M8.72727273,26 L11.2727273,26 C11.6743889,26 12,26.3256111 12,26.7272727 L12,29.2727273 C12,29.6743889 11.6743889,30 11.2727273,30 L8.72727273,30 C8.32561109,30 8,29.6743889 8,29.2727273 L8,26.7272727 C8,26.3256111 8.32561109,26 8.72727273,26 Z M14.7272727,26 L17.2727273,26 C17.6743889,26 18,26.3256111 18,26.7272727 L18,29.2727273 C18,29.6743889 17.6743889,30 17.2727273,30 L14.7272727,30 C14.3256111,30 14,29.6743889 14,29.2727273 L14,26.7272727 C14,26.3256111 14.3256111,26 14.7272727,26 Z M20.7272727,26 L23.2727273,26 C23.6743889,26 24,26.3256111 24,26.7272727 L24,29.2727273 C24,29.6743889 23.6743889,30 23.2727273,30 L20.7272727,30 C20.3256111,30 20,29.6743889 20,29.2727273 L20,26.7272727 C20,26.3256111 20.3256111,26 20.7272727,26 Z" />
        </g>
      </svg>
    );
  }
}

export default PlayerIcon;
