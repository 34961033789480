import React, { Component } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar/dist/entry.nostyle';

class DetailDayCalendar extends Component {
  render() {
    return (
      <Calendar
        prevLabel={<i />}
        nextLabel={<i />}
        prev2Label={null}
        next2Label={null}
        minDetail="month"
        maxDetail="month"
        locale="en-EN"
        showNeighboringMonth={false}
        navigationLabel={({ date, label }) => {
          const isActiveMonth = (date.getFullYear()
          === this.props.value.getFullYear() && date.getMonth() === this.props.value.getMonth());

          return (
            <span>
              {isActiveMonth
                                && <span>{moment.utc(this.props.value).format('dddd, D ')}</span>}
              <span>{label}</span>
            </span>
          );
        }}
        {...this.props}
      />
    );
  }
}

export default DetailDayCalendar;
