import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import logging from '../../lib/logging';
import AlertIcon from '../icons/AlertIcon';
import invalidateAlert from './invalidateAlert.gql';

class Alert extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Alert');
    this.logDebug('init:', props);
  }

  render() {
    const {
      icon,
      label,
      headline,
      text,
      type,
      cta,
    } = this.props;

    return (
      <div className={`b-alert is-type-${type} container`}>
        <div className="container">
          <div className="gr-10">

            {icon
                            && <i className="b-alert__icon"><AlertIcon /></i>}

            {label
                            && <span className="b-alert__label">{this.props.labelTitle}</span>}

            {headline
                            && <span className="b-alert__headline h2-style">{this.props.headlineTitle}</span>}

            {text
                        && <p className="text">{text}</p>}

            {cta
                            && <a className="b-alert__cta link-style" href={this.props.link}>{this.props.ctaTitle}</a>}
            <Mutation mutation={invalidateAlert}>
              {(mutate) => (
                <i
                  className="close"
                  onClick={() => {
                    this.props.onClose(this.props.id);

                    mutate({
                      variables: { id: this.props.id },
                      optimisticResponse: {
                        __typename: 'Mutation',
                        invalidateAlert: {
                          __typename: 'Alert',
                          id: this.props.id,
                          invalid: true,
                        },
                      },
                    });
                  }}
                />
              )}
            </Mutation>

          </div>
        </div>
      </div>
    );
  }
}

export default Alert;
