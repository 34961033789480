import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import Teaser from '../teaser/Teaser';
import logging from '../../lib/logging';
import LoginStatusWrapped from './query.gql';

class TeaserList extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'TeaserList');
    this.logDebug('init:', props);
  }

  render() {
    const {
      id = null,
      isHalfGrid,
      isThirdGrid,
      isQuarterGrid,
      hasFavlist,
      hasAvailableHint,
      hasIcon,
      moreLink,
      hasBrandTitle,
      hasDescription,
      hasGenre,
      isLocked,
      teaserlistLinkUrl,
      items,
      title,
      teaserlistLink,
    } = this.props;

    return (

      <div className="b-teaser-list">
        { id && <a id={id} />}
        <div className="b-teaser-list__head">
          {title && <h2 className="h2-style">{title}</h2>}
          {(teaserlistLinkUrl) && <Link to={teaserlistLinkUrl} className="link-style">{teaserlistLink}</Link>}
        </div>
        <div className="row">
          { (!items || items.length === 0)
            ? (
              <div className="gr-12 empty-text">Here is currently no data available.</div>
            ) : (
              <ul className="gr-12 no-gutter b-teaser-list__list-wrapper">
                {(items).map((item, index) => (
                  <li
                    key={index}
                    className={cx('gr-12', {
                      'gr-6@m': isHalfGrid,
                      'gr-6@m gr-4@l': isThirdGrid,
                      'gr-6@m gr-3@l': isQuarterGrid,
                    })}
                  >
                    <Teaser
                      {...item}
                      largeImage={isHalfGrid}
                      hasFavlist={this.props.data.loginStatus && item.pid ? hasFavlist : false}
                      hasAvailableHint={item.pid ? hasAvailableHint : false}
                      hasBrandTitle={hasBrandTitle}
                      hasDescription={hasDescription}
                      hasIcon={hasIcon}
                      hasGenre={item.pid ? hasGenre : false}
                      isLocked={item.pid ? isLocked : false}
                    />
                  </li>
                ))}
              </ul>
            )}
        </div>
        {moreLink && <Link to={moreLink.url} className="link-style hide@m b-teaser-list__bottom-link">{moreLink.text}</Link>}
      </div>
    );
  }
}

export default graphql(LoginStatusWrapped)(TeaserList);
