import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import cx from 'classnames';
import FavlistIcon from '../icons/FavlistIcon';
import query from './notifications.gql';

function FavLink({ data: { notifications } }) {
  return (
    <Link
      className={cx('b-main-header__meta-elements__favlist', {
        'b-main-header__meta-elements__favlist--marked': (notifications || []).length > 0,
      })}
      to="/favourites"
    >
      <FavlistIcon />
    </Link>
  );
}

export default memo(graphql(query)(FavLink));
