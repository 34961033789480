import React, { Component } from 'react';

class TwitterIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M3.1 0h43.8C48.6 0 50 1.4 50 3.1v43.8c0 1.7-1.4 3.1-3.1 3.1H3.1A3.1 3.1 0 0 1 0 46.9V3.1C0 1.4 1.4 0 3.1 0zM45 14c-1.4.6-2.8 1-4.4 1.2A7.5 7.5 0 0 0 44 11c-1.5.9-3.1 1.5-4.9 1.9a7.6 7.6 0 0 0-13 6.9c-6.3-.3-12-3.4-15.7-8a7 7 0 0 0-1 3.8c0 2.7 1.3 5 3.4 6.4-1.3 0-2.4-.4-3.5-1v.1c0 3.7 2.6 6.8 6.1 7.5-.6.2-1.3.3-2 .3l-1.4-.1c1 3 3.8 5.2 7.1 5.3-2.6 2-5.9 3.3-9.5 3.3l-1.8-.1c3.4 2.2 7.4 3.4 11.7 3.4A21.5 21.5 0 0 0 41.2 19v-1c1.5-1.1 2.8-2.4 3.8-4z" fill="#00acee" /></svg>
    );
  }
}

export default TwitterIcon;
