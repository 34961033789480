import React, { Component } from 'react';
import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './rundownBrands.gql';
import NotLoggedIn from '../../../components/notLoggedIn/notLoggedIn';

class RundownBrands extends Component {
  render() {
    const { loginStatus, rundownBrands, page } = this.props.data;
    const documentMeta = page && page.documentMeta;

    if (!loginStatus) {
      return (<NotLoggedIn text="BBC Rundowns show the running order of our programmes with a breakdown of each segment details and times." showPartnerButton />);
    }

    return (
      <div>
        <div className="container container--indent">
          <div className="gr-12">
            <div className="gr-12 gr-11@m page__intro">
              <h1>{documentMeta && documentMeta.pageTitle}</h1>
              { documentMeta && (
              <p><strong dangerouslySetInnerHTML={{ __html: documentMeta.description }} /></p>
              )}
              { documentMeta && (
              <div dangerouslySetInnerHTML={{ __html: documentMeta.synopsis }} />
              )}
            </div>
            <div className="gr-12">
              {rundownBrands.length === 0
                                && <p>There are currently no brands with rundowns available.</p>}

              { rundownBrands.map((rundownBrand) => {
                if (rundownBrand.items.length > 0) {
                  return (
                    <TeaserList
                      key={rundownBrand.slug}
                      teaserlistLink={`View ${rundownBrand.label} page`}
                      teaserlistLinkUrl={rundownBrand.slug}
                      title={`${rundownBrand.label} Rundowns`}
                      isThirdGrid
                      hasFavlist={false}
                      hasAvailableHint={false}
                      hasIcon={false}
                      hasBrandTitle={false}
                      hasDescription={false}
                      hasGenre={false}
                      isLocked={false}
                      items={rundownBrand.items}
                    />
                  );
                }
                return null;
              }) }
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(RundownBrands, query);
