import React, { Component, Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';
import logging from '../../lib/logging';

import Article from '../routes/article';
import SchedulesChannels from '../routes/schedules/Channels';
import SchedulesDetailDay from '../routes/schedules/DetailDay';
import SchedulesDetailWeek from '../routes/schedules/DetailWeek';
import SchedulesDetailSeason from '../routes/schedules/DetailSeason';
import Contact from '../routes/contact/Contact';
import EpisodeRundown from '../routes/detailpage/episodeRundown';
import Homepage from '../routes/homepage/Homepage';
import Favlist from '../routes/favorites';
import DetailpageEpisode from '../routes/detailpage/DetailpageEpisode';
import DetailpageClip from '../routes/detailpage/DetailpageClip';
import DetailpageBrand from '../routes/detailpage/DetailpageBrand';
import BrandEpisodes from '../routes/detailpage/brandEpisodes';
import EpisodeClips from '../routes/detailpage/episodeClips';
import BrandClips from '../routes/detailpage/brandClips';
import BrandClock from '../routes/detailpage/brandClock';
import Latest from '../routes/latest';
import Search from '../routes/search';
import SpecialPartnerContent from '../routes/specialPartnerContent';
import LatestLanguage from '../routes/latestLanguage';
import Evergreen from '../routes/evergreen';
import EvergreenGenre from '../routes/evergreen/genrePage';
import CatalogueRegion from '../routes/catalogue/catalogueRegion';
import CatalogueLanguage from '../routes/catalogue/catalogueLanguage';
import CatalogueGenre from '../routes/catalogue/catalogueGenre';
import RundownBrands from '../routes/rundowns/RundownBrands';

import User from '../routes/user/user';
import Basket from '../routes/basket/Basket';

import SiteContext from '../contexts/SiteContext';

import Header from '../../components/header/Header';
import Error from '../../components/error/Error';

import AlertsArea from '../../components/alertsArea/alertsArea';

import rootQuery from './app.gql';
import AuthorizationModal from '../../graphql/BasketMutations/AuthorizationModal';
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner';
import CookieBanner from '../../components/cookieBanner/CookieBanner';
import EpisodeClock from '../routes/detailpage/episodeClock';

/* eslint react/no-children-prop: 0 */

export class App extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'App');
    this.logDebug('init');
  }

  render() {
    const { combinedQueries } = this.props;

    if (combinedQueries.loading) {
      return (<LoadingSpinner />);
    } if (combinedQueries.error) {
      return (<Error>Error: unable to load data, please try again later. If this error persist please contact us.</Error>);
    }

    return (
      <SiteContext.Provider value={combinedQueries.specialEvent.mode}>
        <BrowserRouter>
          <>
            { /* Header contains some global code for tracking and page title) */ }
            <Route
              exact
              path="/"
              children={({ match, history, location }) => (
                <Header
                  location={location}
                  apolloClient={this.props.apolloClient}
                  history={history}
                  isHome={!!match}
                  navigation={combinedQueries.navigation}
                  loginStatus={combinedQueries.loginStatus}
                  regions={combinedQueries.regions}
                  languages={combinedQueries.allLanguages}
                  genres={combinedQueries.allGenres}
                  cartFetch={combinedQueries.cartFetch}
                  onRequestLogin={this.onRequestLogin}
                />
              )}
            />
            <main>
              <Switch>
                <Route exact path="/" component={Homepage} />
                <Route exact path="/article/:id" component={Article} />
                <Route exact path="/glossary" render={(props) => <Article defaultRouteParams={{ id: 'glossary' }} {...props} />} />
                <Route exact path="/faq" render={(props) => <Article defaultRouteParams={{ id: 'faq' }} {...props} />} />

                <Route exact path="/contact/:inquiryType/:pid?" component={Contact} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/rundowns" component={RundownBrands} />

                <Route exact path="/schedules" component={SchedulesChannels} />
                <Route path="/schedules/:channel/day" component={SchedulesDetailDay} />
                <Route path="/schedules/:channel/week" component={SchedulesDetailWeek} />
                <Route path="/schedules/:channel/season/:season" component={SchedulesDetailSeason} />

                <Route exact path="/clip/:pid" component={DetailpageClip} />
                <Route exact path="/episode/:pid" component={DetailpageEpisode} />
                <Route exact path="/brand/:pid" component={DetailpageBrand} />
                <Route exact path="/brand/:pid/clock" component={BrandClock} />
                <Route exact path="/brand/:pid/episodes/:page" render={(props) => <BrandEpisodes defaultRouteParams={{ sort: 'availability' }} {...props} />} />
                <Route exact path="/brand/:pid/episodes-latest/:page" render={(props) => <BrandEpisodes defaultRouteParams={{ sort: 'release_date' }} {...props} />} />
                <Route exact path="/brand/:pid/episodes-az/:page" render={(props) => <BrandEpisodes defaultRouteParams={{ sort: 'title' }} {...props} />} />
                <Route exact path="/episode/:pid/rundown" component={EpisodeRundown} />
                <Route exact path="/episode/:pid/clock" component={EpisodeClock} />
                <Route exact path="/episode/:pid/clips/:page" component={EpisodeClips} />
                <Route exact path="/brand/:pid/clips/:page" component={BrandClips} />

                <Route exact path="/special-partner-content" component={SpecialPartnerContent} />
                <Route exact path="/latest/:page" component={Latest} />
                <Route exact path="/latest-language/:page" component={LatestLanguage} />
                <Route exact path="/continuous-content" component={Evergreen} />
                <Route exact path="/continuous-content/:genre/:page" component={EvergreenGenre} />

                <Route exact path="/user" component={User} />
                <Route exact path="/favourites" component={Favlist} />
                <Route exact path="/basket" component={Basket} />

                <Route exact path="/catalogue/region/:region" component={CatalogueRegion} />
                <Route exact path="/catalogue/region/:region/genre/:genre/:page" component={CatalogueGenre} />
                <Route exact path="/catalogue/region/:region/:language/:page" component={CatalogueLanguage} />

                <Route exact path="/search/" component={Search} />

                { combinedQueries.navigation.map(({ id }) => (
                  <Route key={id} exact path={`/${id}`} render={(props) => <Article defaultRouteParams={{ id }} {...props} />} />
                ))}

                <Route render={() => <Error>Error: route not found</Error>} />
              </Switch>
            </main>
            <CookieBanner />
            <AlertsArea items={this.props.combinedQueries.alerts} />
            <AuthorizationModal
              client={this.props.apolloClient}
              {...this.props.combinedQueries.modalAuthorization}
            />
          </>
        </BrowserRouter>
      </SiteContext.Provider>
    );
  }
}

export default graphql(rootQuery, {
  name: 'combinedQueries',
  fetchPolicy: 'cache-and-network',
})(App);
