import React from 'react';
import Select from 'react-select';
import cx from 'classnames';

export default function ({
  field, required, form: {
    touched, errors, setFieldValue, setFieldTouched,
  }, label, ...props
}) {
  const hasError = touched[field.name] && errors[field.name];
  return (
    <div className={cx('b-selectbox-wrapper form-element', {
      'form-element--error': hasError,
    })}
    >
      <span className="b-selectbox-wrapper__label">
        {label}
        {required ? '*' : ''}
      </span>
      <Select
        className="b-selectbox"
        options={props.options}
        {...field}
        {...props}
        value={props.options ? props.options.find((option) => option.value === field.value) : ''}
        onChange={(option) => {
          setFieldTouched(field.name, true);
          setFieldValue(field.name, option ? option.value : '');
        }}
        onBlur={() => setFieldTouched(field.name, true)}
      />
      {hasError && <span className="form-element__error-message">{errors[field.name]}</span>}
    </div>
  );
}
