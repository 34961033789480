import React from 'react';

function BottomTeaser({ pid, titleTruncated, image }) {
  return (
    <div key={pid || titleTruncated} className="b-carousel__thumbnails__item">
      <span className="b-carousel__thumbnails__item__title hide show@l hide show@m">{titleTruncated}</span>
      <div className="b-carousel__thumbnails__item__image">
        {image && <img src={image.sizes.medium} />}
      </div>
    </div>
  );
}

export default BottomTeaser;
