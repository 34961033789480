import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import logging from '../../../lib/logging';

class DateSelect extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'DateSelect', false);
    this.logDebug('init:', props);
  }

  render() {
    const {
      id, type, min, value, onChange,
    } = this.props;

    return (
      type === 'start' ? (
        <DatePicker
          id={id}
          selected={value}
          onChange={(date) => onChange(date)}
          selectsStart
          dateFormat="dd MMM yyyy"
          placeholderText="Select start date"
          popperPlacement="top-start"
        />
      ) : (
        <DatePicker
          id={id}
          selected={value}
          onChange={(date) => onChange(date)}
          selectsEnd
          minDate={min}
          dateFormat="dd MMM yyyy"
          placeholderText="Select end date"
          popperPlacement="top-start"
        />
      )
    );
  }
}

export default DateSelect;
