import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import { Route, generatePath } from 'react-router-dom';
import logging from '../../../lib/logging';
import 'moment-timezone';
import DetailHeader from './DetailHeader';
import graphQlHoc from '../../../components/graphQlHoc';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';
import { getTzAdjustedDateTimeString, groupBroadcastsByTime } from '../../../lib/time';
import queryDetailWeek from './queryDetailWeek.gql';
import queryWeekBroadcasts from './queryWeekBroadcasts.gql';
import Error from '../../../components/error/Error';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import TimezoneSelectbox from '../../../components/form-elements/timezoneSelectbox/TimezoneSelectbox';
import { IconList, IconListItem } from '../../../components/iconList/IconList';

class SchedulesDetailWeek extends Component {
  state = {
    timezone: null,
  };

  constructor(props) {
    super(props);
    logging.applyLogging(this, 'SchedulesDetailWeek', false);
  }

  renderTop = (match, channelData, lastModified) => {
    const { timezone } = this.state;

    const activeDate = moment(match.params.date);
    const activeDateEnd = moment(activeDate).add(6, 'days');
    const activeDateString = (activeDate.month() === activeDateEnd.month()) ? activeDate.format('D') : activeDate.format('D MMMM');
    const preDate = moment(activeDate).subtract(7, 'days');
    const nextDate = moment(activeDate).add(7, 'days');

    const prevLink = (moment(channelData.min).isSameOrBefore(preDate)) ? generatePath(match.path, {
      channel: match.params.channel,
      date: preDate.format('YYYY-MM-DD'),
    }) : null;

    const nextLink = (moment(channelData.max).isSameOrAfter(nextDate)) ? generatePath(match.path, {
      channel: match.params.channel,
      date: nextDate.format('YYYY-MM-DD'),
    }) : null;

    return (
      <>
        <div className="b-schedule-page__week-changer">
          <ExtendedLink to={prevLink} className="react-calendar__navigation__arrow react-calendar__navigation__prev-button"><i /></ExtendedLink>
          <span>
            <strong>
              {activeDateString}
              {' '}
              -
              {' '}
              {activeDateEnd.format('D MMMM')}
              ,
            </strong>
            {' '}
            {activeDateEnd.format('YYYY')}
          </span>
          <ExtendedLink to={nextLink} className="react-calendar__navigation__arrow react-calendar__navigation__next-button"><i /></ExtendedLink>
        </div>

        <ul className="calendar-anchor-list" id="calendar-anchor-list">
          <li><a href="#anchor-monday">Monday</a></li>
          <li><a href="#anchor-tuesday">Tuesday</a></li>
          <li><a href="#anchor-wednesday">Wednesday</a></li>
          <li><a href="#anchor-thursday">Thursday</a></li>
          <li><a href="#anchor-friday">Friday</a></li>
          <li><a href="#anchor-saturday">Saturday</a></li>
          <li><a href="#anchor-sunday">Sunday</a></li>
        </ul>

        <div className="gr-12 no-gutter">
          <div className="gr-12 prefix-3@l gr-6@l">
            <TimezoneSelectbox
              className="m-large"
              value={this.state.timezone}
              onChange={(option) => { this.setState({ timezone: option }); }}
            />

            {lastModified
                          && (
                          <div className="availability-wrapper">
                            <IconList isHorizontal>
                              <IconListItem icon="calendar">
                                Last updated in
                                {' '}
                                {getTzAdjustedDateTimeString(
                                  lastModified,
                                  timezone ? timezone.zone : null,
                                )}
                              </IconListItem>
                            </IconList>
                          </div>
                          )}
          </div>
        </div>
      </>
    );
  };

  renderBody = (weekBroadcasts, match, channelData) => {
    const { timezone } = this.state;

    if (!weekBroadcasts.days || weekBroadcasts.days.length === 0) {
      return (
        <div className="row">
          {this.renderTop(match, channelData)}
          <Error>No data available.</Error>
        </div>
      );
    }

    return (
      <div className="row">

        {this.renderTop(match, channelData, weekBroadcasts.lastModified)}

        {weekBroadcasts.days.map((item) => {
          const broadcastGroups = groupBroadcastsByTime(item.broadcasts, item.date);

          return (
            <section key={item.date} className="b-schedule-page--week-view__days" id={`anchor-${item.day.toLowerCase()}`}>

              <h2><strong>{item.day}</strong></h2>

              {broadcastGroups.map((group, key) => (
                <Fragment key={key}>
                  <p>
                    {group.start.format('HH:mm')}
                    {' '}
                    -
                    {' '}
                    {group.end.format('HH:mm')}
                  </p>
                  <div className="row">
                    <ul className="no-gutter b-teaser-list__list-wrapper">
                      {group.broadcasts.map((broadcast, key2) => (
                        <li key={key2} className="gr-12 gr-6@m gr-4@l">
                          <ExtendedLink to={broadcast.url} className="b-teaser">
                            <div className="b-teaser-info">
                              <span className="b-teaser-info__available-hint">
                                {moment.utc(broadcast.start).format('HH:mm ')}
                                {broadcast.offAir && (`- ${moment.utc(broadcast.end).format('HH:mm ')}`)}
                                GMT
                                {!broadcast.offAir && timezone
                                                                    && (` | ${moment.utc(broadcast.start).tz(timezone.zone).format('HH:mm z')}`)}
                              </span>
                              <span className="b-teaser-info__title">{broadcast.brand}</span>
                              <span className="b-teaser-info__sub-title">{broadcast.title}</span>
                            </div>
                          </ExtendedLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="gr-12 back-to-top">
                    <a href="#calendar-anchor-list">Back to top</a>
                  </div>
                </Fragment>
              ))}

            </section>
          );
        })}

      </div>
    );
  };

  render() {
    if (!this.props.data.channelData) {
      return (<Error>This channel does not exist.</Error>);
    }

    return (
      <div className="b-schedule-page b-schedule-page--week-view">
        <div className="container container--indent">
          <div className="gr-12">

            <DetailHeader channelData={this.props.data.channelData} route={this.props.route} />

            <Route
              exact
              path="/schedules/:channel/week/:date"
              history={this.props.route.history}
              render={(props) => (
                <Query
                  fetchPolicy="cache-and-network"
                  query={queryWeekBroadcasts}
                  variables={{
                    channel: props.match.params.channel,
                    date: props.match.params.date,
                  }}
                >
                  {({ loading, error, data }) => {
                    this.logDebug('render DetailWeek Body ', props.match.params.date, data, loading);

                    if (error) {
                      return (<Error>Something went wrong...</Error>);
                    } if (loading) {
                      return (<LoadingSpinner />);
                    }

                    return this.renderBody(
                      data.weekBroadcasts,
                      props.match,
                      this.props.data.channelData,
                    );
                  }}
                </Query>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(SchedulesDetailWeek, queryDetailWeek);
