import fragment from './Fragments/headerImage.gql';

export default {
  defaults: {
    activeBasketDownload: {
      __typename: 'BasketDownload',
      completed: false,
      active: false,
      items: null,
    },
    modalAuthorization: {
      __typename: 'ModalAuthorization',
      open: false,
      variant: null,
      episodePid: null,
    },
    headerImage: {
      __typename: 'HeaderImage',
      title: '',
      text: '',
      sizes: null,
    },
  },
  resolvers: {

    Query: {
      getHeaderImage: (_, variables, { cache, getCacheKey }) => {
        const id = getCacheKey({ __typename: 'HeaderImage' });

        const data = cache.readFragment({ fragment, id });

        if (!data) {
          return {
            __typename: 'HeaderImage',
            text: null,
            title: null,
            sizes: {
              __typename: 'Sizes',
              small: null,
              medium: null,
              large: null,
              super: null,
            },
          };
        }

        return data;
      },

      getDocumentMeta: (_, variables, { cache, getCacheKey }) => {
        const id = getCacheKey({ __typename: 'DocumentMeta' });

        const data = cache.readFragment({ fragment, id });

        if (!data) {
          return {
            __typename: 'DocumentMeta',
            pageTitle: null,
            shortSummary: null,
            description: null,
          };
        }

        return data;
      },
    },
  },
};
