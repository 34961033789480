import React, { Component } from 'react';

class AlertIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M46.5 46.5h-43a3.5 3.5 0 0 1-3-5.3L22 5.2C23 3.5 25.1 3 26.8 4c.5.3.9.7 1.2 1.2l21.5 35.9c1 1.7.5 3.9-1.2 4.9a4 4 0 0 1-1.8.5zm-21.7-5.1c1.5 0 2.7-1.2 2.7-2.7S26.3 36 24.8 36s-2.7 1.2-2.7 2.7 1.2 2.7 2.7 2.7zm0-26.6l-.7.1c-2 .4-3.3 2.3-3 4.3l2.6 14.2c.1.5.5.9 1 .9s1-.4 1-.9l2.6-14.2.1-.7c.1-2-1.6-3.7-3.6-3.7z" /></svg>
    );
  }
}

export default AlertIcon;
