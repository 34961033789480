import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

export const bpS = 320;
export const bpM = 600;

export const maxBpL = 899;
export const minBpL = 900;

export const bpXl = 1024;
export const bpXxl = 1200;
export const bpXxxl = 1800;

export function MaxBreakPointL(props) {
  return (
    <Media query={`screen and (max-width: ${maxBpL}px)`}>
      {props.children}
    </Media>
  );
}

export function MinBreakPointL(props) {
  return (
    <Media query={`screen and (min-width: ${minBpL}px)`}>
      {props.children}
    </Media>
  );
}

/* export const MediaMatchDesktopGt = () => window.matchMedia(
  `screen and (min-width: ${BreakPointDesktop}px)`,
).matches; */

const childProps = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MaxBreakPointL.propTypes = childProps;
MinBreakPointL.propTypes = childProps;
