/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import React from 'react';
import { Temporal } from 'temporal-polyfill';

const codeMapping = {
  A: 'Sound',
  C: 'Commercial',
  H: 'Radiophonic Workshop',
  I: 'Local Radio Idents',
  K: 'Commercial old',
  L: 'Live',
  M: 'Library music',
  N: 'Private tapes',
  P: 'Music video',
  R: 'Studio recording',
  2: '2nd or further use of "R" code',
  T: 'Soundtrack tape',
  U: 'Foreign tapes',
  X: 'Specially composed music',
  S: 'Signature Tune',
  D: 'Dubbed',
  B: 'Background music',
  V: 'Visual Music',
};

function getCodeNames(code) {
  return Array.from(code).map((c) => codeMapping[c]).join(' / ');
}

function formatDuration(dur) {
  if (dur.total('minute') < 1) {
    return `${dur.round('second').seconds} s`;
  }
  return dur.round({ smallestUnit: 'minute', largestUnit: 'hour' }).toString().replace('H', ':').slice(2, 7);
}

function idMap(ids) {
  if (!ids) {
    return {};
  }
  return Object.fromEntries(ids.map((id) => ([id.attribution.type, id.value])));
}

function formatParty(party) {
  const name = party?.name?.map((n) => n.text).join(' / ');
  const id = party?.id?.map((m) => `${m.attribution.type}: ${m.value}`);
  if (name && id) {
    return `${name} (${id})`;
  }
  if (id) {
    if (id === ': ') {
      return '';
    }
    return id;
  }
  return name;
}

export function getPerformanceFields(performance) {
  if (performance) {
    const roles = performance.role ?? [];
    const titles = [...new Set(roles.map((r) => r.title))];
    return {
      artist: titles.map((t) => roles
        .filter((r) => r.title === t).map((r) => formatParty(r.party)).join(' / ')),
      perfIds: idMap(performance.id),
    };
  }
  return { artist: [], perfIds: {} };
}

export function getReleaseFields(release) {
  if (release) {
    const roles = release.role ?? [];
    return {
      label: roles.find((r) => r.title === 'Label')?.party?.name?.map((n) => n.text),
      releaseTitle: release?.title?.find((t) => t)?.text,
      relIds: idMap(release.id),
    };
  }
  return { label: [], relIds: {}, releaseTitle: undefined };
}

function flattenTitle(title, type) {
  if (Array.isArray(title)) {
    return title?.find((t) => t.attribution.type === type)?.text;
  }
  if (title?.text) {
    return title.text;
  }
  return undefined;
}

export function getWorkFields(work) {
  if (!work) {
    return {
      composers: undefined,
      publishers: undefined,
      title: undefined,
      work_ids: {},
    };
  }
  const roles = work.role ?? [];
  const composers = roles
    .filter((r) => r.title === 'Composer')
    .map((r) => formatParty(r.party)).join(' / ');
  const publishers = roles
    .filter((r) => r.title === 'Publisher')
    .map((r) => formatParty(r.party)).join(' / ');
  const workTitle = flattenTitle(work.title, 'mainTitle');
  const workIds = idMap(work.id);
  return {
    composers, publishers, workTitle, workIds,
  };
}

export function defaultView(item) {
  const { performance, release, work } = item.music_data;
  const { label, releaseTitle, relIds } = getReleaseFields(release);
  const {
    composers, publishers, workTitle, workIds,
  } = getWorkFields(work);
  const { artist, perfIds } = getPerformanceFields(performance);
  return {
    Title: workTitle ?? releaseTitle,
    Artist: artist.join(' / '),
    Composer: composers,
    Publisher: publishers,
    Label: label.join(' / '),
    'PRS Tunecode': workIds?.PRS ?? '',
    'ISWC Code': workIds?.ISWC ?? '',
    'ISRC Code': perfIds?.ISRC ?? '',
    'Catalogue Number': relIds?.CATALOGUE_NUMBER ?? '',
    'Music Origin': getCodeNames(item.licence_code_usage) ?? '',
    'Cue Usage': 'Background',
    Duration: formatDuration(Temporal.Duration.from(item.played_duration ?? '')),
  };
}

export function toRows(item) {
  const view = item?.map((it) => defaultView(it));
  if (view?.length > 0) {
    return {
      header: Object.keys(view[0]),
      rows: view.map((row) => Object.values(row)),
    };
  }
  return {
    header: [
      'Title', 'Artist', 'Composer', 'Publisher', 'Label',
      'PRS Tunecode', 'ISWC Code', 'ISRC Code', 'Catalogue Number',
      'Music Origin', 'Cue Usage', 'Duration',
    ],
    rows: [],
  };
}

export function getcuesheet(versions) {
  const version = versions?.find((v) => v.cuesheet || v.amrText);
  return version?.cuesheet || version?.amrText;
}

export function cueSheetAvailability(start) {
  if (start) {
    const availableInHours = 48;

    const now = new Date();
    const starting = new Date(start);

    const expectedBefore = starting.getTime() + 3600000 * availableInHours;
    if (expectedBefore > now) {
      const waitFor = Math.ceil((expectedBefore - now) / 3600000);
      return `This programme may carry copyrighted music. A music cue sheet is expected to be available within ${Math.ceil(waitFor)} hours.`;
    }

    if (starting > now) {
      return 'This programme may carry copyrighted music. A music cue sheet will be available here shortly after transmission.';
    }
  }
  return 'Music cue sheet not currently available. Please contact us.';
}

export function Cuesheet({ cuesheet, amrEnabled, start }) {
  if (Array.isArray(cuesheet)) {
    const { header, rows } = toRows(cuesheet[0].item);
    return (
      <div className="section">
        <div className="amr-content">
          <table>
            <tr>
              {header.map((h) => <th key={h}>{h}</th>)}
            </tr>
            {rows.map((r, index) => <tr key={index}>{r.map((c) => <td>{c ?? ''}</td>)}</tr>)}
          </table>
        </div>
      </div>
    );
  }
  if (cuesheet) {
    return (
      <div className="section">
        <div className="amr-content" dangerouslySetInnerHTML={{ __html: cuesheet }} />
      </div>
    );
  }
  if (amrEnabled) {
    return (
      <div className="description">
        {cueSheetAvailability(start)}
      </div>
    );
  }
  return '';
}
