import React, { Component } from 'react';

import logging from '../../../lib/logging';
import TeaserList from '../../../components/teaserList/TeaserList';
import TeaserSquareList from '../../../components/teaserSquareList/TeaserSquareList';
import Carousel from '../../../components/carousel/Carousel';

import graphQlHoc from '../../../components/graphQlHoc';
import query from './query.gql';
import Error from '../../../components/error/Error';

import SiteContext from '../../contexts/SiteContext';

class Homepage extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Homepage');
    this.logDebug('init:', props);
  }

  render() {
    if (!this.props.data.homepage) {
      return (
        <Error>Server Error: Cannot load iSite data, please try again later.</Error>
      );
    }

    const {
      carousel, languageLatest, latest, specials, evergreens, specialPartnerContent, genres,
    } = this.props.data.homepage;

    return (
      <div className="b-homepage">

        <div className="container container--indent">

          <div className="gr-12">

            {carousel && <Carousel items={carousel.items} />}

            {this.context !== 'SPECIALEVENT' && specialPartnerContent && specialPartnerContent.items.length > 0
                            && (
                            <TeaserList
                              teaserlistLink="See all recommended for you"
                              teaserlistLinkUrl="/special-partner-content"
                              title={specialPartnerContent.label}
                              isThirdGrid
                              hasFavlist
                              hasAvailableHint
                              hasBrandTitle
                              hasDescription
                              hasGenre
                              items={specialPartnerContent.items}
                            />
                            )}

            {this.context !== 'SPECIALEVENT' && languageLatest && languageLatest.items.length > 0
                            && (
                            <TeaserList
                              teaserlistLink={`See all ${languageLatest.label.charAt(0).toLowerCase()}${languageLatest.label.slice(1)}`}
                              teaserlistLinkUrl="/latest-language/1"
                              title={languageLatest.label}
                              isThirdGrid
                              hasFavlist
                              hasAvailableHint
                              hasBrandTitle
                              hasDescription
                              hasGenre
                              items={languageLatest.items}
                            />
                            )}

            {this.context !== 'SPECIALEVENT' && (
            <TeaserList
              teaserlistLink="See all latest content"
              teaserlistLinkUrl="/latest/1"
              title={latest.label}
              isThirdGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle
              hasDescription
              hasGenre
              items={latest.items}
            />
            )}

            {specials && (
            <TeaserList
              title={specials.label}
              isHalfGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle
              hasDescription
              hasGenre
              items={specials.items}
            />
            )}

            <TeaserSquareList
              title="Browse by genre"
              items={genres}
              TeaserSquareList="View our full archive"
            />

            {this.context !== 'SPECIALEVENT' && (
            <TeaserList
              teaserlistLink="See all continuous content"
              teaserlistLinkUrl="/continuous-content"
              title={evergreens.label}
              isThirdGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle
              hasDescription
              hasGenre
              items={evergreens.items}
            />
            )}

          </div>
        </div>

      </div>
    );
  }
}
Homepage.contextType = SiteContext;

export default graphQlHoc(Homepage, query);
