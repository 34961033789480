import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import AudioIcon from '../icons/AudioIcon';
import LockedIcon from '../icons/LockedIcon';
import VideoIcon from '../icons/VideoIcon';
import FavlistIcon from '../icons/FavlistIcon';
import BasketIcon from '../icons/BasketIcon';
import { availability, formatDurationString, getDownloadableVersions } from '../../lib/time';
import ToggleFavoriteMutation from '../../graphql/ToggleFavoriteMutation';
import BasketMutations from '../../graphql/BasketMutations';
import ExtendedLink from '../ExtendedLink/ExtendedLink';

class Teaser extends Component {
  render() {
    const {
      pid,
      hasFavlist,
      hasBrandTitle = false,
      hasDescription,
      hasGenre,
      hasAvailableHint,
      hasIcon = true,
      isLocked,
      path,
      episode,
      clip,
      subtitle,
      image,
      brand,
      titleTruncated,
      synopsisTruncated,
      genre,
      language,
      largeImage,
    } = this.props;

    const availabilityData = (episode && episode.availability)
      || (clip && clip.availability) || null;
    const availableInfo = hasAvailableHint && availability(availabilityData);
    const lang = language && language.rightToLeft ? language.languageCode : null;
    const downloadableVersions = getDownloadableVersions(episode);
    const showBasket = episode && episode.hasAccess !== null && downloadableVersions.length > 0;

    return (
      <ExtendedLink
        to={path}
        lang={lang}
        className={cx('b-teaser', {
          'languages--rtl': language && language.rightToLeft,
        })}
      >
        <div className="b-teaser-image">
          {availabilityData && availabilityData.isNew && <div className="b-teaser-image__label">New</div>}

          {image
                        && (
                        <picture>
                          <img
                            srcSet={`${largeImage ? image.sizes.small : image.sizes.mini}, ${largeImage ? image.sizes.medium : image.sizes.small} 2x`}
                            src={image.sizes.medium}
                          />
                        </picture>
                        )}

          {(hasIcon && episode && episode.mediaType)
                        && (
                        <div className={cx('b-teaser-image__format-icon', {
                          'b-teaser-image__format-icon--evergreen': episode && episode.availability && episode.availability.evergreen,
                        })}
                        >
                          {episode.mediaType === 'audio' && <i><AudioIcon /></i>}
                          {episode.mediaType === 'audio_video' && <i><VideoIcon /></i>}
                        </div>
                        )}

          {(hasIcon && brand && brand.mediaType)
                        && (
                        <div className={cx('b-teaser-image__format-icon')}>
                          {brand.mediaType === 'audio' && <i><AudioIcon /></i>}
                          {brand.mediaType === 'audio_video' && <i><VideoIcon /></i>}
                        </div>
                        )}

          {showBasket && (
          <BasketMutations
            pid={pid}
            versionPids={downloadableVersions.map((version) => version.pid)}
            hasAccess={episode.hasAccess}
          >
            {({ isInCart, toggle, isDisabled }) => (
              <div
                className={cx('b-teaser-image__format-icon is-basket', {
                  'is-active': isInCart,
                  'is-waiting': isDisabled,
                })}
                data-tip={(!isInCart) ? 'Add to basket' : 'Remove from basket'}
                onClick={(event) => {
                  event.preventDefault();
                  toggle();
                }}
              >
                <BasketIcon />
                <ReactTooltip place="left" effect="solid" />
              </div>
            )}
          </BasketMutations>
          )}

          {hasFavlist && brand
                        && (
                        <ToggleFavoriteMutation>
                          {(mutate) => (
                            <div
                              className={cx('b-teaser-image__favlist', {
                                'b-teaser-image__favlist--active': brand.isFavorite,
                                'has-offset': showBasket,
                              })}
                              data-tip={brand.isFavorite ? 'Remove from Favourites' : 'Add to Favourites'}
                              onClick={this.onClick(mutate, brand)}
                            >
                              <FavlistIcon />
                              <ReactTooltip place="left" effect="solid" />
                            </div>
                          )}
                        </ToggleFavoriteMutation>
                        )}
        </div>

        <div className={cx('b-teaser-info', { 'has-genre': hasGenre && genre })}>
          {(availableInfo && episode)
                        && <span className="b-teaser-info__available-hint">{availableInfo}</span>}

          {(subtitle)
                        && <span className="b-teaser-info__available-hint">{subtitle}</span>}

          {hasBrandTitle && brand
                    && <span className="b-teaser-info__brand-title">{brand.title}</span>}

          <span className={cx('b-teaser-info__title', { 'has-brand-title': brand && hasBrandTitle })}>
            {(isLocked) && <i><LockedIcon /></i>}
            <span>{titleTruncated}</span>
          </span>

          {(hasDescription)
                        && <p className="b-teaser-info__description">{synopsisTruncated}</p>}

          {episode
            && episode.availability
            && episode.availability.duration
            && episode.versions
            && episode.versions.length === 1
                        && (
                        <span className="b-teaser-info__available-hint" style={{ paddingBottom: '5px' }}>
                          Duration:
                          {formatDurationString(episode.availability.duration)}
                        </span>
                        )}

          {(hasGenre && genre)
                        && <span className="genres">{genre.name}</span>}
        </div>
      </ExtendedLink>
    );
  }

  onClick = (mutate, brand) => (ev) => {
    ev.preventDefault();
    mutate(brand);
  };
}

export default Teaser;
