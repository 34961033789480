import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import LoginLink from '../login/LoginLink';

class NotLoggedIn extends Component {
  render() {
    return (
      <div className="b-not-logged-in">
        <div className="container container--indent">
          <div className="gr-12">
            <div className="gr-12 gr-9@l">
              <h1>Log in required</h1>
              <p>
                { this.props.text ? this.props.text : (
                  <>
                    You are currently not logged in.
                    <br />
                    Please login to see this page.
                  </>
                )}
              </p>
              <LoginLink />
              { this.props.showPartnerButton && (
              <Link style={{ marginLeft: '10px' }} className="b-button" to="/contact/become-bbc-media-partner/">Become a Partner</Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotLoggedIn;
