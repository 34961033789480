import React, { Component } from 'react';
import range from 'lodash/range';
import cx from 'classnames';
import logging from '../../lib/logging';
import ExtendedLink from '../ExtendedLink/ExtendedLink';

class Pagination extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Pagination');
    this.logDebug('init:', props);
  }

  renderRange(fr, end, currentPage, route) {
    return (
      range(fr, end).map((page) => {
        const linkProp = route ? { to: `${route}/${page + 1}` } : { onClick: () => this.props.onItemClick(page + 1) };

        return (
          <li
            key={page}
            className={cx('b-pagination__item', {
              'b-pagination__item--active': currentPage === (page + 1),
            })}
          >
            <ExtendedLink className="text-link-style" {...linkProp}>
              <span>{page + 1}</span>
            </ExtendedLink>
          </li>
        );
      })
    );
  }

  render() {
    const {
      currentPage, totalPages, route, onItemClick, maxPageListLength = 10,
    } = this.props;

    if (totalPages === 1) {
      return null;
    }

    const firstProp = route ? { to: `${route}/1` } : { onClick: () => onItemClick(1) };
    const prevProp = route ? { to: `${route}/${(currentPage) - 1}` } : { onClick: () => onItemClick(currentPage - 1) };
    const nextProp = route ? { to: `${route}/${(currentPage) + 1}` } : { onClick: () => onItemClick(currentPage + 1) };
    const lastProp = route ? { to: `${route}/${totalPages}` } : { onClick: () => onItemClick(totalPages) };

    const first = Math.max(0, currentPage - Math.ceil(maxPageListLength / 2));
    const last = Math.min(totalPages, currentPage + Math.ceil(maxPageListLength / 2));

    return (
      <ul className="b-pagination">
        {currentPage > 2 && totalPages > maxPageListLength && (
        <li className="b-pagination__item b-pagination__item--prev">
          <ExtendedLink className="text-link-style" {...firstProp}>
            <i />
            <span className="hide show@m">First</span>
          </ExtendedLink>
        </li>
        )}
        {currentPage > 1 && (
        <li className="b-pagination__item b-pagination__item--prev">
          <ExtendedLink className="text-link-style" {...prevProp}>
            <i />
            <span className="hide show@m">Previous</span>
          </ExtendedLink>
        </li>
        )}

        {this.renderRange(first, last, currentPage, route)}

        {currentPage < totalPages && (
        <li className="b-pagination__item b-pagination__item--next">
          <ExtendedLink className="text-link-style" {...nextProp}>
            <span className="hide show@m">Next</span>
            <i />
          </ExtendedLink>
        </li>
        )}
        {currentPage < (totalPages - 1) && totalPages > maxPageListLength && (
        <li className="b-pagination__item b-pagination__item--next">
          <ExtendedLink className="text-link-style" {...lastProp}>
            <span className="hide show@m">Last</span>
            <i />
          </ExtendedLink>
        </li>
        )}
      </ul>
    );
  }
}

export default Pagination;
