import React from 'react';
import { Link } from 'react-router-dom';
import BasketIcon from '../icons/BasketIcon';

function BasketLink({ cartFetch }) {
  if (!cartFetch) {
    return null;
  }

  return (
    <Link className="b-main-header__meta-elements__basket" to="/basket">
      <BasketIcon />
      {cartFetch.items.length > 0
                && <span className="b-main-header__meta-elements__basket__counter">{cartFetch.items.length}</span>}
    </Link>
  );
}

export default BasketLink;
