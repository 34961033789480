import React, { Component, Fragment } from 'react';
import { Query, withApollo } from 'react-apollo';

import { Link } from 'react-router-dom';
import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './query.gql';
import SEARCH from './search.gql';
import { setPageTitle } from '../../../js/helper';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import Error from '../../../components/error/Error';
import Pagination from '../../../components/pagination/Pagination';
import { exportToPdf } from '../../../lib/pdfExport';

class Search extends Component {
  state = {
    pageBrands: 1,
    pageTrailers: 1,
    pageEpisodes: 1,
  };

  componentDidMount() {
    this.scrollTarget = null;
    this.scrollTimeout = null;
    setPageTitle('Search results', this.props.client);
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  /* eslint-disable-next-line no-unused-vars */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevSearch = prevProps.route.location.search;
    const newSearch = this.props.route.location.search;

    if (prevSearch !== newSearch) {
      this.setState({
        pageBrands: 1,
        pageTrailers: 1,
        pageEpisodes: 1,
      });
    }
  }

  getSearchParameters() {
    const search = new URLSearchParams(window.document.location.search.slice(1));

    if (search.has('term')) {
      const searchParameter = { ...this.state };

      search.forEach((value, key) => {
        searchParameter[key] = value;
      });

      return JSON.stringify(searchParameter);
    }

    return '';
  }

  render() {
    const vars = {
      q: this.getSearchParameters(),
    };

    return (
      <div className="b-search-results">
        <div className="container container--indent">
          <div className="gr-12">
            <h1 id="headline">Search results</h1>

            <Query query={SEARCH} variables={vars} fetchPolicy="cache-and-network">
              {({ data, error, loading }) => {
                if (error) {
                  return (
                    <Error>
                      Error: an error occurred on the server, you can report this fault via
                      <Link to="/contact" className="text-link-style"> contact us</Link>
                      .
                    </Error>
                  );
                }

                if (loading) {
                  return <LoadingSpinner />;
                }

                if (!data.search) {
                  return null;
                }

                const brands = data.search.brands.items;
                const trailers = data.search.trailers.items;
                const episodes = data.search.episodes.items;
                const { download, availabilityStart, availabilityEnd } = JSON.parse(vars.q);

                if (download) {
                  exportToPdf(episodes, { availabilityStart, availabilityEnd });
                }

                this.scrollTimeout = setTimeout(() => {
                  if (this.scrollTarget) {
                    location.href = this.scrollTarget;
                    this.scrollTarget = null;
                  }
                }, 100);

                if (!data.search
                  || (brands.length === 0 && trailers.length === 0 && episodes.length === 0)) {
                  return (
                    <section className="section"><p>There are currently no results matching your search.</p></section>
                  );
                }

                return (
                  <>
                    {brands.length > 0 && (
                    <>
                      <section className="section" id="brands">
                        <TeaserList
                          title="Programme brands"
                          isThirdGrid
                          hasLabel
                          hasFavlist
                          hasAvailableHint
                          hasBrandTitle={false}
                          hasDescription
                          hasGenre
                          items={brands}
                        />
                      </section>
                      <Pagination
                        onItemClick={(pageBrands) => {
                          location.href = '#headline';
                          this.scrollTarget = '#brands';
                          this.setState({ pageBrands });
                        }}
                        totalPages={data.search.brands.totalPages}
                        currentPage={this.state.pageBrands}
                      />
                    </>
                    )}
                    {trailers.length > 0 && (
                    <>
                      <section className="section" id="trailers">
                        <TeaserList
                          title="Trailers"
                          isThirdGrid
                          hasLabel
                          hasFavlist
                          hasAvailableHint
                          hasBrandTitle
                          hasDescription
                          hasGenre
                          items={trailers}
                        />
                      </section>
                      <Pagination
                        onItemClick={(pageTrailers) => {
                          location.href = '#headline';
                          this.scrollTarget = '#trailers';
                          this.setState({ pageTrailers });
                        }}
                        totalPages={data.search.trailers.totalPages}
                        currentPage={this.state.pageTrailers}
                      />
                    </>
                    )}
                    {episodes.length > 0 && (
                    <>
                      <section className="section" id="episodes">
                        <TeaserList
                          title="Episodes"
                          isThirdGrid
                          hasLabel
                          hasFavlist
                          hasAvailableHint
                          hasBrandTitle
                          hasDescription
                          hasGenre
                          items={episodes}
                        />
                      </section>
                      <Pagination
                        onItemClick={(pageEpisodes) => {
                          location.href = '#headline';
                          this.scrollTarget = '#episodes';
                          this.setState({ pageEpisodes });
                        }}
                        totalPages={data.search.episodes.totalPages}
                        currentPage={this.state.pageEpisodes}
                      />
                    </>
                    )}
                  </>
                );
              }}
            </Query>
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(Search), query);
