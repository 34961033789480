import React, { Component } from 'react';

class LoginLink extends Component {
  render() {
    return (
      <a
        className={this.props.className || 'b-button'}
        href={`/api/oauth-redirect?redirect_uri=${encodeURIComponent(document.location.href)}`}
      >
        Login
      </a>
    );
  }
}

export default LoginLink;
