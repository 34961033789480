/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import logging from '../../lib/logging';
import Button from '../button/Button';

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'CookieBanner');
    this.logDebug('init:', props);

    let accepted = false;

    try {
      accepted = JSON.parse(localStorage.getItem('_bbc_cookie_accepted')) || false;
    } catch (error) {
      this.logError(error);
    }

    this.state = {
      accepted,
    };
  }

  onClose = () => {
    this.setState({ accepted: true });

    try {
      localStorage.setItem('_bbc_cookie_accepted', 'true');
    } catch (error) {
      this.logError(error);
    }
  };

  render() {
    const { accepted } = this.state;

    if (accepted) {
      return null;
    }

    return (
      <div className="b-cookie-banner">
        <div className="text">
          <div className="h2-style">Privacy and Cookies Policy</div>
          <p>
            This website uses cookies to help us understand how visitors are using the website
            and how it is performing, so we can make it better. For more information about how
            the BBC process personal data and uses cookies please see the BBC Privacy and
            Cookies Policy here:
            <a className="link" href="https://www.bbc.co.uk/usingthebbc/privacy/" target="_blank">
              https://www.bbc.co.uk/usingthebbc/privacy/
            </a>
          </p>
        </div>
        <Button buttonName="OK" onClick={this.onClose} />
      </div>
    );
  }
}

export default CookieBanner;
