import React, { Component } from 'react';

class AudioIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <path d="M2.3 17.6c1.3 0 2.3 1 2.3 2.3v6.5c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3v-6.5c0-1.3 1-2.3 2.3-2.3zm9.1-6.8c1.3 0 2.3 1 2.3 2.3v23.6c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V13.1c0-1.3 1-2.3 2.3-2.3zm9.1-9c1.3 0 2.3 1 2.3 2.3v41.8c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V4.1c0-1.3 1-2.3 2.3-2.3zm9 6.5c1.3 0 2.3 1 2.3 2.3v24.5c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3V10.6c.1-1.2 1.1-2.3 2.3-2.3zm9.1 6.9c1.3 0 2.3 1 2.3 2.3v14.4c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V17.5c.1-1.3 1.1-2.3 2.3-2.3zm9.1 4.2c1.3 0 2.3 1 2.3 2.3v4.1c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3v-4.1c.1-1.3 1.1-2.3 2.3-2.3z" fill="#fff" />
      </svg>

    );
  }
}

export default AudioIcon;
