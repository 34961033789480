import React, { Component } from 'react';
import cx from 'classnames';
import logging from '../../../lib/logging';
import Teaser from '../../teaser/Teaser';

class WidgetCarousel extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetCarousel');
    this.logDebug('init:', props);
  }

  render() {
    const { items, displayType } = this.props;

    return (
      <section className="widget-section gr-12">
        <div className="b-teaser-list">
          <div className="row">
            <ul className="gr-12 no-gutter b-teaser-list__list-wrapper">
              {items.map((item, idxItem) => (
                <li
                  key={idxItem}
                  className={cx('gr-12 gr-6@m', {
                    'gr-4@l': displayType === 3,
                    'gr-3@l': displayType === 4,
                  })}
                >
                  <Teaser
                    {...item}
                    path={item.path ? item.path : '/'}
                    hasFavlist
                    hasAvailableHint
                    hasBrandTitle
                    hasDescription
                    hasGenre
                    isLocked={false}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default WidgetCarousel;
