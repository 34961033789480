import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import SupportBanner from '../supportBanner/SupportBanner';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import Footer from '../footer/Footer';
import Error from '../error/Error';

export function withPendingState(WrappedComponent) {
  return class extends Component {
    render() {
      const { loading, error } = this.props;

      const ready = !loading && !error;

      let errorResult = null;

      if (error) {
        if (error.graphQLErrors.find((err) => err.message === '404')) {
          errorResult = 404;
        } else {
          errorResult = true;
        }
      }

      return (
        <>
          {!ready && !errorResult && <LoadingSpinner />}
          {errorResult === 404 && <Error>Error: 404 not found</Error>}
          {errorResult === true && (
          <Error>
            Error: an error occurred on the server, you can report this fault via
            <Link to="/contact" className="text-link-style"> contact us</Link>
            .
          </Error>
          )}
          {ready && !errorResult && <WrappedComponent {...this.props} />}
          {(ready || errorResult)
                        && (
                        <>
                          <SupportBanner />
                          <Footer />
                        </>
                        )}
        </>
      );
    }
  };
}

function WithQuery(WrappedComponent, query, processSearch, pagination) {
  const InnerComponent = withPendingState(WrappedComponent);

  return class WithQueryComponent extends Component {
    render() {
      const {
        history, location, match, defaultRouteParams, ...rest
      } = this.props;
      const route = { history, location, match };
      const { params: variables } = match;

      let paginationVariables = {};

      if (variables.page) {
        variables.page = parseInt(variables.page, 10);
      }

      if (pagination) {
        paginationVariables = {
          page: variables.page,
        };
      }

      return (
        <Query
          fetchPolicy="cache-and-network"
          query={query}
          variables={{
            ...variables,
            ...defaultRouteParams,
            ...paginationVariables,
          }}
        >
          {({
            data, error, loading, fetchMore,
          }) => (
            <InnerComponent
              error={error}
              fetchMore={fetchMore}
              loading={loading}
              data={data}
              route={route}
              {...rest}
            />
          )}
        </Query>

      );
    }
  };
}

export default WithQuery;
