import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

class ExtendedLink extends Component {
  render() {
    const {
      to, className, children, onClick, lang,
    } = this.props;

    if (onClick) {
      return (<button type="button" className={className} onClick={onClick} lang={lang}>{children}</button>);
    }

    if (!to) {
      return (<div className={cx('is-disabled', className)} lang={lang}>{children}</div>);
    }

    if (to.indexOf('http') === 0) {
      return (<a href={to} className={className} lang={lang}>{children}</a>);
    }

    return (<Link to={to} className={className} lang={lang}>{children}</Link>);
  }
}

export default ExtendedLink;
