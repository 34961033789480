import React, { Component } from 'react';

class DurationIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M26.9 23.1h7.7a1.9 1.9 0 1 1 0 3.8H25c-1.1 0-1.9-.9-1.9-1.9V9.6a1.9 1.9 0 1 1 3.8 0v13.5zM25 50a25 25 0 1 1 0-50 25 25 0 0 1 0 50zm0-5.8a19.2 19.2 0 1 0 0-38.4 19.2 19.2 0 0 0 0 38.4z" /></svg>
    );
  }
}

export default DurationIcon;
