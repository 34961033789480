import React, { Component, Fragment } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import {
  formatDurationString,
  versionAvailability,
  getDownloadableVersions,
  formatDateString,
} from '../../lib/time';
import { IconListItem, IconList } from '../iconList/IconList';
import BasketMutations from '../../graphql/BasketMutations';
import AudioIcon from '../icons/AudioIcon';
import VideoIcon from '../icons/VideoIcon';
import VideoModal from '../../modules/routes/detailpage/VideoModal';
import PlayIcon from '../icons/PlayIcon';
import { visitSocialNetwork } from '../../js/helper';
import ToggleFavoriteMutation from '../../graphql/ToggleFavoriteMutation';

export default class VersionSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageMaxHeight: '0px',
      modalVersionPid: null,
      modalSamlToken: null,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setImageMaxHeight.bind(this));
    this.setImageMaxHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setImageMaxHeight);
  }

  setImageMaxHeight() {
    const slidePreviewImages = document.querySelectorAll('.slick-slider .slick-slide img');
    let currentImageMaxHeight = 0;

    slidePreviewImages.forEach((sliderPreviewImage) => {
      const boundingBox = sliderPreviewImage.getBoundingClientRect();

      if (boundingBox.height > currentImageMaxHeight) {
        currentImageMaxHeight = boundingBox.height;
      }
    });

    this.setState({ imageMaxHeight: `${currentImageMaxHeight}px` });
  }

  formatStartDate(unformattedDate) {
    return String(unformattedDate).substring(0, 10);
  }

  openPlayer(version, overridePid, index) {
    this.setState({
      modalVersionPid: (overridePid && index === 0) ? overridePid : version.pid,
      modalSamlToken: (overridePid && index === 0) ? null : version.samlToken,
    });
  }

  renderActions() {
    const {
      loginStatus, entity, path, brand, pid, hasRundown, programClock, rundownEpisodes, episodes
      = [], trailers, brandEpisodes, type,
    } = this.props;

    let downloadableEpisode;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < episodes.length; i++) {
      if (episodes[i].episode.hasAccess
        !== null && getDownloadableVersions(episodes[i].episode).length > 0) {
        downloadableEpisode = episodes[i];
        break;
      }
    }

    return (
      <ul>
        { entity === 'brand' && loginStatus
                    && (
                    <ToggleFavoriteMutation>
                      {(follow) => {
                        const title = brand.brand.isFavorite ? 'Unfollow' : 'Follow';
                        return <IconListItem onClick={() => follow(brand.brand)} icon="favlist">{title}</IconListItem>;
                      }}
                    </ToggleFavoriteMutation>
                    )}

        { downloadableEpisode
                    && (
                    <BasketMutations
                      pid={downloadableEpisode.pid}
                      versionPids={
                        getDownloadableVersions(downloadableEpisode.episode).map(
                          (version) => version.pid,
                        )
                      }
                      hasAccess={downloadableEpisode.episode.hasAccess}
                    >
                      {({ isInCart, toggle, isDisabled }) => (
                        <IconListItem
                          className={cx({ 'is-waiting': isDisabled })}
                          onClick={toggle}
                          icon="basket"
                        >
                          {(isInCart) ? 'Remove latest episode from basket' : 'Add latest episode to basket'}
                        </IconListItem>
                      )}
                    </BasketMutations>
                    )}

        { hasRundown
                    && <IconListItem icon="preview" to={`/episode/${pid}/rundown`}>View rundown</IconListItem>}

        { programClock
                    && <IconListItem icon="duration" to={(entity === 'brand') ? `${path}/clock` : `/episode/${pid}/clock`}>View programme clock</IconListItem>}

        { rundownEpisodes && rundownEpisodes.length > 0 && (
        <IconListItem icon="preview" to={rundownEpisodes[0].path}>View latest rundown</IconListItem>
        )}

        { episodes && episodes.length > 0 && (
        <IconListItem icon="grid" to={`${path}/episodes/1`}>See all episodes</IconListItem>
        )}

        { brandEpisodes && brandEpisodes.length > 0 && (
        <IconListItem icon="grid" to={`${path}/episodes/1`}>
          See all episodes of
          <em>{brand.title}</em>
        </IconListItem>
        )}

        { type === 'clip' && brand?.title && (
        <IconListItem icon="grid" to={`${path}/clips/1`}>
          See all clips of
          <em>{brand.title}</em>
        </IconListItem>
        )}

        { trailers && trailers.length > 0 && (
        <IconListItem
          icon="player"
          onClick={(event) => {
            event.preventDefault();
            document.querySelector('#trailers').scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          See all trailers for this episode
        </IconListItem>
        )}
      </ul>
    );
  }

  formattedReleaseDate(episode, version) {
    if (version?.start) {
      if (version.isUpcoming) {
        return (
          <>
            <strong>Release Date:&nbsp;</strong>
            {formatDateString(version.start)}
          </>
        );
      }
      return (
        <>
          <strong>Released:&nbsp;</strong>
          {formatDateString(version.start)}
        </>
      );
    }
    if (episode?.releaseDate) {
      return (
        <>
          <strong>Released:&nbsp;</strong>
          {formatDateString(episode.releaseDate)}
        </>
      );
    }
    return '';
  }

  render() {
    const {
      versions,
      image,
      episode,
      series,
      entity,
      pid,
      clip,
      title,
      subTitle,
      videoPid,
      noPlayer,
      onVersionChange,
    } = this.props;
    const { imageMaxHeight, modalVersionPid, modalSamlToken } = this.state;
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      draggable: false,
      afterChange: (index) => onVersionChange(versions[index]),
    };
    const availabilityData = (
      (episode && episode.availability) || (clip && clip.availability) || null
    );

    if (versions.length === 0) {
      versions.push({ isUpcoming: true });
    }

    return (
      <div
        className="b-version-selector"
        style={{ '--sliderImageMaxHeight': `${imageMaxHeight}` }}
      >
        <VideoModal
          isOpen={(modalVersionPid !== null)}
          onClose={() => this.setState({ modalVersionPid: null, modalSamlToken: null })}
          headline={title || null}
          description={subTitle || null}
          cover={image.sizes.medium || null}
          versionPid={modalVersionPid}
          samlToken={modalSamlToken}
          preview={!!episode}
        />

        <Slider {...settings}>
          {versions.map((version, index) => {
            const hasPlayer = (
              !noPlayer && (
                (clip && version.pid) || (episode && !version.isExpired && !version.isUpcoming)
              )
            );

            return (
              <div key={index} className="b-version-selector__container">
                <div
                  className={cx('b-version-selector__container__img', 'gr-8@l', { 'has-video': hasPlayer })}
                  onClick={hasPlayer ? () => { this.openPlayer(version, videoPid, index); } : null}
                >
                  <img
                    src={image.sizes.large}
                    alt="Teaser Image"
                    onLoad={this.setImageMaxHeight.bind(this)}
                  />
                  { hasPlayer && <i title="Open player" className="player-icon"><PlayIcon /></i>}
                  {(episode && episode.mediaType)
                                        && (
                                        <div className={cx('b-version-selector__container__img__format-icon', {
                                          'b-version-selector__container__img__format-icon is-evergreen': availabilityData && availabilityData.evergreen,
                                        })}
                                        >
                                          {episode.mediaType === 'audio' && <i><AudioIcon /></i>}
                                          {episode.mediaType === 'audio_video' && <i><VideoIcon /></i>}
                                        </div>
                                        )}
                </div>
                <div className="b-version-selector__container__info_list">
                  <ul className={cx(
                    'b-version-selector__container__info_list__container',
                    cx('b-version-selector__container__info_list__underline'),
                  )}
                  >
                    <li>
                      <span className="b-version-selector__container__info_list__headline">
                        {version.label}
                      </span>
                    </li>
                    <li>
                      <span className="b-version-selector__container__info_list__start-date">
                        {this.formattedReleaseDate(episode, version)}
                      </span>
                    </li>
                    {episode && (
                      <IconListItem icon="calendar">
                        {versionAvailability(version, availabilityData
                          && availabilityData.evergreen)}
                      </IconListItem>
                    )}
                    {version.duration && <IconListItem icon="duration">{formatDurationString(version.duration)}</IconListItem>}
                    {series && (
                    <IconListItem icon="playlist">
                      {series.index || 'Part'}
                      {' '}
                      of
                      {' '}
                      {series.length}
                    </IconListItem>
                    )}
                    {entity === 'episode' && version.isDownloadable
                                            && (
                                            <div className={cx('b-version-selector__container__basket-actions')}>
                                              <BasketMutations
                                                pid={pid}
                                                versionPid={version.pid}
                                                hasAccess={episode.hasAccess}
                                              >
                                                {({ isInCart, toggle, isDisabled }) => (
                                                  <IconListItem
                                                    className={cx({ 'is-waiting': isDisabled })}
                                                    onClick={toggle}
                                                    icon="basket"
                                                  >
                                                    {(isInCart) ? 'Remove from basket' : 'Add to basket'}
                                                  </IconListItem>
                                                )}
                                              </BasketMutations>
                                            </div>
                                            )}
                  </ul>
                  <div className="b-detailpage__content__link_container">
                    {this.renderActions()}
                    <div className={cx('b-detailpage__content__link_container__social-network-links')}>
                      <IconList>
                        <IconListItem onClick={() => visitSocialNetwork('twitter')} icon="twitter" />
                        <IconListItem onClick={() => visitSocialNetwork('facebook')} icon="facebook" />
                        <IconListItem onClick={() => visitSocialNetwork('instagram')} icon="instagram" />
                      </IconList>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
