import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../lib/logging';

class AuthorBadge extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'AuthorBadge');
    this.logDebug('init:', props);
  }

  render() {
    return (
      <Link className="b-author-badge" to={this.props.path}>
        <img src={this.props.image.sizes.mini} alt="Author" className="b-author-badge__image" />
        <div className="b-author-badge__data">
          <span>{ this.props.label || 'Presented by' }</span>
          <span className="b-author-badge__name">{this.props.name}</span>
        </div>
      </Link>

    );
  }
}

export default AuthorBadge;
