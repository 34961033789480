import moment from 'moment';

const now = moment.utc();
const nowPlus24h = moment.utc().add(24, 'h');
const nowLess24h = moment.utc().subtract(24, 'h');

export function getDownloadableVersions(episode) {
  if (!episode || !episode.versions) {
    return [];
  }

  return episode.versions.filter((version) => version.isDownloadable);
}

function formatDateTime(dateTime) {
  if (dateTime.isBetween(nowLess24h, nowPlus24h)) {
    return `${dateTime.format('DD MMM, HH:mm')} GMT`;
  }

  return dateTime.format('DD MMM YYYY');
}

export function formatDateString(dateTime) {
  return moment(dateTime).format('DD MMM YYYY');
}

export function firstStart(broadcastGroups) {
  if (!broadcastGroups) {
    return undefined;
  }
  const allStarts = broadcastGroups
    .map((group) => group.broadcasts).flat()
    .map((item) => item.start);
  return Math.min(...allStarts);
}

function availabilityTime({
  until, from, actual, duration, ignoreActual,
}) {
  const start = moment.utc(from);
  const end = until && moment.utc(until);

  if (start.isAfter(now)) {
    if (end) {
      return `Available ${formatDateTime(start)} - ${formatDateTime(end)}`;
    }
    return `Available from ${formatDateTime(start)}`;
  }

  if (end && end.isBefore(now)) {
    return `Expired ${formatDateTime(end)}`;
  }

  if (actual || ignoreActual) {
    if (end) {
      return `Available until ${formatDateTime(end)}`;
    }
    return 'Available until further notice';
  }

  const versionDuration = moment.duration(duration);
  const maxPublishingDelay = moment.duration();
  maxPublishingDelay.add(60, 's'); // give the system some fixed time
  maxPublishingDelay.add(versionDuration.asSeconds() * 2.5, 's'); // longer files take longer

  const expectedBy = start.add(maxPublishingDelay);

  if (now.isAfter(expectedBy)) {
    return 'Processing failure - contact us';
  }

  return 'Preparing - available soon';
}

export function availability(config) {
  if (config) {
    if (config.evergreen && moment.utc(config.from).isSameOrBefore(now) && config.actual) {
      return 'Continuous content';
    } if (!config.from) {
      return 'Live only';
    }

    return availabilityTime(config);
  }

  return null;
}

export function versionAvailability({
  start, end, actualStart, duration,
}, evergreen, ignoreActual = false) {
  return availability({
    evergreen,
    from: start,
    until: end,
    actual: actualStart,
    duration,
    ignoreActual,
  });
}

export function groupBroadcastsByTime(broadcasts, date) {
  const groups = [
    {
      name: 'Early',
      start: moment.utc(`${date}T00:00:00`),
      end: moment.utc(`${date}T06:00:00`),
      broadcasts: [],
    }, {
      name: 'Morning',
      start: moment.utc(`${date}T06:00:00`),
      end: moment.utc(`${date}T12:00:00`),
      broadcasts: [],
    }, {
      name: 'Afternoon',
      start: moment.utc(`${date}T12:00:00`),
      end: moment.utc(`${date}T18:00:00`),
      broadcasts: [],
    }, {
      name: 'Evening',
      start: moment.utc(`${date}T18:00:00`),
      end: moment.utc(`${date}T00:00:00`).add(1, 'days'),
      broadcasts: [],
    },
  ];

  const filteredBroadcasts = {
    broadcasts: [],
  };

  broadcasts.forEach((item) => {
    const start = moment.utc(item.start);
    let selectedGroup = filteredBroadcasts;

    if (start.isBefore(groups[1].start)) {
      const end = moment.utc(item.end);
      if (end.isAfter(groups[0].start)) {
        selectedGroup = groups[0];
      }
    } else if (start.isBefore(groups[2].start)) {
      selectedGroup = groups[1];
    } else if (start.isBefore(groups[3].start)) {
      selectedGroup = groups[2];
    } else if (start.isBefore(groups[3].end)) {
      selectedGroup = groups[3];
    }

    selectedGroup.broadcasts.push(item);
  });

  return groups;
}

export function getTzAdjustedDateTimeString(datetime, timezone) {
  const time = moment.utc(datetime);

  if (timezone) {
    const local = time.tz(timezone);
    return `${local.format('DD/MM/YYYY HH:mm')} | ${local.format('Z')} GMT`;
  }

  return `${time.format('DD/MM/YYYY HH:mm')} GMT`;
}

export function formatDurationString(duration) {
  return (
    parseInt(duration.substring(0, 2), 10) === 0 ? duration.substring(3, duration.length) : duration
  );
}
