import React, { Component } from 'react';
import logging from '../../../lib/logging';

class WidgetRichText extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetRichText');
    this.logDebug('init:', props);
  }

  render() {
    const { html } = this.props;

    return (
      <section className="gr-12 gr-10@m gr-11@l widget-section widget-richtext">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    );
  }
}

export default WidgetRichText;
