import React, { Component, Fragment } from 'react';
import logging from '../../../lib/logging';
import Selectbox from '../selectbox/Selectbox';
import timezones from './timezoneData';

class TimezoneSelectbox extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'TimezoneSelectbox', false);
    this.logDebug('init:', props);
  }

  render() {
    return (
      <Selectbox
        placeholder="Select a different timezone…"
        options={timezones}
        valueKey="zone"
        labelKey="label"
        getOptionValue={(option) => (option.zone)}
        getOptionLabel={(option) => (
          <>
            <b>{option.label}</b>
&nbsp;&nbsp;
            <em>{option.zone.replace('_', ' ')}</em>
          </>
        )}
        {...this.props}
      />
    );
  }
}

export default TimezoneSelectbox;
