/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import cx from 'classnames';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import logging from '../../../lib/logging';
import TeaserList from '../../../components/teaserList/TeaserList';
import { IconList, IconListItem } from '../../../components/iconList/IconList';
import AuthorBadge from '../../../components/authorBadge/AuthorBadge';
import TagButtonList from '../../../components/tagButtonList/TagButtonList';
import Selectbox from '../../../components/form-elements/selectbox/Selectbox';
import ToggleFavoriteMutation from '../../../graphql/ToggleFavoriteMutation';
import { setPageTitle, visitSocialNetwork } from '../../../js/helper';
import BasketMutations from '../../../graphql/BasketMutations';
import VersionSelector from '../../../components/versionSelector/VersionSelector';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';
import { firstStart, getDownloadableVersions } from '../../../lib/time';
import VideoModal from './VideoModal';
import PlayIcon from '../../../components/icons/PlayIcon';
import { Cuesheet, getcuesheet } from '../../../lib/cuesheets';

class Detailpage extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Detailpage');
    this.logDebug('init:', props);

    this.state = {
      tabMinHeight: 'auto',
      modalPlayerOpen: false,
      cuesheet: getcuesheet(props?.episode?.versions),
    };

    if (props.broadcastGroups) {
      props.broadcastGroups.sort((a, b) => b.title.localeCompare(a.title));
      this.state.broadcastChannel = props.broadcastGroups[0];
    }
  }

  componentDidMount() {
    const { title, subTitle } = this.props;
    setPageTitle((subTitle) ? `${subTitle} - ${title}` : title, this.props.client);
    this.setTabMinHeight();
  }

  setTabMinHeight() {
    const tabs = document.querySelectorAll('.react-tabs__tab-panel');
    let minHeight = -1;
    tabs.forEach(
      (tab) => tab.getBoundingClientRect().height > (
        minHeight && (minHeight = tab.getBoundingClientRect().height)
      ),
    );

    this.setState({ tabMinHeight: `${Math.ceil(minHeight)}px` });
  }

  onVersionChange = (version) => {
    const cs = getcuesheet([version]);
    if (cs) {
      this.setState({ cuesheet: cs });
    }
  };

  render() {
    const {
      intro, episode, clip, description, genre, title, subTitle, path, entity, image, videoPid,
      language, marketingText, broadcastTimesText, broadcastWindow,
      broadcastWindowLabel, durationText, durationTextLabel,
      presenter, series, trailers, /* radioInternational, */ broadcastGroups, amrEnabled,
    } = this.props;

    broadcastGroups?.sort((a, b) => b.title.localeCompare(a.title));

    const { tabMinHeight, cuesheet } = this.state;

    const availabilityData = (episode?.availability) || (clip?.availability) || null;

    const rtlAttributes = language?.rightToLeft ? {
      lang: language.languageCode,
    } : {};

    const tagButtonList = [];

    if (language) {
      if (genre) {
        tagButtonList.push({ name: genre.name, url: `/catalogue/region/${language.regionSlug}${genre.path}/1` });
      }
      tagButtonList.push({ name: language.name, url: `/catalogue/region/${language.regionSlug}/${language.slug}/1` });
    }

    const haveBroadcastDetails = broadcastGroups
      || broadcastWindow || durationText || broadcastTimesText;

    return (
      <div className={cx('b-detailpage', {
        'b-detailpage--evergreen': availabilityData?.evergreen,
        'b-detailpage--current': availabilityData && !availabilityData.evergreen,
      })}
      >
        <div className="container container--indent">
          <div className="gr-12">
            <div className="row">
              <div className={cx('gr-12 b-main-title', { 'is-parent': entity === 'episode' })}>
                <div className="title">
                  <ExtendedLink to={path}>{title}</ExtendedLink>
                </div>
              </div>

              { subTitle && (
              <div className="gr-12 headline">{subTitle}</div>
              )}

              <div className="gr-12 b-detailpage__content">
                {this.renderPreviewArea()}

                <div className="b-detailpage__content__bottom_grid">
                  <div className={entity === 'brand' ? 'gr-8@l' : 'gr-12@l'}>
                    { entity === 'brand' && (
                    <div
                      className={cx('b-detailpage__content__description__img', { 'has-video': videoPid })}
                      onClick={videoPid ? () => {
                        this.setState({ modalPlayerOpen: true });
                      } : null}
                    >
                      <img src={image.sizes.large} alt="Teaser Image" />
                      { videoPid && <i title="Open player" className="player-icon"><PlayIcon /></i>}
                    </div>
                    )}
                  </div>
                  { entity === 'brand' && (
                  <div className="b-detailpage__content__link_container">
                    {this.renderActions()}
                    <div className={cx('b-detailpage__content__link_container__social-network-links')}>
                      <span>Visit us</span>
                      <IconList>
                        <IconListItem onClick={() => visitSocialNetwork('twitter')} icon="twitter" />
                        <IconListItem onClick={() => visitSocialNetwork('facebook')} icon="facebook" />
                        <IconListItem onClick={() => visitSocialNetwork('instagram')} icon="instagram" />
                      </IconList>
                    </div>
                  </div>
                  )}
                </div>
                <Tabs style={{ '--tabMinHeight': `${tabMinHeight}` }}>
                  <TabList>
                    <Tab>Description</Tab>
                    {haveBroadcastDetails && <Tab>Broadcast details</Tab>}
                    {cuesheet && <Tab>Music reporting</Tab>}
                  </TabList>
                  <TabPanel>
                    <div
                      {...rtlAttributes}
                      className={cx('b-detailpage__content__description', {
                        'languages--rtl': language && language.rightToLeft,
                      })}
                    >
                      {intro && <h1 className="h3-style gr-12 gr-10@l suffix-2@l no-gutter">{intro}</h1>}
                      <p dangerouslySetInnerHTML={{ __html: description ? description.replaceAll('\n', '<br>') : '' }} />
                    </div>

                    {marketingText && <div className="editor-text" dangerouslySetInnerHTML={{ __html: marketingText }} />}
                    {presenter && presenter.name && <AuthorBadge {...presenter} />}
                  </TabPanel>
                  {(broadcastGroups || broadcastWindow || durationText || broadcastTimesText)
                    && (
                    <TabPanel>
                      {broadcastTimesText && (
                      <div className="section">
                        <div className="section-headline">Broadcast times:</div>
                        <div className="editor-text" dangerouslySetInnerHTML={{ __html: broadcastTimesText }} />
                      </div>
                      )}

                      {broadcastWindow
                            && (
                            <div className="section">
                              <div className="section-headline">
                                { broadcastWindowLabel || 'Broadcast window' }
                                :
                              </div>
                              { broadcastWindow }
                            </div>
                            )}

                      {durationText && (
                      <div className="section">
                        <div className="section-headline">
                          { durationTextLabel || 'Duration' }
                          :
                        </div>
                        { durationText }
                      </div>
                      )}

                      {broadcastGroups
                            && (
                            <div className="gr-12 gr-8@l no-gutter section">
                              <div className="section-headline">Broadcast times:</div>
                              {broadcastGroups.length > 1
                                && (
                                <Selectbox
                                  options={broadcastGroups}
                                  value={this.state.broadcastChannel}
                                  valueKey="title"
                                  searchable={false}
                                  clearable={false}
                                  onChange={(option) => {
                                    this.setState({ broadcastChannel: option });
                                  }}
                                  getOptionValue={(option) => (option.title)}
                                  getOptionLabel={(option) => (option.title)}
                                />
                                )}
                              <div>
                                { this.state.broadcastChannel.broadcasts.map((broadcast, i) => (
                                  <div key={broadcast.start + i}>
                                    {`${moment.utc(broadcast.start).format('ddd YYYY-MM-DD HH:mm')}-${moment.utc(broadcast.end).format('HH:mm')} GMT`}
                                  </div>
                                ))}
                              </div>
                            </div>
                            )}
                    </TabPanel>
                    )}
                  {cuesheet && (
                    <TabPanel>
                      <Cuesheet
                        cuesheet={cuesheet}
                        amrEnabled={amrEnabled}
                        start={availabilityData?.from ?? firstStart(broadcastGroups)}
                      />
                    </TabPanel>
                  )}
                </Tabs>
                <TagButtonList items={tagButtonList} />
              </div>
            </div>

            {this.props.episodes?.length > 0
                            && (
                            <TeaserList
                              teaserlistLink="See all episodes"
                              teaserlistLinkUrl={`${this.props.path}/episodes/1`}
                              title={`Recent Episodes from ${this.props.title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              isLocked={false}
                              items={this.props.episodes}
                            />
                            )}

            {this.props.rundownEpisodes?.length > 0
                            && (
                            <TeaserList
                              title={`Rundown: ${this.props.title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint={false}
                              hasIcon={false}
                              hasBrandTitle={false}
                              hasDescription={false}
                              hasGenre={false}
                              isLocked={false}
                              items={this.props.rundownEpisodes}
                            />
                            )}

            {trailers?.length > 0
                            && (
                            <TeaserList
                              id="trailers"
                              title={`Trailers from ${subTitle || title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              items={trailers}
                            />
                            )}

            {series?.items?.length > 0
                            && (
                            <TeaserList
                              title={`More from ${series.title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint
                              hasIcon
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              items={series.items}
                            />
                            )}

            {this.props.clips?.length > 0
                            && (
                            <TeaserList
                              teaserlistLink="See all programme clips"
                              teaserlistLinkUrl={`/${this.props.entity}/${this.props.pid}/clips/1`}
                              title={`Clips from ${this.props.title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              isLocked={false}
                              items={this.props.clips}
                            />
                            )}

            {this.props.brandEpisodes?.length > 0
                            && (
                            <TeaserList
                              teaserlistLink="View programme details"
                              teaserlistLinkUrl={this.props.path}
                              title={`More from ${this.props.title}`}
                              isThirdGrid
                              hasFavlist={false}
                              hasAvailableHint
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              isLocked={false}
                              items={this.props.brandEpisodes}
                            />
                            )}

            {this.props.relatedBrands?.length > 0
                            && (
                            <TeaserList
                              title="More like this"
                              isThirdGrid
                              hasFavlist
                              hasAvailableHint
                              hasBrandTitle={false}
                              hasDescription
                              hasGenre
                              isLocked={false}
                              items={this.props.relatedBrands}
                            />
                            )}

          </div>
        </div>

        { entity === 'brand' && videoPid && (
        <VideoModal
          isOpen={this.state.modalPlayerOpen}
          onClose={() => this.setState({ modalPlayerOpen: false })}
          headline={title || null}
          description={subTitle || null}
          cover={image.sizes.medium || null}
          versionPid={videoPid}
                        // samlToken={this.props.samlToken}
          preview={false}
        />
        )}
      </div>
    );
  }

  renderPreviewArea() {
    const { episode, clip, entity } = this.props;

    if (entity === 'clip' && clip) {
      const version = {
        duration: clip.availability?.duration,
        pid: clip.availability?.versionPid,
        end: clip.availability?.until,
        start: clip.availability?.from,
        actualStart: clip.availability?.from,
      };

      return <VersionSelector {...this.props} versions={[version]} />;
    }

    if (entity === 'episode' && episode) {
      return <VersionSelector {...this.props} onVersionChange={this.onVersionChange} />;
    }

    return null;
  }

  renderActions() {
    const {
      loginStatus, entity, path, brand, pid, hasRundown,
      programClock, rundownEpisodes, episodes = [], trailers, brandEpisodes,
    } = this.props;

    let downloadableEpisode;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < episodes.length; i++) {
      if (episodes[i].episode?.hasAccess
        !== null && getDownloadableVersions(episodes[i].episode).length > 0) {
        downloadableEpisode = episodes[i];
        break;
      }
    }

    return (
      <ul>
        { entity === 'brand' && loginStatus
                    && (
                    <ToggleFavoriteMutation>
                      {(follow) => {
                        const title = brand.brand.isFavorite ? 'Unfollow' : 'Follow';
                        return <IconListItem onClick={() => follow(brand.brand)} icon="favlist">{title}</IconListItem>;
                      }}
                    </ToggleFavoriteMutation>
                    )}

        { downloadableEpisode
                    && (
                    <BasketMutations
                      pid={downloadableEpisode.pid}
                      versionPids={
                        getDownloadableVersions(
                          downloadableEpisode.episode,
                        ).map((version) => version.pid)
                      }
                      hasAccess={downloadableEpisode.episode.hasAccess}
                    >
                      {({ isInCart, toggle, isDisabled }) => (
                        <IconListItem
                          className={cx({ 'is-waiting': isDisabled })}
                          onClick={toggle}
                          icon="basket"
                        >
                          {(isInCart) ? 'Remove latest episode from basket' : 'Add latest episode to basket'}
                        </IconListItem>
                      )}
                    </BasketMutations>
                    )}

        { hasRundown && <IconListItem icon="preview" to={`/episode/${pid}/rundown`}>View rundown</IconListItem>}

        { programClock && <IconListItem icon="duration" to={(entity === 'brand') ? `${path}/clock` : `/episode/${pid}/clock`}>View programme clock</IconListItem>}

        { rundownEpisodes?.length > 0 && <IconListItem icon="preview" to={rundownEpisodes[0].path}>View latest rundown</IconListItem>}

        { episodes?.length > 0 && <IconListItem icon="grid" to={`${path}/episodes/1`}>See all episodes</IconListItem>}

        { brandEpisodes?.length > 0 && <IconListItem icon="grid" to={`${path}/episodes/1`}>See all episodes of the brand</IconListItem>}

        { trailers?.length > 0 && (
        <IconListItem
          icon="player"
          onClick={(event) => {
            event.preventDefault();
            document.querySelector('#trailers').scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          See all trailers
        </IconListItem>
        )}
      </ul>
    );
  }
}

export default withApollo(Detailpage);
