import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './query.gql';
import Pagination from '../../../components/pagination/Pagination';
import { setPageTitle } from '../../../js/helper';

class LanguageLatest extends Component {
  render() {
    const { title, items, totalPages } = this.props.data.languageLatestAll;
    const { page: currentPage } = this.props.route.match.params;

    setPageTitle(title, this.props.client);

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">
            <h1>{title}</h1>
            <TeaserList
              isThirdGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle
              hasDescription
              hasGenre
              items={items}
            />
            <Pagination route="/latest-language" totalPages={totalPages} currentPage={parseInt(currentPage, 10)} />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(LanguageLatest), query, null, true);
