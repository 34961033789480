import React, { Component } from 'react';

class LogoutIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 25">
        <g>
          <path d="M4.3.7v22.6c0 .4-.3.7-.7.7H1.7a.7.7 0 0 1-.7-.7V.7c0-.4.3-.7.7-.7h1.9c.4 0 .7.3.7.7z" />
          <path d="M1.7 0h14.6c.4 0 .7.3.7.7v1.9c0 .4-.3.7-.7.7H1.7a.7.7 0 0 1-.7-.7V.7c0-.4.3-.7.7-.7z" />
          <path d="M1.7 21h14.6c.4 0 .7.3.7.7v1.9c0 .4-.3.7-.7.7H1.7a.7.7 0 0 1-.7-.7v-1.9c0-.4.3-.7.7-.7z" />
          <path d="M13.5 13.7v3.7c0 .3.3.6.6.6l.4-.2 6-5.3c.3-.2.3-.6.1-.9l-.1-.1-6-5.3c-.3-.2-.7-.2-.9.1l-.2.4v3.6H8.8c-.4 0-.7.3-.7.7v2c0 .4.3.7.7.7h4.7z" />
        </g>
      </svg>
    );
  }
}

export default LogoutIcon;
