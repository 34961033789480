import React, { Component, Fragment } from 'react';
import AnimateHeight from 'react-animate-height';
import { Mutation, Query } from 'react-apollo';
import { Link, NavLink } from 'react-router-dom';
import cx from 'classnames';

import logging from '../../lib/logging';
import BBCLogoIcon from '../icons/BBCLogoIcon';
import FavLink from './FavLink';
import SearchIcon from '../icons/SearchIcon';
import SettingsIcon from '../icons/SettingsIcon';
import LogoutIcon from '../icons/LogoutIcon';
import Search from '../search/Search';
import { MinBreakPointL, MaxBreakPointL } from '../../js/media';
import BasketLink from './BasketLink';
import query from './loginStatus.gql';
import headerImageQuery from './client.gql';
import MUTATION_LOGOUT from './logout.gql';
import LoginLink from '../login/LoginLink';
import { resetDocumentMeta } from '../../js/helper';

const optimisticResponse = {
  __typename: 'Mutation',
  logout: {
    __typename: 'LoginResult',
    _optimisticResult: true,
    success: true,
  },
};

class Header extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Header');
    this.logDebug('init:', props);
    this.state = {
      showMenu: false,
      showSubMenu: false,
      showMobileSearch: (this.props.history.location.pathname === '/search/'),
    };

    this.pageTracked = false;
  }

  componentDidMount() {
    this.props.history.listen((location) => {
      this.setState({
        showMenu: false,
        showMobileSearch: (location.pathname === '/search/'),
      });

      window.scrollTo(0, 0);

      resetDocumentMeta(this.props.apolloClient);
      this.pageTracked = false;
    });
  }

  trackPage(pathname, pageTitle) {
    if (window.echo && !this.pageTracked) {
      this.pageTracked = true;

      const parts = pathname.split('/').filter((item) => item);
      if (parts.length === 0) {
        parts.push('home');
      }
      const page = `wspartners.${parts.join('.')}`;
      const parameters = {
        [window.echo_js.Enums.EchoLabelKeys.SECTION]: parts.slice(0, Math.min(parts.length - 1, 3)).join('::'),
        [window.echo_js.Enums.EchoLabelKeys.PAGE_TITLE]: pageTitle,
        // organisation: this.props.loginStatus && this.props.loginStatus.organisation,
      };

      this.logInfo('[trackPage]', page, parameters);
      window.echo.viewEvent(page, parameters);
    }
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  toggleMobileSearch = () => {
    this.setState({
      showMobileSearch: !this.state.showMobileSearch,
    });
  };

  toggleSubMenu = () => {
    this.setState({
      showSubMenu: !this.state.showSubMenu,
    });
  };

  onUpdateLogout = (store, { data: { logout: { _optimisticResult } } }) => {
    store.writeQuery({
      query,
      data: {
        loginStatus: null,
      },
    });

    if (!_optimisticResult) {
      const querySubscription = this.props.apolloClient.watchQuery({
        query,
      }).subscribe(({ loading }) => {
        if (loading === false) {
          querySubscription.unsubscribe();
          this.props.apolloClient.resetStore();
        }
      });
    }
  };

  render() {
    return (
      <Query query={headerImageQuery}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          const { getDocumentMeta } = data;
          document.title = (getDocumentMeta.pageTitle) ? `BBC Partners | ${getDocumentMeta.pageTitle}` : 'BBC Partners';

          if (getDocumentMeta.pageTitle) {
            this.trackPage(this.props.history.location.pathname, getDocumentMeta.pageTitle);
          }

          const headerBackground = data.getHeaderImage.sizes && data.getHeaderImage.sizes.large;
          const isBrandPage = /^\/brand\/(\w*)\/?$/.test(this.props.location.pathname);
          const isDetailPage = /^\/(brand|episode|clip)/.test(this.props.location.pathname);

          return (
            <header
              className={cx('b-main-header', { 'b-main-header--small-version': !isBrandPage, 'is-on-detail': isDetailPage })}
              style={{ backgroundImage: `url(${headerBackground})` }}
            >
              <div className="container">
                <div className="b-main-header__wrapper gr-12 no-gutter">
                  <div className="b-main-header__top-row">
                    <Link className="b-main-header__logo" to="/"><BBCLogoIcon /></Link>
                    <button className={cx('menu-toggle menu-toggle--spin hide@l', { ' is-active': this.state.showMenu })} type="button" onClick={this.toggleMenu}>
                      <span className="menu-toggle__box">
                        <span className="menu-toggle__inner" />
                      </span>
                    </button>
                  </div>

                  <MaxBreakPointL>
                    <AnimateHeight
                      duration={500}
                      height={this.state.showMenu ? 'auto' : 0}
                    >
                      {this.renderMenu()}
                    </AnimateHeight>
                  </MaxBreakPointL>
                  <MinBreakPointL>
                    {this.renderMenu()}
                  </MinBreakPointL>

                  {this.renderSearch(getDocumentMeta)}

                </div>
              </div>

            </header>
          );
        }}

      </Query>
    );
  }

  renderSearch(documentMeta) {
    const {
      isHome, languages, genres, history,
    } = this.props;
    const showFilter = !isHome;
    const isVisible = (isHome || (this.state.showMobileSearch && this.state.showMenu));

    return (
      <>
        <MaxBreakPointL>
          <Search
            isVisible={isVisible}
            isMobile
            hasFilter={showFilter}
            languages={languages}
            genres={genres}
            documentMeta={documentMeta}
            history={history}
          />
        </MaxBreakPointL>
        <MinBreakPointL>
          <Search
            isVisible
            isMobile={false}
            hasFilter={showFilter}
            languages={languages}
            genres={genres}
            documentMeta={documentMeta}
            history={history}
          />
        </MinBreakPointL>
      </>
    );
  }

  renderMenu() {
    const {
      isHome, loginStatus, navigation, cartFetch,
    } = this.props;
    const showFilter = !isHome;

    return (
      <>
        <div className="b-main-header__meta-elements-wrapper">
          <div className="b-main-header__meta-elements">
            {showFilter
                            && (
                            <button
                              className="b-main-header__meta-elements__search-btn"
                              onClick={this.toggleMobileSearch}
                            >

                              {this.state.showMobileSearch
                                ? <span className="close" />
                                : <SearchIcon />}
                            </button>
                            )}

            {loginStatus ? (
              <span className="meta-wrapper">
                <FavLink />
                <BasketLink cartFetch={cartFetch} />
                <Link className="b-main-header__meta-elements__profile" to="/user">
                  <SettingsIcon />
                </Link>

                <Mutation
                  mutation={MUTATION_LOGOUT} // awaitRefetchQueries={true}
                  update={this.onUpdateLogout}
                  optimisticResponse={optimisticResponse}
                >
                  {(mutate, {
                    data, loading, called, ...rest
                  }) => {
                    if (rest.error) {
                      return <div>Error:</div>;
                    }

                    const disabled = loading;

                    return (
                      <form
                        className="b-main-header__meta-elements__logout"
                        disabled={disabled}
                        onSubmit={(e) => {
                          e.preventDefault();
                          mutate();
                        }}
                      >

                        <button title="Logout" type="submit">
                          <span>Logout</span>
                          <LogoutIcon />
                        </button>

                      </form>
                    );
                  }}
                </Mutation>
              </span>

            )
              : (
                <>
                  <LoginLink className="b-button-link-style" />
                  <Link to="/contact/become-bbc-media-partner/">Become a Partner</Link>
                </>
              )}
          </div>
        </div>

        <div className="b-main-header__main-nav">
          <nav>
            <ul className="b-main-header__main-nav__ul">
              <MaxBreakPointL>
                <li className="b-main-header__main-nav__item">

                  {this.props.regions.filter((_, idx) => idx === 0).map((region) => (
                    <span key={region.slug} className="sub-menu-item-wrapper">
                      <NavLink
                        location={this.props.location}
                        activeClassName="b-main-header__main-nav__item--active"
                        to={`/catalogue/region/${region.slug}`}
                        isActive={(match, location) => (match || location.pathname.indexOf('/catalogue/region/') !== -1)}
                      >
                        Discover
                        {' '}
                      </NavLink>
                      <span className={cx('sub-menu-item-wrapper__arrow', { 'sub-menu-item-wrapper__arrow--open': this.state.showSubMenu })} onClick={this.toggleSubMenu} />
                    </span>
                  ))}

                  <div className={cx('sub-nav', { 'sub-nav--open': this.state.showSubMenu })}>
                    <ul className="b-main-header__main-nav__sub-nav">
                      {this.props.regions.filter((_, idx) => idx === 0).map((region) => (
                        <li key={region.slug} className="b-main-header__main-nav__sub-nav__item">
                          <NavLink location={this.props.location} activeClassName="b-main-header__main-nav__item--active" to={`/catalogue/region/${region.slug}`}>
                            {region.name}
                          </NavLink>
                        </li>
                      ))}
                      {this.props.regions.filter((_, idx) => idx !== 0).map((region) => (
                        <li key={region.slug} className="b-main-header__main-nav__sub-nav__item">
                          <NavLink location={this.props.location} activeClassName="b-main-header__main-nav__item--active" to={`/catalogue/region/${region.slug}`}>
                            {region.name}
                          </NavLink>
                        </li>
                      ))}

                    </ul>
                  </div>
                </li>
              </MaxBreakPointL>
              <MinBreakPointL>
                <li onMouseEnter={this.toggleSubMenu} onMouseLeave={this.toggleSubMenu} className="b-main-header__main-nav__item">

                  {this.props.regions.filter((_, idx) => idx === 0).map((region) => (
                    <NavLink
                      key={region.slug}
                      location={this.props.location}
                      activeClassName="b-main-header__main-nav__item--active"
                      to={`/catalogue/region/${region.slug}`}
                      isActive={(match, location) => (match || location.pathname.indexOf('/catalogue/region/') !== -1)}
                    >
                      Discover
                    </NavLink>
                  ))}

                  <div className={cx('sub-nav', { 'sub-nav--open': this.state.showSubMenu })}>
                    <ul className="b-main-header__main-nav__sub-nav">
                      {this.props.regions.filter((_, idx) => idx === 0).map((region) => (
                        <li key={region.slug} className="b-main-header__main-nav__sub-nav__item">
                          <NavLink location={this.props.location} activeClassName="b-main-header__main-nav__item--active" to={`/catalogue/region/${region.slug}`}>
                            {region.name}
                          </NavLink>
                        </li>
                      ))}
                      {this.props.regions.filter((_, idx) => idx !== 0).map((region) => (
                        <li key={region.slug} className="b-main-header__main-nav__sub-nav__item">
                          <NavLink location={this.props.location} activeClassName="b-main-header__main-nav__item--active" to={`/catalogue/region/${region.slug}`}>
                            {region.name}
                          </NavLink>
                        </li>
                      ))}

                    </ul>
                  </div>
                </li>
              </MinBreakPointL>

              <li className="b-main-header__main-nav__item"><NavLink location={this.props.location} exact={false} to="/schedules" activeClassName="b-main-header__main-nav__item--active">Schedules</NavLink></li>
              <li className="b-main-header__main-nav__item"><NavLink location={this.props.location} exact={false} to="/rundowns" activeClassName="b-main-header__main-nav__item--active">Rundowns</NavLink></li>

              { navigation.map(({ id, title }) => (
                <li key={id} className="b-main-header__main-nav__item">
                  <NavLink location={this.props.location} exact={false} to={`/${id}`} activeClassName="b-main-header__main-nav__item--active">{title}</NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </>
    );
  }
}

export default Header;
