import React, { Component } from 'react';

class PrintIcon extends Component {
  render() {
    return (
      <svg version="1.1" viewBox="0 0 28 31">
        <path d="M4,22H3c-1.7,0-3-1.3-3-3V9c0-1.7,1.3-3,3-3h1V2c0-1.1,0.9-2,2-2h16c1.1,0,2,0.9,2,2v4h1c1.7,0,3,1.3,3,3v10
    c0,1.7-1.3,3-3,3h-1v6c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V22z M6,6h16V3c0-0.6-0.4-1-1-1H7C6.4,2,6,2.4,6,3V6z M24,12
    c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S22.9,12,24,12z M21,28c0.6,0,1-0.4,1-1V17c0-0.6-0.4-1-1-1H7c-0.6,0-1,0.4-1,1v10
    c0,0.6,0.4,1,1,1H21z M20,26H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h11c0.6,0,1,0.4,1,1S20.6,26,20,26z M20,22h-9c-0.6,0-1-0.4-1-1s0.4-1,1-1
    h9c0.6,0,1,0.4,1,1S20.6,22,20,22z"
        />
      </svg>
    );
  }
}

export default PrintIcon;
