import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../lib/logging';

class TagButtonList extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'TagButtonList');
    this.logDebug('init:', props);
  }

  render() {
    return (
      <ul className="b-tag-button-list">
        { this.props.items.map((item) => (
          <li key={item.name} className="b-tag-button-list__item">
            <Link to={item.url}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export default TagButtonList;
