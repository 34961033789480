/* eslint-disable no-console */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import find from 'lodash/find';
import { withApollo } from 'react-apollo';
import BasketItem from './BasketItem';
import { getDownloadData } from '../../../lib/mediaSelector';
import NotLoggedIn from '../../../components/notLoggedIn/notLoggedIn';
import SettingsIcon from '../../../components/icons/SettingsIcon';
import graphQlHoc from '../../../components/graphQlHoc';
import BasketMutations from '../../../graphql/BasketMutations';
import DOWNLOAD_COMPLETE from './downloadComplete.gql';
import DOWNLOAD_ERROR from './downloadError.gql';
import CART_FETCH from '../../../graphql/BasketMutations/cartFetch.gql';
import query from './query.gql';

async function download(index, {
  versionPid, format, fname, token,
}, initCallback, progressCallback, errorCallback) {
  const { url, mimeType, extension } = await getDownloadData(versionPid, format, token);

  if (!url || !mimeType) {
    errorCallback({ statusText: ' This Item is not available at the moment.' });
    return;
  }

  const xhr = new XMLHttpRequest();

  xhr.addEventListener('progress', (event) => {
    if (event.lengthComputable) {
      const percentComplete = event.loaded / event.total;

      progressCallback(index, percentComplete);
    }
  }, false);

  xhr.addEventListener('readystatechange', () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const blob = new Blob([xhr.response], { type: mimeType });
      const a = document.createElement('a');
      a.href = (window.URL || window.webkitURL).createObjectURL(blob);
      a.download = fname + extension;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      initCallback(index, 2);
    } else if (xhr.readyState === 4) {
      console.error('Error', url, xhr.status, xhr.statusText);
      errorCallback({ url, status: xhr.status, statusText: xhr.statusText || 'Server Error' });
    }
  });

  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.send();
}

class CartContent extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { cartFetch } = this.props.apolloClient.readQuery({ query: CART_FETCH }, true);

    if (this.props.cartDetailed.items.length !== cartFetch.items.length) {
      window.location.reload();
    }
  }

  groupByBrandSortByEpisode(sourceItems) {
    const grouped = {};
    sourceItems.forEach((item) => {
      grouped[item.teaser.brand.title] = grouped[item.teaser.brand.title] || [];
      grouped[item.teaser.brand.title].push(item);
    });

    return Object.entries(grouped).map(([title, items]) => ({
      title,
      items: items.sort((a, b) => {
        if (a.pid === b.pid) {
          return (a.version.label > b.version.label ? 1 : -1);
        }
        return (a.pid > b.pid ? 1 : -1);
      }),
    }));
  }

  render() {
    const { activeBasketDownload, cartDetailed, documentMeta } = this.props;

    if (cartDetailed.items.length === 0) {
      return (
        <div className="b-cart-page">
          <div className="container container--indent">
            <div className="gr-12">
              <div className="gr-12 gr-9@l">
                <h1>{documentMeta.pageTitle}</h1>
                <p>There are currently no items in your basket.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">
            <div className="gr-12 gr-9@l">
              <h1>{documentMeta.pageTitle}</h1>

              {this.groupByBrandSortByEpisode(cartDetailed.items).map(({ title, items }) => (
                <div key={title}>
                  <h2 className="brand-title">
                    Items from
                    {title}
                  </h2>
                  <ul>
                    {items.map((item) => {
                      const activeDownloadItem = (
                        activeBasketDownload && activeBasketDownload.items)
                        ? find(
                          activeBasketDownload.items,
                          (downloadItem) => downloadItem.versionPid === item.versionPid,
                        )
                        : null;

                      const isDownloading = activeBasketDownload.active
                                                && !activeBasketDownload.completed
                                                && activeDownloadItem && !activeDownloadItem.error;

                      return (
                        <BasketItem
                          key={item.versionPid}
                          item={item}
                          downloadItem={activeDownloadItem}
                          isDownloading={isDownloading}
                          startDownload={() => this.startDownloads([item])}
                        />
                      );
                    })}
                  </ul>
                </div>
              ))}
              <div>
                <button onClick={this.props.onEmpty} className="b-button b-button--secondary-style">Remove all</button>
              </div>
            </div>
            <div className="gr-12 gr-3@l b-cart-page__cta-box" ref={this.myRef}>
              <span className="h2-style">
                {cartDetailed.items.length}
                {' '}
                items
              </span>

              <button
                className="b-button"
                onClick={() => { this.startDownloads(cartDetailed.items); }}
              >
                <span>{activeBasketDownload && activeBasketDownload.completed && activeBasketDownload.items.length > 1 ? 'Downloaded' : 'One-Click-Download'}</span>
                {activeBasketDownload.active && activeBasketDownload.items.length > 1
                                    && (
                                    <span
                                      className="progress"
                                      style={{
                                        width: `${(activeBasketDownload.items.reduce((sum, { progress }) => {
                                          sum += progress;
                                          return sum;
                                        }, 0) / activeBasketDownload.items.length) * 100}%`,
                                      }}
                                    />
                                    )}
              </button>

              <Link className="open-settings" to="/user?tab=1">
                <SettingsIcon />
                {' '}
                Change default download format
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  startDownloads = async (items) => {
    const { apolloClient, activeBasketDownload } = this.props;

    if (activeBasketDownload && activeBasketDownload.active) {
      return;
    }

    try {
      await this.props.apolloClient.writeData({
        data: {
          activeBasketDownload: {
            __typename: 'BasketDownload',
            completed: false,
            active: true,
            items: items.filter(
              (item) => item.version.isDownloadable,
            ).map(({ pid, versionPid }) => ({
              __typename: 'BasketDownloadItem',
              _id: versionPid,
              versionPid,
              pid,
              progress: 0,
              completed: false,
              error: false,
              errorStatus: null,
            })),
          },
        },
      });

      const progressUpdate = (item, progress) => {
        apolloClient.writeData({
          id: `BasketDownloadItem:${item._id}`,
          data: { progress },
        });
      };

      const completeUpdate = (item) => Promise.all([
        apolloClient.writeData({
          id: `BasketDownloadItem:${item._id}`,
          data: { completed: true },
        }),
        apolloClient.mutate({
          variables: { pid: item.pid, versionPid: item.versionPid },
          mutation: DOWNLOAD_COMPLETE,
          refetchQueries: [{ query: CART_FETCH }],
        }),
      ]);

      const errorUpdate = ({ item, statusText }) => {
        apolloClient.writeData({
          id: `BasketDownloadItem:${item._id}`,
          data: {
            errorStatus: `${statusText}`,
            error: true,
            completed: false,
          },
        });
        apolloClient.mutate({
          variables: { pid: item.pid, versionPid: item.versionPid },
          mutation: DOWNLOAD_ERROR,
        });
      };

      const downloadItems = this.props.activeBasketDownload.items;

      const itemsMap = items.reduce((acc, item) => {
        acc[item.versionPid] = item;
        return acc;
      }, {});

      await Promise.all(downloadItems.map(
        (item, index) => new Promise((resolveInner) => {
          download(index, itemsMap[item.versionPid], (idx) => {
            completeUpdate(downloadItems[idx]).then(() => {
              resolveInner(null);
            });
          }, (idx, progress) => {
            progressUpdate(downloadItems[idx], progress);
          }, ({ statusText, status }) => {
            errorUpdate({
              item: downloadItems[index],
              status,
              statusText,
            });
            resolveInner({
              item: downloadItems[index],
              status,
              statusText,
            });
          });
        }),
      ));

      apolloClient.writeData({
        id: 'BasketDownload',
        data: {
          completed: true,
          active: false,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount() {
    if (this.props.activeBasketDownload.active) {
      this.props.apolloClient.writeData({
        id: 'BasketDownload',
        data: { active: false, items: null },
      });
    }
  }
}

function Cart({ data, client }) {
  if (!data.loginStatus) {
    return (<NotLoggedIn />);
  }

  return (
    <BasketMutations>
      {({ empty }) => (
        <CartContent
          apolloClient={client}
          documentMeta={data.page.documentMeta}
          cartDetailed={data.cartDetailed}
          activeBasketDownload={data.activeBasketDownload}
          onEmpty={empty}
        />
      )}
    </BasketMutations>
  );
}

export default graphQlHoc(withApollo(Cart), query);
