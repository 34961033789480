import React, { Component } from 'react';

class FAQIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M47.1 5.3c1.6 0 2.9 1.3 2.9 2.9v21.9l-.1 4.3a1.5 1.5 0 0 1-2.5 1L42.1 30H37V15.6c0-1.6-1.3-2.9-2.9-2.9H14.7V8.2c0-1.6 1.3-2.9 2.9-2.9h29.5zM2.9 14.1h29.4c1.6 0 2.9 1.3 2.9 2.9v19c0 1.6-1.3 2.9-2.9 2.9H7.9l-5.3 5.4c-.6.6-1.5.6-2.1 0-.3-.3-.4-.6-.4-1L0 39V17c0-1.6 1.3-2.9 2.9-2.9zm3 5.9c-.8 0-1.5.7-1.5 1.5S5.1 23 5.9 23h23.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H5.9zm0 7.3c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h16.2c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H5.9z" /></svg>
    );
  }
}

export default FAQIcon;
