import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import opentype from 'opentype.js';
import { versionAvailability } from './time';

const hostname = `https://${window.location.hostname}`;
const logo = 'https://static.files.bbci.co.uk/wspartners/images/pdf_logo.png';
const defaultEpisodeImage = 'https://ichef.bbci.co.uk/images/ic/640x360/p08dwm55.jpg';
const fillColor = '#f2f2f2';
const defaultFontName = 'BBCReithSans';
const fontPath = 'https://static.files.bbci.co.uk/wspartners/react/fonts';
const fontLanguageMap = {
  BBCReithSans: ['en'],
  NotoSansEthiopic: ['am'],
  NotoSansBengali: ['bn'],
  NotoNaskhArabic: ['ar', 'fa', 'ur'],
  NotoSansDevanagari: ['hi', 'mr', 'ne'],
  NotoSansGujarati: ['gu'],
  NotoSansGurmukhi: ['pa'],
  NotoSansTamil: ['ta'],
  NotoSansTelugu: ['te'],
  NotoSans: ['uk', 'ky', 'yo', 'uz', 'ru'],
  NotoSansSinhala: ['si'],
  NotoSansKorean: ['ko'],
  NotoSansMyanmar: ['bu'],
  NotoSansChinese: ['zh'],
};
const fonts = Object.fromEntries(
  Object.entries(fontLanguageMap).map(([key, value]) => [
    key,
    { normal: `${fontPath}/${key}.ttf` },
  ]),
);
const separator = () => ({
  canvas: [
    {
      type: 'line',
      x1: 0,
      y1: 5,
      x2: 595 - 2 * 40,
      y2: 5,
      lineWidth: 1,
      color: '#d9d9d9',
    },
  ],
});

const subHeading = (text) => ({
  table: {
    widths: ['100%'],
    heights: 20,
    body: [
      [
        {
          text,
          height: 20,
          alignment: 'left',
          margin: [5, 5, 5, 5],
        },
      ],
    ],
    dontBreakRows: true,
  },
  layout: 'noBorders',
  fillColor,
  margin: [0, 10, 0, 3],
});

const availability = (episode) => {
  const items = [];

  if (!episode.availability) {
    return items;
  }

  const availabilities = episode.versions
    .filter((version) => version.start)
    .map((version) => ({
      versionType: version.label,
      text: versionAvailability(version, episode.availability.evergreen, true),
      duration: version.duration || '',
    }));
  availabilities.forEach((availability, index) => {
    items.push(
      {
        columns: [
          {
            text: availability.versionType,
            height: 20,
            alignment: 'left',
            margin: [5, 5, 0, 3],
            width: '20%',
          },
          {
            text: availability.text,
            height: 20,
            alignment: 'left',
            margin: [5, 5, 0, 3],
            width: '40%',
          },
          {
            text: getDurationText(availability.duration),
            height: 20,
            alignment: 'left',
            margin: [0, 5, 0, 3],
            width: '25%',
          },
        ],
        columnGap: 10,
      },
      index === availabilities.length - 1 ? {} : separator(),
    );
  });
  return items;
};

const broadcastTimes = (title, titleFont, broadcastGroups) => {
  const items = [];
  broadcastGroups.forEach((item, index) => {
    if (!item.broadcasts.length === 0) {
      return;
    }
    const broadcasts = item.broadcasts.map((broadcast) => ({
      day: moment(broadcast.start).utc().format('ddd'),
      date: moment(broadcast.start).utc().format('yyyy-MM-DD'),
      startTime: moment(broadcast.start).utc().format('HH:mm'),
      endTime: moment(broadcast.end).utc().format('HH:mm'),
    }));

    items.push(...[
      {
        columns: [
          {
            text: `${title} on ${item.title} :`,
            height: 20,
            alignment: 'left',
            margin: [5, 5, 5, 5],
            width: '100%',
            font: titleFont,
          },
        ],
      },
      {
        columns: [
          {
            text: broadcasts.map((broadcast) => (`${broadcast.day} ${broadcast.date} ${broadcast.startTime}-${broadcast.endTime} GMT`)).join(', '),
            height: 20,
            alignment: 'left',
            margin: [5, 0, 5, 3],
            width: '100%',
          },
        ],
      },
      separator(),
    ]);
  });
  return items;
};

const getDurationText = (text) => {
  const duration = moment.duration(text);
  const hours = duration.hours() > 0 ? `${duration.hours()} hours ` : '';
  const minutes = duration.minutes() > 0 ? `${duration.minutes()} minutes ` : '';
  const seconds = duration.seconds() > 0 ? `${duration.seconds()} seconds ` : '';
  return `${hours}${minutes}${seconds}`;
};

const description = (synopsis, brandSynopsis) => {
  if (!synopsis) {
    return '';
  }
  if (synopsis.long && synopsis.long.length > 1) {
    return synopsis.long;
  }
  if (synopsis.medium && synopsis.medium.length > 1) {
    return synopsis.medium;
  }
  if (synopsis.short && synopsis.short.length > 1) {
    return synopsis.short;
  }
  return brandSynopsis?.short || '';
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const findSuitableFont = (font, text, languageCode) => {
  const canRender = !font.stringToGlyphs(text).find((glyph) => !glyph.unicode);
  if (canRender) {
    return defaultFontName;
  }
  return Object.entries(fontLanguageMap).find(([key, value]) => (
    value.includes(languageCode)))?.[0] || defaultFontName;
};

export const exportToPdf = async (episodes, { availabilityStart, availabilityEnd }) => {
  const content = [];
  const defaultFont = await opentype.load(fonts.BBCReithSans.normal);
  let reportDuration = moment(availabilityStart).format('DD MMM YYYY');

  if (availabilityEnd) {
    reportDuration += ` - ${moment(availabilityEnd).format('DD MMM YYYY')}`;
  } else {
    reportDuration = `From ${reportDuration}`;
  }
  content.push(
    {
      width: 240,
      height: 30,
      alignment: 'center',
      image: await getBase64FromUrl(logo),
      margin: [0, 0, 0, 15],
    },
    {
      text: 'Programme information for Media Partners',
      fontSize: 20,
      alignment: 'center',
      margin: [5, 0, 0, 0],
    },
    {
      text: reportDuration,
      fontSize: 14,
      alignment: 'center',
      margin: [5, 0, 0, 30],
      color: 'grey',
    },
  );

  for (const episode of episodes) {
    let base64Image = await getBase64FromUrl(episode.image.sizes.mini);
    if (!base64Image.includes('data:image/jpeg')) {
      base64Image = await getBase64FromUrl(defaultEpisodeImage);
    }
    const languageCode = episode.language?.languageCode;

    const items = [
      {
        fillColor,
        alignment: 'left',
        layout: 'noBorders',
        table: {
          widths: ['40%', '60%'],
          heights: 120,
          headerRows: 0,
          body: [
            [
              {
                width: 200,
                height: 120,
                image: base64Image,
              },
              {
                columns: [
                  [
                    {
                      text: episode.brand.title,
                      fontSize: 13,
                      alignment: 'left',
                      margin: [5, 5, 10, 2],
                      font: findSuitableFont(
                        defaultFont,
                        episode.brand.title,
                        languageCode,
                      ),
                    },
                    {
                      text: episode.episode.title,
                      fontSize: 11,
                      alignment: 'left',
                      margin: [5, 0, 10, 10],
                      font: findSuitableFont(
                        defaultFont,
                        episode.episode.title,
                        languageCode,
                      ),
                    },
                    {
                      text: `Release Date:  ${moment(
                        episode.episode.releaseDate,
                      ).format('dddd DD MMM YYYY')}`,
                      alignment: 'left',
                      margin: [5, 0, 0, 5],
                    },
                    {
                      text: episode.genre?.name ? `Genre:  ${episode.genre?.name}` : null,
                      alignment: 'left',
                      margin: [5, 0, 0, 5],
                    },
                    {
                      text: `Episode:  ${hostname}${episode.path}`,
                      alignment: 'left',
                      margin: [5, 0, 0, 5],
                    },
                    {
                      text: episode.trailerPids?.length > 0 ? `Trail:  ${hostname}/episode/${episode.trailerPids[0]}` : null,
                      alignment: 'left',
                      margin: [5, 0, 0, 5],
                    },
                  ],
                ],
              },
            ],
          ],
          dontBreakRows: true,
        },
      },
      {
        text: '',
        margin: [0, 5, 0, 0],
      },
      subHeading('Download availability'),
      availability(episode.episode),
      subHeading('Programme description'),
      {
        text: description(episode.episode.synopsis, episode.brand.synopsis),
        alignment: 'left',
        margin: [5, 5, 0, 5],
        font: findSuitableFont(
          defaultFont,
          description(episode.episode.synopsis, episode.brand.synopsis),
          languageCode,
        ),
      },
    ];

    if (episode.broadcastGroups && episode.broadcastGroups.length > 0) {
      items.push(
        subHeading('Broadcast times'),
        {
          text: '',
          margin: [0, 0, 0, 5],
        },
        broadcastTimes(
          episode.episode.title,
          findSuitableFont(defaultFont, episode.episode.title, languageCode),
          episode.broadcastGroups,
        ),
      );
    } else {
      items.push(
        subHeading('Broadcast times'),
        {
          text: 'Not scheduled, ondemands only.',
          margin: [0, 10, 0, 5],
        },
      );
    }

    if (episode.marketingText && episode.marketingText.length > 0) {
      items.push(subHeading('Additional information for media partners'));
      items.push({
        text: episode.marketingText.replaceAll(/<\/?[^>]+(>|$)/gi, ''),
        alignment: 'left',
        margin: [0, 5, 0, 5],
      });
    }

    items.push({
      text: '',
      margin: [0, 0, 0, 30],
    });
    content.push(items);
  }

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 40, 40, 100],
    content,
    footer: {
      columns: [
        {
          text: 'Information contained in this document is subject to change. The BBC reserves the right to restrict further or (exceptionally) withdraw broadcasting rights for a particular programme or series if necessary. If you have any questions, please contact us https://wspartners.bbc.com/contact',
          alignment: 'center',
          fontSize: 6,
          margin: [40, 30, 40, 0],
          height: 100,
        },
      ],
    },
    defaultStyle: {
      font: defaultFontName,
      fontSize: 9,
    },
  };
  const win = window.open('', '_blank');
  pdfMake.createPdf(docDefinition, null, fonts).open({}, win);
};
