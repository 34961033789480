import React, { Component } from 'react';

class MailIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M0 17.2l23.8 19.4c.7.6 1.7.6 2.3 0L50 17.2v22.2c0 1-.8 1.9-1.9 1.9H1.9c-1 0-1.9-.8-1.9-1.9V17.2zm50-6.6v2.9L26.2 32.7a2 2 0 0 1-2.3 0L0 13.5v-2.9c0-1 .8-1.9 1.9-1.9h46.3c1 0 1.8.9 1.8 1.9z" /></svg>
    );
  }
}

export default MailIcon;
