import React, { Component, Fragment } from 'react';
import { Formik, Field } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import { graphql, withApollo } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';
import POST_QUERY from './contactForm.gql';
import UPDATE_POST_MUTATION from './updateContactForm.gql';
import TextField from './TextField';
import TextArea from './TextArea';
import SelectField from './SelectField';
import { setPageTitle } from '../../../js/helper';
import { withPendingState } from '../../../components/graphQlHoc';

/* eslint-disable no-useless-escape */
const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const urlErrorMessage = 'Please remove URLs';

const validationSchema = yupObject().shape({
  organisation: yupString()
    .test('url-check', urlErrorMessage, (value) => !urlRegex.test(value))
    .required('Required'),
  contactName: yupString().required()
    .max(50, 'Too Long!')
    .test('url-check', urlErrorMessage, (value) => !urlRegex.test(value))
    .required('Required'),
  emailAddress: yupString().required()
    .email('Please provide an e-mail')
    .required('Required'),
  phoneNumber: yupString().required()
    .test('url-check', urlErrorMessage, (value) => !urlRegex.test(value)),
  country: yupString()
    .required('Required'),
  message: yupString()
    .test('url-check', urlErrorMessage, (value) => !urlRegex.test(value))
    .required('Required'),
  inquiryType: yupString()
    .required('Required'),
});

class Contact extends Component {
  componentDidMount() {
    setPageTitle('Contact', this.props.client);
  }

  render() {
    const { updatePost, contactForm: { loading, contactForm }, ...props } = this.props;

    const { inquiryType, pid } = props.match.params;
    const { countryOptions, inquiryOptions, ...initialValues } = contactForm || {};

    return (
      <div className="b-contact-page">

        {!loading
                && (
                <Formik
                  initialValues={{
                    ...initialValues,
                    pid: pid || initialValues.pid,
                    inquiryType: inquiryType || initialValues.purpose,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => updatePost({
                    variables: values,
                  })
                    .then(() => {
                      actions.setSubmitting(false);
                      actions.setStatus({ done: true });
                    })
                    .catch(() => {
                      actions.setSubmitting(false);
                      actions.setErrors({
                        serverError: true,
                      });
                    })}
                  render={({
                    handleSubmit, errors, isSubmitting, status,
                  }) => (

                    <div className="container container--indent">
                      <div className="gr-12">
                        <h1>Contact</h1>

                        {(!status || !status.done)
                          ? (
                            <>
                              <p>If you have any questions, you can contact us:</p>
                              <p>
                                Your information will be used in accordance with our privacy policy.
                                All fields marked with * are mandatory
                              </p>
                            </>
                          )

                          : (
                            <p>
                              Thank you for contacting BBC World Service Partners.
                              Your enquiry is important to us and we will get back to you shortly.
                            </p>
                          )}
                      </div>
                      <form onSubmit={handleSubmit}>

                        {(!status || !status.done)

                                && (
                                <div className={cx('gr-12 gr-8@m', {
                                  'is--busy': isSubmitting,
                                })}
                                >

                                  <Field
                                    component={TextField}
                                    name="organisation"
                                    required
                                    type="text"
                                    label="Organisation Name"
                                  />

                                  <Field
                                    component={SelectField}
                                    name="country"
                                    required
                                    label="Country"
                                    options={contactForm.countryOptions}
                                  />

                                  <Field
                                    component={TextField}
                                    name="contactName"
                                    required
                                    type="text"
                                    label="Contact name"
                                  />

                                  <Field
                                    component={TextField}
                                    name="emailAddress"
                                    required
                                    type="email"
                                    label="Contact email address"
                                  />

                                  <Field
                                    component={TextField}
                                    name="phoneNumber"
                                    type="tel"
                                    label="Contact phone number"
                                  />

                                  <Field
                                    component={SelectField}
                                    name="inquiryType"
                                    required
                                    label="Enquiry type"
                                    options={contactForm.inquiryOptions}
                                  />

                                  <Field
                                    component={TextArea}
                                    name="message"
                                    maxLength="750"
                                    label="Your Message"
                                    required
                                    rows="4"
                                  />

                                  {errors.serverError && <div>Server Error</div>}

                                  <button className="b-button" type="submit" disabled={isSubmitting}>
                                    Submit
                                  </button>
                                </div>
                                )}
                      </form>
                    </div>
                  )}
                />
                )}
      </div>
    );
  }
}

export default withPendingState(compose(
  graphql(POST_QUERY, { name: 'contactForm' }),
  graphql(UPDATE_POST_MUTATION, { name: 'updatePost' }),
)(withApollo(Contact)));
