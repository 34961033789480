import React, { Component } from 'react';

class NotificationIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M29.5 4.2a15.2 15.2 0 0 1 10.7 14.5v11.7l5.3 7.9c.5.8.3 1.9-.5 2.5-.3.2-.6.3-1 .3H6c-1 0-1.8-.8-1.8-1.8 0-.4.1-.7.3-1l5.3-7.9V18.8c0-6.8 4.5-12.6 10.7-14.5C20.7 1.9 22.6 0 25 0s4.3 1.9 4.5 4.2zM18.8 42.9H33c0 3.9-3.2 7.1-7.1 7.1s-7.1-3.2-7.1-7.1z" /></svg>
    );
  }
}

export default NotificationIcon;
