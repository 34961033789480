import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';

import logging from '../../lib/logging';
import SearchInput from './SearchInput';
import FilterTag from '../filterTag/FilterTag';
import Selectbox from '../form-elements/selectbox/Selectbox';
import Checkbox from '../form-elements/checkbox/Checkbox';
import DateSelect from '../form-elements/dateselect/DateSelect';

const stateDefaults = {
  filterToggle: false,
  term: '',
  genres: [],
  fileTypes: [],
  language: null,
  downloadableContentOnly: null,
  includeArchiveContent: true,
  filterByAvailability: false,
  availabilityStart: moment.utc().toDate(),
  availabilityEnd: null,
  includeBrands: true,
  includeEpisodes: true,
  includeTrails: true,
};

function compare(array1, array2) {
  if (array1.length !== array2.length) {
    return false;
  }

  array1 = array1.slice();
  array1.sort();
  array2 = array2.slice();
  array2.sort();

  for (let i = 0; i < array1.length;) {
    i += 1;
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
}

class Search extends Component {
  state = {
    ...stateDefaults,
  };

  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Search');
    this.logDebug('init:', props);

    this.state = {
      ...this.state,
      ...this.getUrlParameters(),
    };

    let resetOnNavigation = (this.props.history.location.pathname === '/search/');

    this.props.history.listen((location) => {
      if (resetOnNavigation && location.pathname !== '/search/') {
        resetOnNavigation = false;
        this.setState({
          ...stateDefaults,
          stateReset: true,
        }, () => {
          this.setState({
            stateReset: false,
          });
        });
      }

      if (location.pathname === '/search/') {
        resetOnNavigation = true;
      }
    });
  }

  getUrlParameters() {
    const search = new URLSearchParams(window.document.location.search.slice(1));
    const params = {
      term: search.has('term') ? decodeURIComponent(search.get('term')) : this.state.term,
      genres: search.has('genres') ? search.get('genres').split(',') : this.state.genres,
      fileTypes: search.has('files') ? [search.get('files')] : this.state.fileTypes,
      includeArchiveContent: !!search.get('evergreen') || this.state.includeArchiveContent,
    };

    if (search.has('lang')) {
      params.language = this.props.languages.filter((item) => item.slug === search.get('lang'))[0];
    }

    return params;
  }

  toggleFilter = () => {
    this.setState({ filterToggle: !this.state.filterToggle });
  };

  handleTermChange = (event) => {
    this.setState({ term: event.target.value });
  };

  handleGenreChange = (value, active) => {
    const genres = (active)
      ? [...this.state.genres, value] : this.state.genres.filter((item) => item !== value);
    this.setState({ genres });
  };

  handleFileTypeChange = (value, active) => {
    const fileTypes = (active)
      ? [...this.state.fileTypes, value] : this.state.fileTypes.filter((item) => item !== value);
    this.setState({ fileTypes });
  };

  handleLangChange = (language) => {
    this.setState({ language });
  };

  handleCheckboxChange = (value, active) => {
    if (value === 'includeBrands' && this.state.filterByAvailability) {
      return;
    }
    if (value === 'filterByAvailability') {
      this.setState({ includeBrands: false });
    }
    this.setState({ [value]: active });
  };

  handleDateChange = (date, type) => {
    if (type === 'endDate' && !this.state.endDate) {
      date.setHours(23, 59, 59);
    }
    this.setState({ [type]: date });
  };

  handleSubmit = (event, download = false) => {
    event.preventDefault();
    this.doSearch(download);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.stateReset) {
      if (!(
        compare(prevState.genres, this.state.genres)
                && compare(prevState.fileTypes, this.state.fileTypes)
                && prevState.language === this.state.language
                && prevState.includeArchiveContent === this.state.includeArchiveContent)) {
        this.doSearch();
      }
    }
  }

  doSearch = (download = false) => {
    const {
      term,
      genres,
      fileTypes,
      language,
      includeArchiveContent,
      filterByAvailability,
      availabilityStart,
      availabilityEnd,
      includeBrands,
      includeEpisodes,
      includeTrails,
    } = this.state;

    let query = `term=${encodeURIComponent(term)}`;
    if (language) {
      query += `&lang=${language.slug}`;
    }
    if (includeArchiveContent) {
      query += '&evergreen=1';
    }
    if (genres.length > 0) {
      query += `&genres=${genres.join(',')}`;
    }
    if (fileTypes.length === 1) {
      query += `&files=${fileTypes[0]}`;
    }
    if (filterByAvailability && availabilityStart) {
      query += `&availabilityStart=${availabilityStart.toISOString().split('T')[0]}`;
    }
    if (filterByAvailability && availabilityEnd) {
      query += `&availabilityEnd=${availabilityEnd.toISOString().split('T')[0]}`;
    }
    if (!filterByAvailability && includeBrands) {
      query += '&includeBrands=1';
    }
    if (includeEpisodes) {
      query += '&includeEpisodes=1';
    }
    if (includeTrails) {
      query += '&includeTrails=1';
    }
    if (download) {
      query += '&download=1';
    }

    this.props.history.push(`/search/?${query}`);
  };

  render() {
    const {
      isVisible, isMobile, hasFilter, documentMeta, languages, genres,
    } = this.props;
    const isDetailPage = /^\/(brand|episode|clip)/.test(this.props.history.location.pathname);

    const genreMap = this.state.genres.reduce((acc, item) => ({ ...acc, [item]: true }), {});
    const filesMap = this.state.fileTypes.reduce((acc, item) => ({ ...acc, [item]: true }), {});

    return (hasFilter
      ? (
        <div className={cx('b-search has-filter row', {
          'is-mobile': isMobile,
          'is-visible': isVisible,
          'is-open': this.state.filterToggle,
          'is-on-detail': isDetailPage,
        })}
        >
          <div className="gr-12">
            <div>
              <div className="gr-12 no-gutter">
                <div className="no-gutter gr-12 gr-7@l">
                  <SearchInput
                    onSubmit={this.handleSubmit}
                    onChange={this.handleTermChange}
                    value={this.state.term}
                  />
                </div>
                <button className="filter-button hide show@l" onClick={this.toggleFilter}>
                  <span>Filter</span>
                </button>
              </div>
            </div>
            <div className={cx('filter-wrapper gr-12 no-gutter', { 'is-open': this.state.filterToggle })}>
              <div className="gr-12 gr-7@m no-gutter">
                <div className="gr-12 no-gutter genre-tags-wrapper">
                  {
                                        genres.map((genre) => (
                                          <FilterTag
                                            key={genre.slug}
                                            id={genre.slug}
                                            value={genreMap[genre.slug]}
                                            title={genre.name}
                                            onChange={this.handleGenreChange}
                                          />
                                        ))
                                    }
                </div>
                <div className="gr-4 no-gutter file-type-tags-wrapper">
                  <span className="gr-12 no-gutter file-type-text">File type</span>
                  <FilterTag title="Video" id="audio_video" value={filesMap.audio_video} onChange={this.handleFileTypeChange} />
                  <FilterTag title="Audio" id="audio" value={filesMap.audio} onChange={this.handleFileTypeChange} />
                </div>
                <div className="gr-8 no-gutter file-type-tags-wrapper">
                  <Checkbox
                    id="filterByAvailability"
                    value={this.state.filterByAvailability}
                    label="Filter by availability (GMT)"
                    onChange={this.handleCheckboxChange}
                  />
                  {this.state.filterByAvailability
                                    && [
                                      <DateSelect
                                        key="start_date"
                                        id="start_date"
                                        type="start"
                                        value={this.state.availabilityStart}
                                        onChange={(date) => this.handleDateChange(date, 'availabilityStart')}
                                      />,
                                      <DateSelect
                                        key="end_date"
                                        id="end_date"
                                        type="end"
                                        min={this.state.availabilityStart}
                                        value={this.state.availabilityEnd}
                                        onChange={(date) => this.handleDateChange(date, 'availabilityEnd')}
                                      />,
                                    ]}
                </div>
              </div>
              <div className="gr-12 prefix-1@m gr-4@m no-gutter selectbox-wrapper">
                <Selectbox
                  value={this.state.language}
                  valueKey="slug"
                  onChange={this.handleLangChange}
                  options={languages}
                  placeholder="Select a language…"
                  getOptionValue={(language) => language.slug}
                  getOptionLabel={(language) => language.name}
                />
                <Checkbox
                  id="includeArchiveContent"
                  value={this.state.includeArchiveContent}
                  label="Include continuous content"
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  id="includeBrands"
                  value={this.state.includeBrands}
                  label="Include brands"
                  onChange={this.handleCheckboxChange}
                  disabled={this.state.filterByAvailability}
                />
                <Checkbox
                  id="includeEpisodes"
                  value={this.state.includeEpisodes}
                  label="Include episodes"
                  onChange={this.handleCheckboxChange}
                />
                <Checkbox
                  id="includeTrails"
                  value={this.state.includeTrails}
                  label="Include trails"
                  onChange={this.handleCheckboxChange}
                />
                {this.state.filterByAvailability
                  && (
                    <button className="download-button" onClick={(event) => this.handleSubmit(event, true)}>
                      Export to PDF
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="gr-12 gr-11@m b-search is-simple is-visible">
          <h1 className="h1-style">{documentMeta.shortSummary}</h1>
          <p>{documentMeta.description}</p>
          <div className="gr-12 gr-10@l no-gutter">
            <SearchInput
              onSubmit={this.handleSubmit}
              onChange={this.handleTermChange}
              value={this.state.term}
            />
          </div>
        </div>
      )
    );
  }
}

export default Search;
