import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

export default {
  dataIdFromObject: (object) => {
    if (object.__typename === 'EpisodeModel') {
      return null;
    }

    if (object.__typename) {
      if (object.__typename === 'Teaser') {
        if (object.pid) {
          return `Programme${object.__typename}:${object.pid}`;
        }
        return defaultDataIdFromObject(object);
      }

      if (object.__typename === 'BasketDownload'
                || object.__typename === 'DocumentMeta'
                || object.__typename === 'HeaderImage') {
        return object.__typename;
      }

      const key = object.versionPid || object.pid || object.id || object._id || object.slug;
      if (key) {
        return `${object.__typename}:${key}`;
      }
    }

    return defaultDataIdFromObject(object);
  },
};
