import React, { Component } from 'react';

class SearchIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <path d="M32.9 37.1c-8.1 6-19.5 5.4-26.9-1.9-8-8-8-21.1 0-29.1s21.1-8 29.1 0c7.3 7.3 8 18.8 1.9 26.9l12 11.9a3 3 0 0 1 0 4.2 3 3 0 0 1-4.2 0l-11.9-12zm-1.4-5.5c6-6 6-15.8 0-21.9s-15.8-6-21.8 0-6 15.8 0 21.9 15.8 6 21.8 0z" />
      </svg>
    );
  }
}

export default SearchIcon;
