import React, { Component } from 'react';

class PlayIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M25 50a25 25 0 1 1 0-50 25 25 0 0 1 0 50zm-3-34.5l-.7-.2c-.6 0-1.2.5-1.2 1.2v17.2l.2.7c.4.5 1.1.6 1.6.3l11.7-8.6.3-.3c.4-.5.3-1.3-.3-1.6L22 15.5z" /></svg>
    );
  }
}

export default PlayIcon;
