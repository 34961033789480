import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import DurationIcon from '../icons/DurationIcon';
import PlaylistIcon from '../icons/PlaylistIcon';
import CalendarIcon from '../icons/CalendarIcon';
import BasketIcon from '../icons/BasketIcon';
import DownloadIcon from '../icons/DownloadIcon';
import FavlistIcon from '../icons/FavlistIcon';
import NotificationIcon from '../icons/NotificationIcon';
import PreviewIcon from '../icons/PreviewIcon';
import FacebookIcon from '../icons/FacebookIcon';
import TwitterIcon from '../icons/TwitterIcon';
import InstagramIcon from '../icons/InstagramIcon';
import PlayerIcon from '../icons/PlayerIcon';
import GridIcon from '../icons/GridIcon';

export const iconsMap = {
  calendar: CalendarIcon,
  duration: DurationIcon,
  playlist: PlaylistIcon,
  basket: BasketIcon,
  download: DownloadIcon,
  favlist: FavlistIcon,
  notification: NotificationIcon,
  preview: PreviewIcon,
  player: PlayerIcon,
  grid: GridIcon,
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
};

export class IconList extends Component {
  render() {
    const { isHorizontal = false } = this.props;

    return (
      <ul className={cx('b-icon-list', { 'is-horizontal': isHorizontal })}>
        {this.props.children}
      </ul>
    );
  }
}

export class IconListItem extends Component {
  render() {
    const {
      icon, onClick, to, className,
    } = this.props;
    const Icon = iconsMap[icon];
    const isLinked = (onClick || to);
    let Element = 'div';
    if (isLinked) {
      Element = to ? Link : 'button';
    }

    return (
      <li>
        <Element to={to} onClick={onClick} className={cx('list-item', className, { 'is-linked': isLinked })}>
          <i className="icon"><Icon /></i>
          <span>{this.props.children}</span>
        </Element>
      </li>
    );
  }
}
