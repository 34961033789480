import React from 'react';
import cx from 'classnames';

export default function ({
  field, type, required, meta, form: { touched, errors }, label, ...props
}) {
  const hasError = touched[field.name] && errors[field.name];
  return (
    <div className={cx('form-element form-element--input', {
      'form-element--error': hasError,
    })}
    >
      <label className="form-element--input__label" htmlFor="check-1">
        <span>
          {label}
          {required ? '*' : ''}
        </span>
      </label>
      <input
        className="form-element--input__input"
        {...field}
        {...props}
        onChange={field.onChange}
        onBlur={field.onBlur}

        placeholder={`${label}${required ? '*' : ''}`}
        type={type}
        autoComplete="off"
      />
      {hasError && <span className="form-element__error-message">{errors[field.name]}</span>}
    </div>
  );
}
