import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './episodeClips.gql';
import { setPageTitle } from '../../../js/helper';
import TeaserList from '../../../components/teaserList/TeaserList';
import Pagination from '../../../components/pagination/Pagination';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';

class EpisodeClips extends Component {
  render() {
    const { pid, title } = this.props.data.episodeClips;
    const { items, totalPages } = this.props.data.episodeClips.clips;
    const { page } = this.props.route.match.params;

    const headline = `Latest Clips from ${title}`;
    setPageTitle(headline, this.props.client);

    return (
      <div className="position-relative">
        <div className="container container--indent">
          <div className="gr-12">
            <div className="b-main-title">
              <div className="title">
                <ExtendedLink to={`/episode/${pid}`}>{title}</ExtendedLink>
              </div>
            </div>

            <h1 className="gr-12 gr-11@l">{headline}</h1>
            <TeaserList
              isThirdGrid
              hasFavlist={false}
              hasAvailableHint
              hasBrandTitle={false}
              hasDescription
              hasGenre
              items={items}
            />
            <Pagination route={`/episode/${pid}/clips`} totalPages={totalPages} currentPage={parseInt(page, 10)} />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(EpisodeClips), query);
