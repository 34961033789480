import React, { Component } from 'react';
import cx from 'classnames';
import Media from 'react-media';
import logging from '../../lib/logging';
import BBCLogoIcon from '../icons/BBCLogoIcon';
import ExtendedLink from '../ExtendedLink/ExtendedLink';
import { IconListItem } from '../iconList/IconList';
import { visitSocialNetwork } from '../../js/helper';

class Footer extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Footer');
    this.logDebug('init:', props);

    this.state = {
      isToggleOn_0: true,
      isToggleOn_1: true,
      isToggleOn_2: true,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(id) {
    this.setState(() => ({
      [`isToggleOn_${id}`]: !this.state[`isToggleOn_${id}`],
    }));
  }

  render() {
    return (
      <footer className="b-main-footer gr-12">
        <div className="container">
          <div className="gr-12 no-gutter logo-row">
            <div className="logo">
              <BBCLogoIcon />
            </div>
            <ul className="social-links">
              <IconListItem onClick={() => visitSocialNetwork('twitter')} icon="twitter" />
              <IconListItem onClick={() => visitSocialNetwork('facebook')} icon="facebook" />
              <IconListItem onClick={() => visitSocialNetwork('instagram')} icon="instagram" />
            </ul>
          </div>

          <ul className="gr-12">

            <li className="gr-12 gr-3@l">
              <Media query="(max-width: 900px)">
                <span
                  onClick={() => this.handleClick(0)}
                  className="b-main-footer__item__headline"
                >
                  About us
                  <i className={cx('arrow', { 'arrow--open': !this.state.isToggleOn_0 })} />
                </span>
              </Media>
              <Media query="(min-width: 901px)">
                <span className="b-main-footer__item__headline">
                  About us
                </span>
              </Media>

              <ul id="footer__list" className={cx('b-main-footer__list', { 'b-main-footer__list--open': !this.state.isToggleOn_0 })}>
                <li className="b-main-footer__item"><ExtendedLink to="https://www.bbc.co.uk/aboutthebbc/whatwedo/worldservice">BBC World Service</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/article/our-content">Our Content</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/article/media-partnerships">Media Partnerships</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/article/our-team">Our Team</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="https://www.bbc.co.uk/terms/">Terms of Use</ExtendedLink></li>
              </ul>
            </li>

            <li className="gr-12 gr-6@l">
              <Media query="(max-width: 900px)">
                <span onClick={() => this.handleClick(1)} className="b-main-footer__item__headline gr-12 no-gutter">
                  Discover
                  <i className={cx('arrow', { 'arrow--open': !this.state.isToggleOn_1 })} />
                </span>
              </Media>
              <Media query="(min-width: 901px)">
                <span className="b-main-footer__item__headline gr-12 no-gutter">
                  Discover
                </span>
              </Media>
              <ul className={cx('b-main-footer__list', { 'b-main-footer__list--open': !this.state.isToggleOn_1 })}>
                <ul className="no-gutter gr-12 gr-6@l">
                  <li className="b-main-footer__item"><ExtendedLink to="/article/specials">Specials</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/news-current-affairs/1">News & Current Affairs</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/business/1">Business</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/sport/1">Sport</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/documentaries/1">Documentaries</ExtendedLink></li>
                </ul>
                <ul className="no-gutter gr-12 gr-6@l">
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/science-health-technology/1">Science, Health & Technology</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/human-interest/1">Human Interest</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/arts/1">Arts</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/society-culture/1">Society & Culture</ExtendedLink></li>
                  <li className="b-main-footer__item"><ExtendedLink to="/catalogue/region/ws-english/genre/childrens/1">Children&apos;s</ExtendedLink></li>
                </ul>
              </ul>
            </li>

            <li className="gr-12 gr-3@l">
              <Media query="(max-width: 900px)">
                <span onClick={() => this.handleClick(2)} className="b-main-footer__item__headline">
                  Useful Tools
                  <i className={cx('arrow', { 'arrow--open': !this.state.isToggleOn_2 })} />
                </span>
              </Media>
              <Media query="(min-width: 901px)">
                <span className="b-main-footer__item__headline">
                  Useful Tools
                </span>
              </Media>
              <ul className={cx('b-main-footer__list', { 'b-main-footer__list--open': !this.state.isToggleOn_2 })}>
                <li className="b-main-footer__item"><ExtendedLink to="/article/virtual-tour">Virtual Tour</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/article/bbc-programme-clock">BBC Programme Clock</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/article/programme-upload-and-broadcast-window-schedule">Flexible Content</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/schedules">Schedules</ExtendedLink></li>
                <li className="b-main-footer__item"><ExtendedLink to="/learning">Learning</ExtendedLink></li>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
    );
  }
}

export default Footer;
