/**
 * Main Javascript entry file
 *
 * all imported modules will be bundled to main.js
 * in this file it is possible to include files with globbing
 */

/* eslint no-underscore-dangle: "off" */
import './polyfill';

import React from 'react';
import { render } from 'react-dom';

import { ApolloProvider } from 'react-apollo';
import App from '../modules/app';

import apolloClient from '../graphql/apolloClient';

render(
  (
    <ApolloProvider client={apolloClient}>
      <App apolloClient={apolloClient} />
    </ApolloProvider>
  ), document.getElementById('main-app'),
);
