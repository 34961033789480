import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../../lib/logging';
import graphQlHoc from '../../../components/graphQlHoc';

import queryChannels from './queryChannels.gql';
import { getRegionName } from '../../../js/helper';

class Channels extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'channels');
    this.logDebug('init:', props);
  }

  render() {
    const {
      channels,
      page,
    } = this.props.data;

    const {
      documentMeta,
      widgets: [carouselWidget],
    } = page || { documentMeta: {}, widgets: [{ items: [] }] };

    return (
      <div className="b-channel-page">
        <div className="container container--indent">
          <div className="gr-12 gr-11@m page__intro">
            <h1>Schedules</h1>
            { documentMeta && (
            <p><strong dangerouslySetInnerHTML={{ __html: documentMeta.description }} /></p>
            )}
            { documentMeta && (
            <div dangerouslySetInnerHTML={{ __html: documentMeta.synopsis }} />
            )}
          </div>
          <div style={{ clear: 'both' }}>
            {channels.map((channelGroup, idxGroup) => (channelGroup.channels.length > 0
                            && (
                            <div key={idxGroup}>
                              <h2 className="gr-12">{getRegionName(channelGroup.name)}</h2>
                              <ul className="gr-12 no-gutter">
                                {channelGroup.channels.map((channel, idx) => (
                                  <li className="gr-12 gr-6@m gr-3@l" key={idx}>
                                    <Link className="b-teaser-square b-teaser-square--red-svg" to={channel.path}>
                                      <div>
                                        <span className="b-teaser-square__title">{channel.name}</span>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            )
            ))}
            {carouselWidget.items.length > 0 && (
            <div>
              <h2 className="gr-12">Schedules - .pdf files</h2>
              <ul className="gr-12 no-gutter">
                {carouselWidget.items.map((channel, idx) => (
                  <li className="gr-12 gr-6@m gr-3@l" key={idx}>
                    <a className="b-teaser-square b-teaser-square--red-svg" href={channel.path} target="_blank" rel="noopener noreferrer">
                      <div>
                        <span className="b-teaser-square__title">{channel.titleTruncated}</span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(Channels, queryChannels);
