import React, { Component } from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import logging from '../../lib/logging';

const URL = 'https://emp.bbci.co.uk/emp/bump-4/bump-4.js';
const maxPreviewSeconds = 120;

window.bbcDynamicDefineResults = window.bbcDynamicDefineResults || {};

window.define = (key, value, moduleObject) => {
  if (moduleObject) {
    window.bbcDynamicDefineResults[key] = moduleObject();
  }
};

window.define.amd = true;

class AsyncWrapper extends Component {
  render() {
    return (
      <div />
    );
  }
}

const AsyncScriptComponent = makeAsyncScriptLoader(URL)(AsyncWrapper);

class Player extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Player');
    this.logDebug('init:', props);

    this.state = {
      loaded: false,
    };

    this.myRef = React.createRef();
  }

  cancelTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  getTrackingPage() {
    const parts = window.location.pathname.split('/').filter((item) => item);
    if (parts.length === 0) {
      parts.push('home');
    }

    return `wspartners.${parts.join('.')}`;
  }

  render() {
    return (
      <div>
        <AsyncScriptComponent asyncScriptOnLoad={() => {
          this.setState({ loaded: true }, () => {
            const bump = window.bbcDynamicDefineResults['bump-4'];

            const settings = {
              product: 'ws-partners-website',
              responsive: true,
              counterName: this.getTrackingPage(),
              statsObject: {
                destination: 'WS_PARTNERS_B2B',
              },
              playlistObject: {
                holdingImageURL: this.props.cover,
                items: [
                  {
                    versionID: this.props.versionPid,
                  },
                ],
              },
            };

            if (this.props.samlToken) {
              settings.mediator = {
                overrideMediaSet: 'ws-partner-download',
              };
              settings.playlistObject.items[0].samltoken = this.props.samlToken;
            }

            const mediaPlayer = bump.player(this.myRef.current, settings);
            mediaPlayer.load({ autoplay: !!this.props.autoplay });

            if (this.props.isPreview) {
              mediaPlayer.bind('playing', () => {
                this.timer = setTimeout(() => { mediaPlayer.pause(); }, maxPreviewSeconds * 1000);
              });
              mediaPlayer.bind('pause', this.cancelTimer);
              mediaPlayer.bind('ended', this.cancelTimer);
            }
          });
        }}
        />

        <div ref={this.myRef} style={{ height: 420, width: '100%' }} />
      </div>
    );
  }
}

export default Player;
