import React, { Component } from 'react';
import logging from '../../../lib/logging';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Checkbox', false);
    this.logDebug('init:', props);
  }

  onChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.id, event.target.checked);
    }
  };

  render() {
    const {
      label, id, value, disabled,
    } = this.props;

    return (
      <div className="form-element form-element--checkbox">
        <input type="checkbox" id={id} name="checkbox" checked={value} onChange={this.onChange} disabled={disabled} />
        <label className="form-element--checkmark" htmlFor={id}><span>{label}</span></label>
      </div>
    );
  }
}

export default Checkbox;
