/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './DetailpageEpisode.gql';
import NotLoggedIn from '../../../components/notLoggedIn/notLoggedIn';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';

export function OldRow({ item }) {
  return (
    <>
      <div className="spacer10" />
      <div className={`roLin ro${item.segment}`}>
        <span className="roFlg roWid5 roWidPM roPM mobDisp">+</span>
        <span className="roSlg roWid15">{item.slug}</span>
        <span className="roApp roWid5">{item.approved ? '✔' : '✕'}</span>
        <span className="roTim roWid20">{`${item.start} - ${item.end}`}</span>
        <span className="roDur roWid5">{item.duration}</span>
        <span className="roTxt roWid50">{item.text}</span>
      </div>
    </>
  );
}

export function Row({ item }) {
  if (item.duration === '00:00') {
    if (['bulletin', 'programme'].includes(item.segment)) {
      return '';
    }
  }
  return (
    <div className={`roLin ro${item.segment}`}>
      <div className="roSlg">{item.slug}</div>
      <div className="roApp">{item.approved ? '✔' : '✕'}</div>
      <div className="roDur">{item.duration}</div>
      <div className="roDur">{item.segno}</div>
      <div className="roTxt">{item.text}</div>
    </div>
  );
}

function Rundown({ html, json }) {
  if (json) {
    return (
      <article className="fipwsbody">
        <p>&nbsp;</p>
        <p>
          Please note: This rundown is a working document
          - the nearer to transmission time, the more information it will include.
          The rundown is subject to change prior and during transmission.
        </p>

        <div className="gr-12 b-rundown">
          <hr className="roHR" />
          <div className="roLin roLinHead">
            <div className="roSlg">
              <b>Story</b>
            </div>
            <div className="roAppCol colApp">
              <b>Approved</b>
            </div>
            <div className="roTxt">
              <b>Abstract</b>
            </div>
          </div>
          <hr className="roHR" />
          {json.map((item) => <Row item={item} />)}
        </div>

        <p className="spacer10">&nbsp;</p>
        <hr className="roHR" />
        <div className="roEnd">
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          BBC World Service,
          New Broadcasting House, Portland Place, London W1A 1AA, UK +44 (0)20 7240 3456.
        </div>
      </article>
    );
  }
  return <div className="gr-12 b-rundown" dangerouslySetInnerHTML={{ __html: html }} />;
}

class EpisodeRundown extends Component {
  render() {
    const { loginStatus, episode } = this.props.data;

    if (!loginStatus) {
      return (<NotLoggedIn />);
    }

    return (
      <div className="container container--indent position-relative">
        <div className="gr-12 b-main-title">
          <div className="title">
            <ExtendedLink to={`/episode/${episode.pid}`}>{episode.title}</ExtendedLink>
          </div>
        </div>
        <h1 className="gr-12 gr-9@m">Rundown</h1>
        {window.history && window.history.length > 0
                && <button type="button" className="b-schedule-page__back-link hide show@m h4-style gr-12 gr-3@m" onClick={() => { window.history.back(); }}>Go back</button>}
        <Rundown html={episode.rundownContent} json={episode.rundownData} />
      </div>
    );
  }
}

export default graphQlHoc(EpisodeRundown, query);
