import React, { Component } from 'react';
import logging from '../../../lib/logging';

class WidgetImage extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetImage');
    this.logDebug('init:', props);
  }

  render() {
    const { panelTitle, image } = this.props;

    return (
      <section className="gr-12 gr-10@m gr-8@l widget-section">
        {panelTitle && <h2>{panelTitle}</h2>}
        {image && image.sizes && image.sizes.medium && <img className="b-detailpage__content__description__img" src={image.sizes.medium} alt="Teaser Image" />}
      </section>
    );
  }
}

export default WidgetImage;
