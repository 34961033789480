import React, { Component } from 'react';
import logging from '../../../lib/logging';
import Player from '../../player/Player';

class WidgetVideo extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'WidgetVideo');
    this.logDebug('init:', props);
  }

  render() {
    const { panelTitle, cover, ...widget } = this.props;

    return (
      <section className="widget-section gr-12 widget-video">
        <h2>{panelTitle}</h2>
        <Player cover={cover && cover.sizes && cover.sizes.medium} {...widget} />
      </section>
    );
  }
}

export default WidgetVideo;
