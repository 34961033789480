import React, { Component } from 'react';
import logging from '../../lib/logging';

class Button extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Button');
    this.logDebug('init:', props);
  }

  onClick = (ev) => {
    if (typeof this.props.onClick === 'function') {
      ev.preventDefault();
      this.props.onClick();
    }
  };

  render() {
    return (
      <div>
        <button type="button" className="b-button" disabled={this.props.disabled} onClick={this.onClick}>
          <span>{this.props.buttonName}</span>
        </button>
      </div>
    );
  }
}

export default Button;
