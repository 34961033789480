import React, { Component } from 'react';

class LockedIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M42.7 22h-3.6v-8c0-7.7-6.3-14-14.1-14S10.9 6.3 10.9 14v8H7.3a2.4 2.4 0 0 0-2.4 2.4v23.2C4.9 48.9 6 50 7.3 50h35.5c1.3 0 2.4-1.1 2.4-2.4V24.4a2.5 2.5 0 0 0-2.5-2.4zm-25.8-8c0-4.4 3.6-8 8.1-8s8.1 3.6 8.1 8v8H16.9v-8z" fill="#282828" /></svg>
    );
  }
}

export default LockedIcon;
