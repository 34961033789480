import React, { Component } from 'react';
import Detailpage from './Detailpage';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './DetailpageBrand.gql';

class DetailpageBrand extends Component {
  render() {
    const { data } = this.props;

    return (
      <Detailpage
        {...this.props}
        entity="brand"
        loginStatus={data.loginStatus}
        {...data.brand}
        brand={data.brand}
      />
    );
  }
}

export default graphQlHoc(DetailpageBrand, query);
