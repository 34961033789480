import React, { Component } from 'react';
import { withApollo } from 'react-apollo';

import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import Pagination from '../../../components/pagination/Pagination';
import Selectbox from '../../../components/form-elements/selectbox/Selectbox';

import query from './brandEpisodes.gql';
import { setPageTitle } from '../../../js/helper';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';

const PATH_AVAILABLE = 'episodes';
const PATH_LATEST = 'episodes-latest';
const PATH_AZ = 'episodes-az';
const OPTION_AVAILABLE = 'availability';
const OPTION_LATEST = 'latest';
const OPTION_AZ = 'az';

class BrandEpisodes extends Component {
  constructor(props) {
    super(props);
    this.sortLatest = /\/episodes\//.test(this.props.route.match.path);
    this.sortOptions = [
      { value: OPTION_AVAILABLE, label: 'Sort by availability' },
      { value: OPTION_LATEST, label: 'Sort by release date' },
      { value: OPTION_AZ, label: 'Sort alphabetically' },
    ];
  }

  onChange = (pid, option) => {
    const sortRoute = this.getSortRouteFromOption(option.value);
    this.props.route.history.push(`/brand/${pid}/${sortRoute}/1`);
  };

  getSortRouteFromOption(value) {
    switch (value) {
      case OPTION_LATEST:
        return PATH_LATEST;
      case OPTION_AZ:
        return PATH_AZ;
      case OPTION_AVAILABLE:
      default:
        return PATH_AVAILABLE;
    }
  }

  getSortRouteFromPath = () => this.props.route.match.path.match(/\/(episodes.*)\//)[1] || PATH_AVAILABLE;

  getHeadlinePrefix() {
    switch (this.getSortRouteFromPath()) {
      case PATH_LATEST:
        return 'Latest Episodes from ';
      case PATH_AZ:
        return 'Episodes A-Z from ';
      case PATH_AVAILABLE:
      default:
        return 'Available Episodes from ';
    }
  }

  render() {
    const { pid, title } = this.props.data.brandEpisodes;
    const { items, totalPages } = this.props.data.brandEpisodes.episodes;
    const { page } = this.props.route.match.params;

    const headline = this.getHeadlinePrefix() + title;
    const sortRoute = this.getSortRouteFromPath();

    setPageTitle(headline, this.props.client);

    return (
      <div className="position-relative">
        <div className="container container--indent">
          <div className="gr-12">
            <div className="b-main-title">
              <div className="title">
                <ExtendedLink to={`/brand/${pid}`}>{title}</ExtendedLink>
              </div>
            </div>

            <h1 className="gr-12 gr-6@m gr-8@l">{headline}</h1>

            <div className="gr-12 prefix-1@m gr-5@m prefix-1@l gr-3@l">
              <Selectbox
                id={pid}
                value={(this.sortLatest) ? this.sortOptions[0] : this.sortOptions[1]}
                onChange={this.onChange}
                options={this.sortOptions}
                searchable={false}
                clearable={false}
              />
            </div>

            <TeaserList
              isThirdGrid
              hasFavlist={false}
              hasAvailableHint
              hasBrandTitle={false}
              hasDescription
              hasGenre
              items={items}
            />
            <Pagination route={`/brand/${pid}/${sortRoute}`} totalPages={totalPages} currentPage={parseInt(page, 10)} />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(BrandEpisodes), query, null, true);
