import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './index.gql';
import { setPageTitle } from '../../../js/helper';

class specialPartnerContent extends Component {
  componentDidMount() {
    setPageTitle('Recommended for you', this.props.client);
  }

  render() {
    const { specialPartnerContentGrouped } = this.props.data;

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">
            <h1>Recommended for you</h1>
            { specialPartnerContentGrouped.map((group) => {
              if (group.items.length > 0) {
                return (
                  <TeaserList
                    key={group.slug}
                    teaserlistLink={`See all from ${group.label}`}
                    teaserlistLinkUrl={group.slug}
                    title={group.label}
                    isThirdGrid
                    hasFavlist
                    hasAvailableHint
                    hasBrandTitle
                    hasDescription
                    hasGenre
                    items={group.items}
                  />
                );
              }
              return null;
            }) }

          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(withApollo(specialPartnerContent), query);
