import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logging from '../../../lib/logging';
import TeaserList from '../../../components/teaserList/TeaserList';
import FavlistTileList from '../../../components/favlistTileList/FavlistTileList';
import NotLoggedIn from '../../../components/notLoggedIn/notLoggedIn';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './query.gql';
import SettingsIcon from '../../../components/icons/SettingsIcon';

class Favlist extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Favlist');
    this.logDebug('init:', props);
  }

  render() {
    if (!this.props.data.loginStatus) {
      return (<NotLoggedIn />);
    }

    return (
      <div className="b-fav-list-page">
        <div className="container container--indent">

          <div className="gr-12">
            <div className="row">
              <div className="gr-12 page-title">
                <h1>{this.props.data.page.documentMeta.pageTitle}</h1>
                <p>{this.props.data.page.documentMeta.synopsis}</p>

                <Link className="settings-link" to="/user?tab=2">
                  <SettingsIcon />
                  <span>Manage notifications</span>
                </Link>
              </div>

              {this.props.data.notifications.length
              > 0 && this.props.data.settings.showNotifications
                                && (
                                <div className="gr-12">
                                  {this.props.data.notifications
                                    .filter((item) => item.notifications.filter(
                                      (item2) => !item2.invalidated,
                                    ).length !== 0)
                                    .map(({ brand, notifications }) => (
                                      <FavlistTileList
                                        key={brand}
                                        brand={brand}
                                        title={notifications[0].episode.brand.title}
                                        items={notifications}
                                      />
                                    ))}
                                </div>
                                )}
            </div>

            <TeaserList
              title="Your favourite programme brands"
              isThirdGrid
              hasFavlist
              hasAvailableHint
              hasBrandTitle={false}
              hasDescription
              hasGenre
              isLocked={false}
              items={this.props.data.favorites}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(Favlist, query);
