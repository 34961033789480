import React, { Component } from 'react';

import { graphql, withApollo } from 'react-apollo';
import { compose } from '@shakacode/recompose';
import CART_FETCH from './cartFetch.gql';
import CART_DETAILED from './cartDetailed.gql';
import PUT_CART_ITEM from './put.gql';
import REMOVE_CART_ITEM from './remove.gql';
import REMOVE_CART_DETAILED_ITEM from './removeDetailed.gql';
import EMPTY_CART from './empty.gql';
import CHANGE_CART_ITEM_FORMAT from './changeCartItemFormat.gql';

const TOGGLE_TIMEOUT = 2000;

class BasketMutations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
    };
  }

  render() {
    const {
      pid, versionPid, preSelectedFormat, hasAccess, client,
    } = this.props;
    const { isDisabled } = this.state;
    const { cartFetch } = client.cache.readQuery({ query: CART_FETCH }, true);

    if (preSelectedFormat) {
      this.onChangeFormat(preSelectedFormat);
    }
    const loggedIn = !!cartFetch;
    const isInCart = loggedIn && cartFetch && ((versionPid)
      ? cartFetch.items.some((item) => item.versionPid === versionPid)
      : cartFetch.items.some((item) => item.pid === pid));

    let toggle = this.openOpenEndModal;
    if (loggedIn) {
      if (hasAccess) {
        toggle = () => { this.toggle(isInCart); };
      } else {
        toggle = this.openNoAccessModal;
      }
    }

    return (
      this.props.children({
        cartFetch,
        loggedIn,
        isInCart,
        toggle,
        isDisabled,
        add: this.onMutateAdd,
        changeFormat: this.onChangeFormat,
        remove: this.onMutateRemove,
        removeDetailed: this.onMutateRemoveDetail,
        empty: this.onMutateEmpty,
      })
    );
  }

  triggerTimeout = () => {
    this.setState({ isDisabled: true });
    setTimeout(() => {
      this.setState({ isDisabled: false });
    }, TOGGLE_TIMEOUT);
  };

  toggle = (isInCart) => {
    const { isDisabled } = this.state;

    if (!isDisabled) {
      this.triggerTimeout();

      if (isInCart) {
        this.onMutateRemove();
      } else {
        this.onMutateAdd();
      }
    }
  };

  openNoAccessModal = () => {
    this.openModal({
      variant: 'no-access',
      episodePid: this.props.pid,
    });
  };

  openOpenEndModal = () => {
    this.openModal({
      variant: 'open-end',
      episodePid: this.props.pid,
    });
  };

  openModal = (options) => {
    const data = {
      modalAuthorization: {
        __typename: 'ModalAuthorization',
        open: true,
        ...options,
      },
    };

    this.props.client.writeData({ data });
  };

  onMutateEmpty = () => {
    const { mutateEmptyCart, client } = this.props;
    const { cartFetch } = client.cache.readQuery({ query: CART_FETCH }, true);

    mutateEmptyCart({
      variables: null,
      optimisticResponse: {
        __typename: 'Mutation',
        empty: {
          __typename: 'CartDetailed',
          _id: cartFetch._id,
          items: [],
        },
      },
    });

    client.writeData({
      data: {
        cartFetch: {
          _id: cartFetch._id,
          items: [],
          __typename: 'Cart',
        },
      },
    });
  };

  onMutateAdd = () => {
    const {
      pid, versionPid, versionPids, mutatePutCartItem, client,
    } = this.props;
    const { cartFetch } = client.cache.readQuery({ query: CART_FETCH }, true);

    const newItems = (versionPids) ? versionPids.map((vPid) => ({
      __typename: 'CartItem',
      pid,
      versionPid: vPid,
    })) : [
      {
        __typename: 'CartItem',
        pid,
        versionPid,
      },
    ];

    mutatePutCartItem({
      variables: { pid, versionPid },
      optimisticResponse: {
        __typename: 'Mutation',
        putCartItem: {
          __typename: 'Cart',
          _id: cartFetch._id,
          items: [
            ...cartFetch.items,
            ...newItems,
          ],
        },
      },
    });
  };

  onMutateRemove = () => {
    const {
      pid, versionPid, mutateRemoveCartItem, client,
    } = this.props;
    const { cartFetch } = client.cache.readQuery({ query: CART_FETCH }, true);

    mutateRemoveCartItem({
      variables: { pid, versionPid },
      optimisticResponse: {
        __typename: 'Mutation',
        removeCartItem: {
          __typename: 'Cart',
          _id: cartFetch._id,
          items: cartFetch.items.filter(
            (item) => (versionPid ? item.versionPid !== versionPid : item.pid !== pid),
          ),
        },
      },
    });
  };

  onChangeFormat = (format) => {
    const {
      pid, versionPid, mutateChangeCartItemFormat, client,
    } = this.props;
    const { cartDetailed } = client.cache.readQuery({ query: CART_DETAILED }, true);
    const { isDisabled } = this.state;

    if (!isDisabled) {
      this.triggerTimeout();

      mutateChangeCartItemFormat({
        variables: { pid, versionPid, format: format.slug },
        optimisticResponse: {
          __typename: 'Mutation',
          changeCartItemFormat: {
            __typename: 'CartDetailed',
            _id: cartDetailed._id,
            items: cartDetailed.items.map((item) => {
              if (item.versionPid === versionPid) {
                return {
                  ...item,
                  format,
                };
              }

              return item;
            }),
          },
        },
      });
    }
  };

  onMutateRemoveDetail = () => {
    const {
      pid, versionPid, mutateRemoveCartDetailedItem, client,
    } = this.props;
    const { cartDetailed } = client.cache.readQuery({ query: CART_DETAILED }, true);

    mutateRemoveCartDetailedItem({
      variables: { pid, versionPid },
      optimisticResponse: {
        __typename: 'Mutation',
        removeCartDetailedItem: {
          __typename: 'CartDetailed',
          _id: cartDetailed._id,
          items: cartDetailed.items.filter((item) => item.versionPid !== versionPid),
        },
      },
    });

    client.writeData({
      data: {
        cartFetch: {
          _id: cartDetailed._id,
          items: cartDetailed.items.filter((item) => item.versionPid !== versionPid),
          __typename: 'Cart',
        },
      },
    });
  };
}

const options = {
  refetchQueries: [{ query: CART_FETCH }],
};

const EnhancedComponent = compose(
  withApollo,
  graphql(CART_FETCH, { name: 'cartFetch' }),
  graphql(PUT_CART_ITEM, { name: 'mutatePutCartItem' }),
  graphql(REMOVE_CART_ITEM, { name: 'mutateRemoveCartItem' }),
  graphql(CHANGE_CART_ITEM_FORMAT, { name: 'mutateChangeCartItemFormat', options }),
  graphql(REMOVE_CART_DETAILED_ITEM, { name: 'mutateRemoveCartDetailedItem', options }),
  graphql(EMPTY_CART, { name: 'mutateEmptyCart', options }),
)(BasketMutations);

export default EnhancedComponent;
