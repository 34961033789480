/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import logging from '../../../lib/logging';
import WidgetHeading from '../../../components/widgets/heading/WidgetHeading';
import WidgetCarousel from '../../../components/widgets/carousel/WidgetCarousel';
import WidgetFaq from '../../../components/widgets/faq/WidgetFaq';
import WidgetRichText from '../../../components/widgets/richText/WidgetRichText';
import WidgetImage from '../../../components/widgets/image/WidgetImage';
import WidgetVideo from '../../../components/widgets/video/WidgetVideo';
import WidgetPageLink from '../../../components/widgets/pageLink/WidgetPageLink';
import graphQlHoc from '../../../components/graphQlHoc';
import MailIcon from '../../../components/icons/MailIcon';
import PrintIcon from '../../../components/icons/PrintIcon';
import Error from '../../../components/error/Error';
import query from './query.gql';

class Article extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'Article');
    this.logDebug('init:', props);
  }

  render() {
    this.logDebug('render:', this.props);
    if (!this.props.data.articlePage) {
      console.error('iSite page dataset does not exist or is not published');

      return (
        <Error>Error: Page not available.</Error>
      );
    }

    const { metadata, widgetBySection, documentMeta } = this.props.data.articlePage;
    const shareUrl = encodeURIComponent(`https://wspartners.bbc.co.uk/${this.props.route.match.url}`);

    return (
      <>
        <div className="b-detailpage b-detailpage--iSite container container--indent" key={metadata.guid}>

          <div className="gr-12 no-gutter">
            {widgetBySection.filter((item, idx) => idx === 0).map(({ heading, items }) => (

              <section key="items" className="b-detailpage--iSite__first-widget gr-12 no-gutter">

                {heading && <header><WidgetHeading {...heading} /></header>}
                <div>
                  {items.map(this.mapWidgetByType)}
                </div>

              </section>
            ))}
          </div>
        </div>
        <div className="b-detailpage b-detailpage--iSite">
          {widgetBySection.length > 1
                        && (
                        <div className="gr-12 no-gutter">
                          {widgetBySection.filter(
                            (item, idx) => idx > 0,
                          ).map(({ heading, items }) => (

                            <section key="items" className="article-section gr-12">

                              <div className="container">

                                {heading && <header><WidgetHeading {...heading} /></header>}
                                <div>
                                  {items.map(this.mapWidgetByType)}
                                </div>
                              </div>

                            </section>
                          ))}
                        </div>
                        )}

          { (documentMeta.showMailButton || documentMeta.showPrintButton)
                    && (
                    <div className="container">
                      <div className="actions gr-12 no-gutter">
                        <div className="gr-12">
                          {documentMeta.showMailButton
                                    && (
                                    <a className="b-button" href={`mailto:?subject=BBC%20Partners%20Programme&body=Check%20out%20this%20BBC%20Partners%20Page%0D%0A ${shareUrl}`}>
                                      <i><MailIcon /></i>
                                      Email page
                                    </a>
                                    )}
                          {documentMeta.showPrintButton
                                    && (
                                    <button className="b-button" type="button" onClick={() => window.print()}>
                                      <i><PrintIcon /></i>
                                      Print this Page
                                    </button>
                                    )}
                        </div>
                      </div>
                    </div>
                    )}

        </div>
      </>
    );
  }

  mapWidgetByType = ({ __typename, ...item }, idx) => {
    if (__typename === 'HeadingWidget') {
      return <WidgetHeading key={idx} {...item} />;
    } if (__typename === 'CarouselWidget') {
      return <WidgetCarousel key={idx} {...item} />;
    } if (__typename === 'RichTextWidget') {
      return <WidgetRichText key={idx} isLeadingText={idx === 0} {...item} />;
    } if (__typename === 'ImageWidget') {
      return <WidgetImage key={idx} {...item} />;
    } if (__typename === 'VideoWidget') {
      return <WidgetVideo key={idx} {...item} />;
    } if (__typename === 'FaqWidget') {
      return <WidgetFaq key={idx} {...item} />;
    } if (__typename === 'PageLinkWidget') {
      return <WidgetPageLink key={idx} {...item} />;
    }

    return null;
  };
}

export default graphQlHoc(Article, query);
