import React, { Component } from 'react';

class CalendarIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M18.1 27.6v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.6-.1 1.2.4 1.2 1.1zm10.1 0v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.7-.1 1.2.4 1.2 1.1zm10.1 0v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.7-.1 1.2.4 1.2 1.1zm-20.2 9.9v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.6-.1 1.2.4 1.2 1.1zm10.1 0v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.7-.1 1.2.4 1.2 1.1zm10.1 0v4c0 .6-.5 1.1-1.2 1.1h-4c-.6 0-1.2-.5-1.2-1.1v-4c0-.6.5-1.1 1.2-1.1h4c.7-.1 1.2.4 1.2 1.1zm7.3-31.9v6a5 5 0 0 1-5.1 4.9h-3.2a5 5 0 0 1-5.1-4.9V5.5H17.8v6.1a5 5 0 0 1-5.1 4.9H9.5a5 5 0 0 1-5.1-4.9v-6C2 5.6 0 7.6 0 10v35.4C0 47.9 2.1 50 4.6 50h40.8c2.5 0 4.6-2 4.6-4.5V10c0-2.4-2-4.4-4.4-4.4zm-1.5 37.7c0 1.1-.9 1.9-2 1.9H7.8c-1.1 0-2-.9-2-1.9V25c0-1.1.9-1.9 2-1.9h34.3c1.1 0 2 .9 2 1.9v18.3zm-34.6-30c-1 0-1.7-.8-1.7-1.7V1.7C7.8.7 8.6 0 9.5 0h3.2c1 0 1.7.8 1.7 1.7v9.9c0 .9-.8 1.7-1.7 1.7H9.5zm27.8 0c-1 0-1.7-.8-1.7-1.7V1.7c0-.9.8-1.7 1.7-1.7h3.2c1 0 1.7.8 1.7 1.7v9.9c0 .9-.8 1.7-1.7 1.7h-3.2z" /></svg>
    );
  }
}

export default CalendarIcon;
