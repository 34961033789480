import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import moment from 'moment';
import { Query } from 'react-apollo';
import logging from '../../../lib/logging';
import 'moment-timezone';
import DetailHeader from './DetailHeader';
import DayCalendar from './DetailDayCalendar';
import LoadingSpinner from '../../../components/loadingSpinner/LoadingSpinner';
import DurationIcon from '../../../components/icons/DurationIcon';
import graphQlHoc from '../../../components/graphQlHoc';
import ExtendedLink from '../../../components/ExtendedLink/ExtendedLink';
import { getTzAdjustedDateTimeString, groupBroadcastsByTime } from '../../../lib/time';
import queryDetailDay from './queryDetailDay.gql';
import queryDayBroadcasts from './queryDayBroadcasts.gql';
import TimezoneSelectbox from '../../../components/form-elements/timezoneSelectbox/TimezoneSelectbox';
import Error from '../../../components/error/Error';
import { IconList, IconListItem } from '../../../components/iconList/IconList';

class SchedulesDetailDay extends Component {
  state = {
    timezone: null,
  };

  constructor(props) {
    super(props);
    logging.applyLogging(this, 'SchedulesDetailDay', false);
  }

  getTzAdjustedTimeString(datetime, timezone) {
    const time = moment.utc(datetime);
    const local = time.tz(timezone);
    const adjusted = moment.utc(local.format('YYYY-MM-DDTHH:mm:ss'));
    let suffix = '';

    if (!adjusted.isSame(time, 'day')) {
      suffix += ` ${adjusted.isAfter(time, 'day') ? '+' : '-'}1d`;
    }

    return `${local.format('HH:mm')}${suffix}`;
  }

  renderBody = (dayBroadcasts, date) => {
    if (!dayBroadcasts || !dayBroadcasts.broadcasts) {
      return (<Error>No data available.</Error>);
    }

    const groups = groupBroadcastsByTime(dayBroadcasts.broadcasts, date);
    const { timezone } = this.state;

    return (
      <div className="b-schedule-detail-body">
        <ul className="calendar-anchor-list" id="calendar-anchor-list">
          {groups.map((daytime, key) => <li key={key}><a href={`#${daytime.name}`}>{daytime.name}</a></li>)}
        </ul>

        <div className="gr-12 no-gutter">
          <div className="gr-12 prefix-3@l gr-6@l">
            <TimezoneSelectbox
              className="m-large"
              value={timezone}
              onChange={(option) => { this.setState({ timezone: option }); }}
            />
            {dayBroadcasts.lastModified
                          && (
                          <div className="availability-wrapper">
                            <IconList isHorizontal>
                              <IconListItem icon="calendar">
                                Last updated in
                                {' '}
                                {getTzAdjustedDateTimeString(
                                  dayBroadcasts.lastModified,
                                  timezone ? timezone.zone : null,
                                )}
                              </IconListItem>
                            </IconList>
                          </div>
                          )}
          </div>
          {/* <div className="gr-12 gr-4@m"> */}
          {/*     <button type="button" className="b-button"> */}
          {/*         <span>Download</span> */}
          {/*     </button> */}
          {/* </div> */}
        </div>

        {groups.map((daytime, key) => (
          <section className="b-teaser-list row" key={key} id={daytime.name}>
            <div className="b-teaser-list__head gr-12">
              <h2 className="h2-style">{daytime.name}</h2>
            </div>
            <ul className="gr-12 no-gutter b-teaser-list__list-wrapper">
              {daytime.broadcasts.map((broadcast, idx) => (
                <li className="gr-12 gr-6@m gr-4@l" key={idx}>

                  <ExtendedLink to={broadcast.url} className="b-teaser">

                    <div className="b-teaser-image">
                      {broadcast.offAir
                                            && <div className="b-teaser-image__label">Off Air</div>}

                      {broadcast.isUpdated
                                            && <div className="b-teaser-image__label b-teaser-image__label--red">Schedule change</div>}

                      {broadcast.image
                                            && (
                                            <picture>
                                              <img srcSet={`${broadcast.image.sizes.small}, ${broadcast.image.sizes.medium} 2x`} src={broadcast.image.sizes.medium} alt={broadcast.image.title} />
                                            </picture>
                                            )}

                    </div>

                    <div className="b-teaser-info">

                      <span className="b-teaser-info__available-hint">
                        {!broadcast.offAir && timezone && `${this.getTzAdjustedTimeString(broadcast.start, timezone.zone)} | `}

                        {moment.utc(broadcast.start).format('HH:mm ')}
                        {broadcast.offAir && (`- ${moment.utc(broadcast.end).format('HH:mm ')}`)}
                        GMT
                      </span>

                      <span className="b-teaser-info__title">
                        {broadcast.brand}
                      </span>

                      {broadcast.title
                                            && <span className="b-teaser-info__sub-title">{broadcast.title}</span>}

                      {broadcast.text
                                            && <p className="b-teaser-info__description">{broadcast.text}</p>}

                      {broadcast.duration
                                            && (
                                            <span className="b-teaser-info__duration">
                                              <DurationIcon />
                                              {broadcast.duration}
                                            </span>
                                            )}
                    </div>
                  </ExtendedLink>

                </li>
              ))}
            </ul>
            <div className="gr-12 back-to-top">
              <a href="#calendar-anchor-list">Back to top</a>
            </div>
          </section>
        ))}
      </div>
    );
  };

  render() {
    if (!this.props.data.channelData) {
      return (<Error>This channel does not exist.</Error>);
    }

    return (
      <div className="b-schedule-page">
        <div className="container container--indent">
          <div className="gr-12">

            <DetailHeader channelData={this.props.data.channelData} route={this.props.route} />

            <Route
              exact
              path="/schedules/:channel/day/:date"
              history={this.props.route.history}
              render={(props) => (
                <>

                  <DayCalendar
                    onClickDay={(date) => {
                      const dateString = moment(date).format('YYYY-MM-DD');
                      this.props.route.history.push(`/schedules/${this.props.route.match.params.channel}/day/${dateString}`);
                    }}
                    minDate={new Date(this.props.data.channelData.min)}
                    maxDate={new Date(this.props.data.channelData.max)}
                    value={new Date(props.match.params.date)}
                  />

                  <Query
                    fetchPolicy="cache-and-network"
                    query={queryDayBroadcasts}
                    variables={{
                      channel: props.match.params.channel,
                      date: props.match.params.date,
                    }}
                  >
                    {({ loading, error, data }) => {
                      this.logDebug('render DetailDay Body ', props.match.params.date, data, loading);

                      if (error) {
                        return (<Error>Something went wrong...</Error>);
                      } if (loading) {
                        return (<LoadingSpinner />);
                      }

                      return this.renderBody(data.dayBroadcasts, props.match.params.date);
                    }}
                  </Query>
                </>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default graphQlHoc(SchedulesDetailDay, queryDetailDay);
