/* eslint-disable no-console */
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import logging from '../../lib/logging';
import FavlistTile from '../favlistTile/FavlistTile';
import Button from '../button/Button';
import query from '../../modules/routes/favorites/query.gql';
import MUTATION_TOGGLE_FAVORITE_BRAND from './mutation.gql';

class FavlistTileList extends Component {
  constructor(props) {
    super(props);
    logging.applyLogging(this, 'FavlistTileList');
    this.logDebug('init:', props);

    this.state = {
      show: false,
    };
  }

  render() {
    const { title, items } = this.props;

    const validItems = items.filter((item) => !item.invalidated);
    const maxItems = 2;

    return (
      <div className="b-favlist-tile-list">
        <div className="b-favlist-tile-list__header">
          <h2 className="b-favlist-tile-list__header__title">
            {title}
            {' '}
            (
            {items.length}
            )
          </h2>
          <Mutation mutation={MUTATION_TOGGLE_FAVORITE_BRAND}>
            {(mutate) => (
              <a onClick={() => this.onMutate(mutate)} className="clear-button">Clear all notifications</a>
            )}
          </Mutation>
        </div>
        {validItems
          .filter((_, idx) => this.state.show || idx < maxItems)
          .map((item) => (
            <FavlistTile
              key={item.pid}
              item={item}
              label="New"
              title={item.episode.titleTruncated}
              image={item.episode.image}
              description={item.episode.synopsisTruncated}
            />
          ))}

        <div className="b-favlist-tile-list__cta-bar">
          {!this.state.show && validItems.length > maxItems
                        && (
                        <Button
                          disabled={this.state.show}
                          onClick={() => this.setState({ show: true })}
                          buttonName="Show all notifications"
                        />
                        )}
        </div>
      </div>
    );
  }

  onMutate = (mutate) => {
    mutate({
      variables: { pid: this.props.brand },
      optimisticResponse: {
        __typename: 'Mutation',
        invalidateNotificationBrand: true,
      },

      update: (proxy) => {
        const data = proxy.readQuery({ query });

        data.notifications = data.notifications.filter((item) => item.brand !== this.props.brand);

        proxy.writeQuery({ query, data });

        return null;
      },
    }).catch((err) => {
      console.error(err);
    });
  };
}

export default FavlistTileList;
