import React, { Component } from 'react';

import TeaserList from '../../../components/teaserList/TeaserList';
import graphQlHoc from '../../../components/graphQlHoc';
import query from './catalogueLanguage.gql';
import Pagination from '../../../components/pagination/Pagination';
import { getRegionName } from '../../../js/helper';

class CatalogueLanguage extends Component {
  render() {
    const { languageList, page } = this.props.data;
    const {
      region, language, items, totalPages,
    } = languageList;
    const documentMeta = page && page.documentMeta;
    const { page: currentPage } = this.props.route.match.params;

    return (
      <div className="b-cart-page">
        <div className="container container--indent">
          <div className="gr-12">
            <div className="gr-12 gr-11@m page__intro">
              <h1>
                {getRegionName(region.name)}
                {' '}
                -
                {' '}
                {language.name}
              </h1>
              { documentMeta && (
              <p><strong dangerouslySetInnerHTML={{ __html: documentMeta.description }} /></p>
              )}
              { documentMeta && (
              <div dangerouslySetInnerHTML={{ __html: documentMeta.synopsis }} />
              )}
            </div>
            <div className="gr-12">
              <TeaserList
                title={false}
                isThirdGrid
                hasFavlist
                hasAvailableHint={false}
                hasBrandTitle={false}
                hasDescription
                hasGenre
                isLocked={false}
                items={items}
              />
              <Pagination route={`/catalogue/region/${region.slug}/${language.slug}`} totalPages={totalPages} currentPage={parseInt(currentPage, 10)} />
            </div>

          </div>
        </div>

      </div>
    );
  }
}

export default graphQlHoc(CatalogueLanguage, query);
